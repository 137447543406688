import styled from "@emotion/styled";
import { motion } from "framer-motion";
import GraphBar from "./GraphBar";
import SingleBar from "./SingleBar";

type DoubleBarProps = {
  totalValue?: string | number;
  utilityValue?: string | number;
  loanValue?: string | number;
  variant?: "compact" | undefined;
  topLabel?: string;
  hideLabels?: boolean;
  bottomText?: string;
};

const fadeInitial = { opacity: 0 };
const fadeAnimate = { opacity: 1 };
const fadeTransition = { duration: 0.5, delay: 0.8 };

function DoubleBar({
  totalValue = "--",
  utilityValue = "--",
  loanValue = "--",
  variant,
  topLabel,
  hideLabels = false,
  bottomText,
}: DoubleBarProps) {
  return (
    <Wrapper>
      <SingleBar value={totalValue} color="#6EE2FF" topLabel={topLabel} />
      <motion.div
        className="inner-bar-content"
        initial={{ height: "0%" }}
        animate={{ height: "100%" }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <motion.div
          className={`value-wrapper ${variant}`}
          initial={fadeInitial}
          animate={fadeAnimate}
          transition={fadeTransition}
        >
          <p>{loanValue}</p>
          <p>{!hideLabels && "Solar Loan Payment"}</p>
        </motion.div>
        <div className="small-bar-wrapper">
          <motion.div
            className={`value-wrapper ${variant}`}
            initial={fadeInitial}
            animate={fadeAnimate}
            transition={fadeTransition}
          >
            <p>{utilityValue}</p>
            <p>{!hideLabels && "Est.Utility"}</p>
          </motion.div>
          <GraphBar
            style={{ background: "#336699", color: "white", height: "100%" }}
          />
        </div>
      </motion.div>
      <p className="bottom-text">{bottomText}</p>
    </Wrapper>
  );
}

export default DoubleBar;

const Wrapper = styled.div`
  position: relative;
  display: flex;

  .inner-bar-content {
    position: absolute;
    z-index: 500;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* overflow: hidden; */

    align-self: flex-end;
    z-index: 400;
  }

  .small-bar-wrapper {
    position: relative;
    width: 100%;
    height: 60%;

    .value-wrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  .value-wrapper {
    white-space: nowrap;
    display: flex;
    margin: 6px 0px;

    p {
      font-family: Gotham Medium;
      font-size: 12px;
    }
    p:first-of-type {
      color: white;
      min-width: 100%;
      text-align: center;
    }

    p:last-of-type {
      margin-left: 1em;
      color: #d4d4d4;
    }
  }

  .compact {
    flex-direction: column;
    align-items: center;
    white-space: normal;
    text-align: center;
    height: 38px;

    p {
      color: white;
    }

    p:last-of-type {
      color: white;
      font-size: 9px;
      margin-left: 0px;
      padding: 0px 6px;
    }
  }

  .bottom-text {
    position: absolute;
    top: 100%;
    text-align: center;
    width: 100%;
    color: #d4d4d4;
    font-family: Gotham Medium;
    font-size: 14px;
    margin-top: 6px;
  }
`;
