import styled from "@emotion/styled";
import { ReactComponent as Triangle } from "@/assets/triangle-down.svg";
import { motion } from "framer-motion";

type LabelProps = {
  y?: string | number;
  color?: string;
  value?: string | number;
};

function Label({ y = 0, color, value = "NO VAL" }: LabelProps) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
      style={{
        position: "absolute",
        right: "3.5%",
        bottom: y,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "red",
      }}
    >
      <Wrapper
        style={{
          color: color ?? "#707070",
          borderColor: color ?? "#D4D4D4",
        }}
      >
        {value}
        <br />
        {color ? "With Solar" : "Without Solar"}
        <Triangle />
      </Wrapper>
    </motion.div>
  );
}

export default Label;

const Wrapper = styled.div`
  position: absolute;
  padding: 4px;
  border: solid 1px;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  background-color: white;
  display: flex;
  justify-content: center;
  white-space: nowrap;

  svg {
    position: absolute;
    bottom: -6px;
  }
`;
