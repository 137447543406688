import { ReactComponent as Splash03 } from "@/assets/splash/03.svg";
import awards from "@/assets/thankyou/awards.png";
import { OptionalLicenseNumbers } from "@/Components/LicenseNumbers";
import delay from "@/utils/animationDelay";
import useIntersectionObserver from "@/utils/useIntersectionObserver";
import styled from "@emotion/styled";
import { useRef } from "react";

function FooterCard() {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { rootMargin: "-20%" });
  const isVisible = !!entry?.isIntersecting;

  return (
    <div ref={ref}>
      <Wrapper>
        <img
          src={awards}
          alt="Some of Momentum Solar's awards"
          className={isVisible ? "animate" : ""}
          style={delay(0.4)}
        />
        <p className={isVisible ? "animate" : ""} style={delay(0.8)}>
          © 2022 A Momentum Solar | All Rights Reserved | *Subject to applicable
          terms and conditions. Phone calls and in-person appointments may be
          recorded for quality assurance. Eligibility and savings is determined
          by municipal utility, roof condition and roof space, azimuth, tree
          location, shading, weather and other factors. HIC # – CA: 1026366; CT:
          HIC.0646323; FL: CVC57036; MA: 192204; NV: #0089130; NJ: 13VH05539000;
          NYC: 2042828-DCA; PA: PA131435; TX: 31915; MA: 192204; AZ: 237781
        </p>
        <Splash03 className={isVisible ? "animate splash" : "splash"} />
      </Wrapper>
    </div>
  );
}

export default FooterCard;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  z-index: 200;
  margin-top: 15%;

  img {
    width: 100%;
  }

  p {
    font-size: 12px;
    color: white;
    z-index: 300;
    margin-bottom: 16px;
  }

  .splash {
    z-index: 200;
    position: absolute;
    min-width: 130%;
    /* height: auto; */
  }

  .animate {
    -webkit-animation: fade 0.8s ease-out forwards;
    -moz-animation: fade 0.8s ease-out forwards;
    animation: fade 0.8s ease-out forwards;
    opacity: 0;
    transform: translate(0%, 10%);
  }

  .splash.animate {
    -webkit-animation: splash- fade 0.8s ease-out forwards;
    -moz-animation: splash- fade 0.8s ease-out forwards;
    animation: splash-fade 0.8s ease-out forwards;
    opacity: 0;
    transform: translate(-5%, 10%);
  }

  @-moz-keyframes fade {
    to {
      -webkit-transform: translate(0%, 0%);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade {
    to {
      -webkit-transform: translate(0%, 0%);
    }
  }
  @keyframes fade {
    to {
      transform: translate(0%, 0%);
      -webkit-transform: translate(0%, 0%);
      opacity: 1;
    }
  }

  @-moz-keyframes splash-fade {
    to {
      -webkit-transform: translate(-5%, 5%);
      opacity: 1;
    }
  }
  @-webkit-keyframes splash-fade {
    to {
      -webkit-transform: translate(-5%, 5%);
    }
  }
  @keyframes splash-fade {
    to {
      transform: translate(-5%, 5%);
      -webkit-transform: translate(-5%, 5%);
      opacity: 1;
    }
  }
`;
