import { getNYAnnualCBCCost } from "../../calculations";
import { cleanData } from "../utilFuncs";

const createLoanTable = (data) => {
  let {
    consumption,
    production,
    utilityRate,
    loanTerm,
    oldAnnualBill,
    utilityEscalator,
    loanPayment,
    EPC,
    wholesaleRate,
    rebate = 0,
  } = data;

  utilityEscalator = +utilityEscalator;
  consumption = +consumption;
  oldAnnualBill = +oldAnnualBill;
  production = +production;
  utilityRate = +utilityRate;
  loanTerm = +loanTerm;
  loanPayment = +loanPayment;
  wholesaleRate = +wholesaleRate;
  EPC = +EPC;
  EPC -= +rebate;

  utilityEscalator = utilityEscalator / 100 + 1;
  let propertyTaxAbatement = (EPC - rebate) * 0.075; //NYC Property tax abatement is 30% system const paid out over the first four years (0.3 / 4 = 0.75)

  let stateTaxCredit = () => {
    if (EPC * 0.25 >= 5000) {
      return 5000;
    } else {
      return EPC * 0.25;
    }
  };

  const yearly = (currentProduction, futureUtilRate, futureWholesaleRate) => {
    if (Math.sign(consumption - currentProduction) === -1) {
      return (
        (consumption - currentProduction) * futureWholesaleRate +
        getNYAnnualCBCCost(data)
      );
    } else {
      return (
        (consumption - currentProduction) * futureUtilRate +
        getNYAnnualCBCCost(data)
      );
    }
  };

  let fullArr = [];

  const futureVal = (investment, rate, index, years) => {
    return fullArr[years - 2] ? fullArr[years - 2][index] * rate : investment;
  };

  const savings = (oldVal, newVal, increase, years) => {
    let old = futureVal(oldVal, increase, 1, years);
    let current = newVal;

    if (years === 1) {
      return (
        old -
        current -
        loanPayment * 12 +
        stateTaxCredit() +
        propertyTaxAbatement
      );
    } else if (years > 1 && years < 5) {
      return old - current - loanPayment * 12 + propertyTaxAbatement;
    } else if (years < +loanTerm + 1) {
      return old - current - loanPayment * 12;
    } else {
      return old - current;
    }
  };

  const total = (oldVal, newVal, increase, years) =>
    fullArr[years - 2]
      ? fullArr[years - 2][7] + savings(oldVal, newVal, increase, years)
      : savings(oldVal, newVal, increase, years);

  let futureUtilRate = utilityRate;
  let futureProductionRate = production;
  let futureWholesaleRate = wholesaleRate;
  for (let i = 1; i < 31; i++) {
    futureUtilRate = i > 1 ? futureUtilRate * utilityEscalator : utilityRate;
    futureProductionRate =
      i > 1
        ? futureProductionRate - futureProductionRate * 0.005
        : futureProductionRate;
    futureWholesaleRate =
      i > 1 ? futureWholesaleRate * utilityEscalator : futureWholesaleRate;

    fullArr.push([
      i,
      futureVal(oldAnnualBill, utilityEscalator, 1, i),
      yearly(futureProductionRate, futureUtilRate, futureWholesaleRate),
      i < +loanTerm + 1 ? loanPayment * 12 : null,
      i === 1 ? stateTaxCredit() : "",
      i < 5 ? propertyTaxAbatement : "", //Abatement paid out valid for the first 4 years
      savings(
        oldAnnualBill,
        yearly(futureProductionRate, futureUtilRate, futureWholesaleRate),
        utilityEscalator,
        i
      ),
      total(
        oldAnnualBill,
        yearly(futureProductionRate, futureUtilRate, futureWholesaleRate),
        utilityEscalator,
        i
      ),
    ]);
  }
  let oldPayments = 0;
  let newPayments = 0;
  let solar = 0;
  let yearlyFull = 0;

  fullArr.forEach((x) => {
    oldPayments += +x[1];
    newPayments += +x[2];
    solar += +x[3];
    yearlyFull += +x[6];
  });

  const loanTableBar = { oldPayments, newPayments, yearlyFull };

  const lastArr = [
    "Total",
    cleanData(oldPayments),
    cleanData(newPayments),
    `(${cleanData(solar)})`,
    "",
    cleanData(propertyTaxAbatement * 4),
    cleanData(yearlyFull),
    "-",
  ];

  return mappedVals(fullArr, lastArr, loanTableBar);
};

const mappedVals = (vals, lastArr, loanTableBar) => {
  let finalArray = vals.reduce((array, curr, index) => {
    let newArr = [];
    newArr.push(
      index + 1,
      cleanData(curr[1]),
      cleanData(curr[2]),
      curr[3] ? `(${cleanData(curr[3])})` : "",
      curr[4] ? cleanData(curr[4]) : "",
      curr[5] ? cleanData(curr[5]) : "",
      curr[6] ? cleanData(curr[6]) : "",
      curr[7] ? cleanData(curr[7]) : ""
    );
    array.push(newArr);
    return array;
  }, []);

  return [[...finalArray, lastArr], loanTableBar];
};

export default createLoanTable;
