import styled from "@emotion/styled";
import { ReactComponent as WorkersSVG } from "@/assets/homeValue/home-install-workers.svg";

const Workers = styled(WorkersSVG)`
  position: absolute;
  aspect-ratio: 1;
  top: 11.5%;
  right: 34.5%;
  height: 47%;

  opacity: 0;
  -webkit-animation: fade-in 1s ease-in-out forwards;
  -moz-animation: fade-in 1s ease-in-out forwards;
  animation: fade-in 1s ease-in-out forwards;

  -webkit-animation-delay: 1.7s;
  -moz-animation-delay: 1.7s;
  animation-delay: 1.7s;

  @-moz-keyframes fade-in {
    100% {
      -webkit-transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-in {
    100% {
      -webkit-transform: translate(0, 0);
      opacity: 1;
    }
  }
  @keyframes fade-in {
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
`;
export default Workers;
