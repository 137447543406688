import styled from "@emotion/styled";

//Responsive sizing handled here

const FullPage = styled.section`
  max-width: 1024px;
  min-height: calc(100vh - 80px - 70px);
  padding: 0px 32px;
  margin: 0 auto;
  background: #f5f5f5;

  @media screen and (max-width: "900px") {
    max-width: 100vw;
  }
`;

export default FullPage;
