import { createContext, ReactNode, useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { JsxElement } from "typescript";
import ScrollSectionRef from "./ScrollSectionRef";

export type ScrollRef = { Icon?: any; label?: string; ref: any };

type ScrollNavigatorProps = {
  children: any[];
  navigationElement?: React.ReactNode;
  headerElement?: React.ReactNode;
};

type ScrollContextProps = {
  scrollRefs?: ScrollRef[];
  setScrollRefs?: any;
  activeSectionIndex: number;
  setActiveSectionIndex: any;
  scrollToIndex: any;
  scrollNext: () => void;
  scrollPrev: () => void;
};

const ScrollContext = createContext<ScrollContextProps>({
  scrollRefs: [],
  activeSectionIndex: 0,
  setActiveSectionIndex: () => {},
  setScrollRefs: () => {},
  scrollToIndex: () => {},
  scrollNext: () => {},
  scrollPrev: () => {},
});

export const useScrollNavigation = () => useContext(ScrollContext);

export function ScrollNavigatorContext({
  children = [],
  navigationElement = null,
}: ScrollNavigatorProps) {
  const [scrollRefs, setScrollRefs] = useState<any[]>(
    new Array(children.length).fill(null)
  );
  const [activeSectionIndex, setActiveSectionIndex] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const scrollToIndex = (index: number = 0) => {
    const i =
      index >= scrollRefs.length
        ? scrollRefs.length - 1
        : index - 1 < 0
        ? 0
        : index;
    setActiveSectionIndex(i);
    scrollRefs[i].sectionRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const scrollNext = () => {
    scrollToIndex(activeSectionIndex + 1);
  };
  const scrollPrev = () => {
    scrollToIndex(activeSectionIndex - 1);
  };

  useEffect(() => {
    const pageParam = searchParams.get("page");
    if (pageParam) {
      children.forEach((child, i) => {
        if (pageParam === child?.props?.label) {
          scrollToIndex(i);
        }
      });
    }
  }, []);

  const renderChildren = () => {
    return children?.map((child, i) => {
      // if (child?.type.name !== "ScrollSection") {
      //   console.log(child?.type.name);
      //   console.error(
      //     "Error in Scroll Navigator: All Children must be wrapped in a ScrollSection or Navigation component"
      //   );
      // }
      return (
        <ScrollSectionRef
          key={"scroll-section-" + i}
          index={i}
          {...child.props}
        >
          {child}
        </ScrollSectionRef>
      );
    });
  };

  return (
    <ScrollContext.Provider
      value={{
        scrollRefs,
        setScrollRefs,
        activeSectionIndex,
        setActiveSectionIndex,
        scrollToIndex,
        scrollNext,
        scrollPrev,
      }}
    >
      <div
        style={{
          scrollSnapType: "y proximity",
          overscrollBehavior: "none",
          overflowY: "auto",
          minHeight: "calc(100vh - 80px  - 70px)", // 80px = header height, 70px = nav height TODO: make dynamic
          top: 0,
        }}
      >
        {renderChildren()}
        {navigationElement}
      </div>
    </ScrollContext.Provider>
  );
}
