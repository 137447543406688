import { getFederalTaxCreditRate } from "../../calculations";
import { numberWithCommas, cleanData } from "../utilFuncs";

// ! if consumption - production is negative multiply by wholesale not utility; (Everything);
// ! for cash flow on nj cash
// ! epc is system cost
// ! production up to 100% of consumption * utility rate

const createCashTable = (data) => {
  let {
    EPC,
    consumption,
    production,
    utilityRate,
    utilityEscalator,
    wholesaleRate,
    rebate = 0,
    utility,
  } = data;
  EPC = +EPC;
  consumption = +consumption;
  production = +production;
  utilityRate = +utilityRate;
  utilityEscalator = +utilityEscalator;
  wholesaleRate = +wholesaleRate;
  EPC -= +rebate;

  // let annualRECIncome =
  //   utility === "Eversource" ? +(production * 0.0318).toFixed(2) : 0;
  let annualRECIncome = 0; //CT REC is not currently offered;
  let federalTaxCredit = getFederalTaxCreditRate() * EPC;

  // keep in mind that this utilityEscaltor reassignment is with the format wholenumber/decimal being passed (i.e. 3.9%);
  utilityEscalator = utilityEscalator / 100 + 1;

  //full array to fill and return at the end;
  let fullArr = [];

  const electricBillSavings = (
    currProduction,
    currUtilityRate,
    futureWholesaleRate
  ) => {
    if (Math.sign(currProduction - consumption) === -1) {
      return currProduction * currUtilityRate;
    } else {
      return (
        currProduction * currUtilityRate +
        (currProduction - consumption) * futureWholesaleRate
      );
    }
  };

  const electricBillAfterSolar = (
    currProduction,
    currUtilityRate,
    futureWholesaleRate
  ) => {
    if (Math.sign(consumption - currProduction) === -1) {
      return (consumption - currProduction) * futureWholesaleRate;
    } else {
      return (consumption - currProduction) * currUtilityRate;
    }
  };

  const savings = (
    currProduction,
    currUtilityRate,
    years,
    futureWholesaleRate
  ) => {
    let currSavings = electricBillSavings(
      currProduction,
      currUtilityRate,
      futureWholesaleRate
    );

    if (years === 1) {
      return currSavings - EPC + federalTaxCredit;
    } else {
      return currSavings;
    }
  };

  const total = (currProduction, currUtilityRate, years, futureWholesaleRate) =>
    fullArr[years - 2]
      ? fullArr[years - 2][8] +
        savings(currProduction, currUtilityRate, years, futureWholesaleRate) +
        annualRECIncome
      : savings(currProduction, currUtilityRate, years, futureWholesaleRate) +
        annualRECIncome;

  let futureUtilRate = utilityRate;
  let futureProductionRate = production;
  let futureWholesaleRate = wholesaleRate;
  for (let i = 1; i < 31; i++) {
    futureUtilRate = i > 1 ? futureUtilRate * utilityEscalator : utilityRate;
    futureProductionRate =
      i > 1
        ? futureProductionRate - futureProductionRate * 0.005
        : futureProductionRate;
    futureWholesaleRate =
      i > 1 ? futureWholesaleRate * utilityEscalator : futureWholesaleRate;
    annualRECIncome = i > 20 ? 0 : annualRECIncome; //CT REC is not currently offered;
    const arr = [
      i,
      i === 1 ? EPC : "",
      electricBillSavings(
        futureProductionRate,
        futureUtilRate,
        futureWholesaleRate
      ),
      electricBillAfterSolar(
        futureProductionRate,
        futureUtilRate,
        futureWholesaleRate
      ),
      futureProductionRate,
      utility === "Eversource" && annualRECIncome,
      i === 1 ? federalTaxCredit : "",
      "",
      savings(futureProductionRate, futureUtilRate, i, futureWholesaleRate) +
        annualRECIncome,
      total(futureProductionRate, futureUtilRate, i, futureWholesaleRate),
    ];

    fullArr.push(arr);
  }

  let electricBillTotal = 0;
  let electricBillAfterTotal = 0;
  let cashFlowTotal = 0;
  let pvGenTotal = 0;

  fullArr.forEach((x) => {
    electricBillTotal += +x[2];
    electricBillAfterTotal += +x[3];
    pvGenTotal += +x[4];
    cashFlowTotal += x[8];
  });

  const cashTableVar = {
    totalElectricBillSavings: electricBillTotal,
    totalElectricAfterSolar: electricBillAfterTotal,
    totalAnnualCashFlow: cashFlowTotal,
  };

  const lastArr = [
    "Total",
    "",
    cleanData(electricBillTotal),
    cleanData(electricBillAfterTotal),
    numberWithCommas(Math.round(pvGenTotal)),
  ];
  utility === "Eversource" && lastArr.push("");
  lastArr.push(cleanData(federalTaxCredit), "", cleanData(cashFlowTotal), "");

  const irrValues = fullArr.reduce((arr, curr, index) => {
    if (index !== fullArr.length) arr.push(curr[8]);
    return arr;
  }, []);

  // console.log(
  //   mappedCashVals(fullArr, lastArr, irrValues, cashTableVar, utility)
  // );
  return mappedCashVals(fullArr, lastArr, irrValues, cashTableVar, utility);
};

const mappedCashVals = (vals, lastArr, irrValues, cashTableVar, utility) => {
  let finalArray = vals.reduce((array, curr, index) => {
    let newArr = [];
    newArr.push(
      index + 1,
      curr[1] ? `(${cleanData(curr[1])})` : "",
      cleanData(curr[2]),
      cleanData(curr[3]),
      numberWithCommas(Math.round(curr[4])) || ""
    );
    utility === "Eversource" &&
      newArr.push(curr[5] === 0 ? "-" : cleanData(curr[5]));
    newArr.push(
      curr[6] ? cleanData(curr[6]) : "",
      "",
      cleanData(curr[8]),
      cleanData(curr[9])
    );
    array.push(newArr);
    return array;
  }, []);

  return [[...finalArray, lastArr], irrValues, cashTableVar];
};

export default createCashTable;
