import styled from "@emotion/styled";
import { ReactComponent as TruckSVG } from "@/assets/homeValue/home-install-truck.svg";

const Truck = styled(TruckSVG)`
  position: absolute;
  aspect-ratio: 1;
  right: 8%;
  bottom: 22%;
  height: 35%;
  transform: translate(33px, -17px);
  opacity: 0;
  -webkit-animation: backup 1s ease-out forwards;
  -moz-animation: backup 1s ease-out forwards;
  animation: backup 1s ease-out forwards;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;

  @-moz-keyframes backup {
    100% {
      -webkit-transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes backup {
    100% {
      -webkit-transform: translate(0, 0);
      opacity: 1;
    }
  }
  @keyframes backup {
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
`;
export default Truck;
