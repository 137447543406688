import styled from "@emotion/styled";
import dayjs from "dayjs";
import { ReactComponent as XIcon } from "@/assets/x-icon.svg";
import Card from "@/Components_DEP/Card";
import CardHeader from "@/Components_DEP/Card/CardHeader";

function MapInfoModal({ marker, closeModal = () => {} }) {
  if (!marker) return null;
  const { city, state, panels_installed, system_size } = marker.data;

  return (
    <Wrapper>
      <CardHeader>
        <h6>
          {city}, {state}
        </h6>
        <button
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        >
          <XIcon style={{ color: "grey" }} />
        </button>
      </CardHeader>
      <div className="content">
        <div className="item">
          <p className="info-text">{system_size}</p>
          <p className="label-text">System Size</p>
        </div>
        <div className="item">
          <p className="info-text">
            {dayjs(panels_installed).format("MM/DD/YYYY")}
          </p>
          <p className="label-text">Install Date</p>
        </div>
      </div>
    </Wrapper>
  );
}

export default MapInfoModal;

const Wrapper = styled(Card)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  transform: translate(-50%, calc(-100% - 44px));
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  min-width: 200px;

  h6 {
    margin: 0;
    font-size: 14px;
  }

  button {
    background: none;
    padding: none;
    margin: none;
    align-self: flex-end;
    border: none;
    padding: 8px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }

  button:hover {
    opacity: 0.6;
  }

  p {
    font-family: Gotham Book;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    gap: 10px;
    margin: 16px;
  }

  .info-text {
    font-size: 12px;
    margin: 0px;
  }

  .label-text {
    font-weight: light;
    font-size: 9px;
    margin: 0px;
  }
`;
