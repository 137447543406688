import styled from "@emotion/styled";
import { ReactComponent as MapIcon } from "@/assets/map-icon.svg";

type MapButtonProps = {
  onClick?: () => void;
};

function MapButton({ onClick }: MapButtonProps) {
  return (
    <Wrapper onClick={onClick}>
      <MapIcon />
      <p>Momentum In Your Hometown</p>
    </Wrapper>
  );
}

export default MapButton;

const Wrapper = styled.button`
  color: #89e279;
  border: #89e279 solid 2px;
  padding: 12px 24px;
  border-radius: 10px;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1000;

  gap: 0.5em;

  p {
    font-size: 14px;
    font-family: Gotham Medium;
  }
`;
