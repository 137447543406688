import Context from "@/Context";
import styled from "@emotion/styled";
import { useContext } from "react";
import MoreDetailsButton from "./MoreDetailsButton";

function CustomerDetails() {
  const { proposal } = useContext(Context);

  return (
    <Wrapper>
      <MoreDetailsButton />
      <h6>{proposal?.customer_name}</h6>
      <p className="address">{proposal?.property_address}</p>
      <p className="address">
        {proposal?.property_city}, {proposal?.property_state}{" "}
        {proposal?.property_zip}
      </p>
      {/* <p className="address">{proposal?.oppnum}</p> */}
    </Wrapper>
  );
}

export default CustomerDetails;

const Wrapper = styled.div`
  flex: 1; // this is important for spacing, see parent wrapper
  text-transform: capitalize;
  position: relative;
`;
