import { ReactComponent as EnphaseIcon } from "@/assets/design/enphase-icon.svg";
import EnphaseImg from "@/assets/design/enphase.png";
import Card from "@/Components_DEP/Card";
import Context from "@/Context";
import styled from "@emotion/styled";
import { useContext } from "react";

function StorageCard() {
  const { proposal } = useContext(Context);
  return (
    <Wrapper>
      <img src={EnphaseImg} alt="Enphase Product" />
      <Card className="card">
        <h6>{proposal?.battery_model}</h6>
        <div>
          <p>Storage System Size</p>
          <p className="text">{proposal?.battery_size} kWh</p>
        </div>
        <div>
          <p>Quantity:</p>
          <p className="text">{proposal?.battery_module_quantity}</p>
        </div>
      </Card>
    </Wrapper>
  );
}

export default StorageCard;

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  img {
    max-height: 250px;
    margin: 0px 32px;
  }

  .card {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h6 {
      font-size: 18px;
    }
    p {
      white-space: nowrap;
      font-family: Gotham Medium;
      color: #4b4b4b;
    }

    .text {
      color: #c5c5c5;
      font-size: 24px;
    }

    .footnote {
      position: absolute;
      font-size: 12px;
      right: 8px;
      bottom: 8px;
      text-align: end;
      font-family: Lato;
      color: #b2b2b2;
    }
  }
`;
