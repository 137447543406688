import { ReactComponent as Star } from "@/assets/thankyou/star.svg";
import delay from "@/utils/animationDelay";
import useIntersectionObserver from "@/utils/useIntersectionObserver";
import styled from "@emotion/styled";
import { useRef } from "react";

function Stars() {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;
  return (
    <Wrapper ref={ref}>
      {isVisible && (
        <>
          <Star style={delay(0.4)} />
          <Star style={delay(0.5)} />
          <Star style={delay(0.6)} />
          <Star style={delay(0.7)} />
          <Star style={delay(0.8)} />
        </>
      )}
    </Wrapper>
  );
}

export default Stars;

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;

  svg {
    transform: translate(5px, 0px) rotate(45deg);
    opacity: 0;
    -webkit-animation: fade-in 1s ease-in-out forwards;
    -moz-animation: fade-in 1s ease-in-out forwards;
    animation: fade-in 1s ease-in-out forwards;

    @-moz-keyframes fade-in {
      100% {
        -webkit-transform: translate(0, 0) rotate(0deg);
        opacity: 1;
      }
    }
    @-webkit-keyframes fade-in {
      100% {
        -webkit-transform: translate(0, 0) rotate(0deg);
        opacity: 1;
      }
    }
    @keyframes fade-in {
      100% {
        -webkit-transform: translate(0, 0) rotate(0deg);
        transform: translate(0, 0) rotate(0deg);
        opacity: 1;
      }
    }
  }
`;
