import axios from "axios";
import { get30YearSavings, getLifetimeCT_RESIncome } from "./calculations";

export const post30YearEstimatedSavings = async (proposal, mongoId) => {
  const thirtyYearSavings = get30YearSavings(proposal);

  try {
    await axios.post(
      "https://api.momentumsolar.io/momentum_db/sql/query_async",
      {
        query: `update [MongoDB].[dbo].[Completedproposals] set thirty_year_savings = '${thirtyYearSavings}' where _id = '${mongoId}' and thirty_year_savings is null`,
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const postCT_RESIncentive = async (proposal, mongoId) => {
  if (
    (proposal?.State?.toUpperCase() !== "CT" ||
      proposal?.property_state?.toUpperCase() !== "CT") &&
    (proposal?.utility?.toUpperCase() !== "EVERSOURCE" ||
      proposal?.utility_provider?.toUpperCase() === "EVERSOURCE")
  )
    return;

  const RESIncome = getLifetimeCT_RESIncome(proposal);

  try {
    await axios.post(
      "https://api.momentumsolar.io/momentum_db/sql/query_async",
      {
        query: `update [MongoDB].[dbo].[Completedproposals] set RES = ${RESIncome} where _id = '${mongoId}' and RES = 0`,
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const postBattery = async (proposal, mongoId) => {
  if (proposal.generacSystem) {
    let battery = proposal.generacSystem;
    try {
      await axios.post(
        "https://api.momentumsolar.io/momentum_db/sql/query_async",
        {
          query: `update [MongoDB].[dbo].[Completedproposals] set battery_capacity = ${+battery.batterySystemSize}, battery_module_quantity = ${+battery.batteryModuleQuantity}, battery_make_model = 'IQ Battery 5P', battery_cost = ${+battery.batteryCost}  where _id = '${mongoId}' and battery_cost is null`,
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
};
