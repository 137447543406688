import { ReactComponent as Logo } from "@/assets/msLogo-gradient.svg";
import styled from "@emotion/styled";
import { FormEvent } from "react";
import { useNavigate, useParams } from "react-router";

function NoGuid() {
  const navigate = useNavigate();
  const { guid } = useParams();
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    //@ts-ignore
    const newGuid = event.target?.guid.value;
    navigate("/" + newGuid);
  };

  return (
    <Wrapper>
      <form className="input-wrapper" onSubmit={handleSubmit}>
        <Logo />
        <div>
          <p>Please enter a valid Project Id</p>
          <p className="no-guid-text">
            {guid ? "No proposal found for " + guid : " "}
          </p>
        </div>
        <input placeholder="Project Id" name="guid" required></input>
        <button type="submit">Submit</button>
      </form>
      <h2>momentum</h2>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  width: 100%;
  margin: auto;

  input {
    border: none;
    border-bottom: solid 1px grey;
    background-color: transparent;
    padding: 8px 24px;
    font-size: 16px;
    font-family: Gotham;
    text-align: center;
    width: 100%;
    max-width: 400px;
  }

  p {
    text-align: center;
    font-family: Lato;
    font-size: 20px;
  }

  .no-guid-text {
    color: grey;
    font-size: 16px;
  }

  input:focus {
    outline: none;
    border-bottom-color: #00ccff;
    background-color: transparent;
  }

  button {
    font-size: 20px;
    border-radius: 4px;
    border: none;
    background-color: #336699;
    color: white;
    font-family: Gotham;
    padding: 8px 24px;
  }

  .input-wrapper {
    min-height: 30%;
    min-width: 40%;
    height: 30%;
    width: 40%;
    gap: 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 34px;
  }

  h2 {
    color: #336699;
    font-size: 40px;
    margin-top: 0px;
  }
`;

export default NoGuid;
