import { motion } from "framer-motion";
import { PropsWithChildren } from "react";

type CarouselItemProps = PropsWithChildren<{
  direction: number;
  goToNext: (direction: number) => void;
}>;

const swipeConfidenceThreshold = 10000;
const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

function CarouselItem({
  children,
  direction = 1,
  goToNext,
}: CarouselItemProps) {
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  return (
    <motion.div
      custom={direction}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
      style={{
        position: "absolute",
        top: 0,
        zIndex: -1,
        display: "flex",
        minWidth: "100%",
        height: "100%",
      }}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={1}
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset.x, velocity.x);

        if (swipe < swipeConfidenceThreshold) {
          goToNext(-1);
        } else if (swipe > -swipeConfidenceThreshold) {
          goToNext(1);
        }
      }}
    >
      {children}
    </motion.div>
  );
}

export default CarouselItem;
