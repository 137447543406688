import { default as CardBase } from "@/Components_DEP/Card";
import CardHeader from "@/Components_DEP/Card/CardHeader";
import Context from "@/Context";
import { money } from "@/utils/helpers";
import styled from "@emotion/styled";
import { useContext } from "react";
import InfoBar from "./InfoBar";
import InfoRow from "./InfoRow";
import PillDisplay from "./PillOverlay";
import { NYCZipcodes } from "@/utils/stateCalculations/utilFuncs";

function TotalSystemCost() {
  const { proposal } = useContext(Context);
  const {
    system_epc = 0,
    incentive_rebate = 0,
    battery_cost = 0,
    battery_rebate = 0,
    property_state = "",
    property_zip = 0,
    type,
  } = proposal || {};

  const rebate = incentive_rebate + battery_rebate;

  return (
    <Card>
      <CardHeader label="Total System Cost" />
      <PillDisplay
        value={`${Math.round(proposal?.irr || 0)}%`}
        label="Estimated 30-Year Inter Rate of Return (IRR)"
        variant="header"
      />
      <div className="card-content">
        <InfoRow>
          <p>Solar System Cost</p>
          <p>{money(system_epc)}</p>
        </InfoRow>
        {battery_cost > 0 && (
          <InfoRow>
            <p>Battery System Cost</p>
            <p>{money(battery_cost)}</p>
          </InfoRow>
        )}
        {/* {isRoofing && (
          <InfoRow>
            <p>Roof Cost</p>
            <p>{money(roof_cost)}</p>
          </InfoRow>
        )} */}
        {/* {["TX", "CT", "NY", "AZ"].includes(State) && ( */}
        {rebate > 0 ? (
          <>
            <InfoRow>
              <p>Rebate</p>
              <p>{money(rebate)}</p>
            </InfoRow>
            <InfoBar>
              <InfoRow>
                <p>Total System Cost</p>
                <p>{money(system_epc + battery_cost)}</p>
              </InfoRow>
              <InfoRow>
                <p>Net Solar System Cost After Rebate</p>
                <p>{money(system_epc + battery_cost - rebate)}</p>
              </InfoRow>
            </InfoBar>
          </>
        ) : battery_cost > 0 ? (
          <InfoBar>
            <InfoRow>
              <p>Total System Cost</p>
              <p>{money(system_epc + battery_cost)}</p>
            </InfoRow>
          </InfoBar>
        ) : null}
        <InfoRow>
          <p>Less: Estimated 30% Federal Tax Credit</p>
          <p>{money(proposal?.incentive_federal_tax_credit)}</p>
        </InfoRow>
        {["NY", "AZ", "MA"].includes(property_state) && (
          <InfoRow>
            <p>
              {property_state === "NY"
                ? `NY State Tax Credit`
                : `State Tax Credit`}
            </p>
            {(property_state === "NY" ||
              property_state === "AZ" ||
              property_state === "MA") && (
              <p>{money(proposal?.incentive_state_tax_credit)}</p>
            )}
          </InfoRow>
        )}
        {["NY", "AZ"].includes(property_state) &&
          NYCZipcodes.includes(+property_zip) && (
            <InfoRow>
              <p>Property Tax Abatement</p>
              <p>{money(proposal?.incentive_property_tax_abatement || 0)}</p>
            </InfoRow>
          )}
        <InfoBar>
          <InfoRow>
            <p>Net Solar System Cost</p>
            <p>
              {money(
                (proposal?.system_epc || 0) +
                  battery_cost -
                  (proposal?.incentive_rebate || 0) -
                  (proposal?.incentive_state_tax_credit || 0) -
                  (proposal?.incentive_federal_tax_credit || 0) -
                  (proposal?.incentive_property_tax_abatement || 0)
              )}
            </p>
          </InfoRow>
        </InfoBar>
      </div>
      <div>
        <p className="footer">
          *Savings calculated solely on the solar system cost.
        </p>
        <p className="footer">
          Tax credits, rebates and incentives may very depending on your
          circumstances. We do not provide tax advice and recommend you consult
          with your tax professional for guidance.
        </p>
      </div>
    </Card>
  );
}

export default TotalSystemCost;

const Card = styled(CardBase)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;

    margin-top: 16px;
    height: 100%;
  }

  .footer {
    color: #a5a5a5;
    font-family: Gotham Medium;
    font-size: 8px;
    width: 80%;
    text-align: center;
    margin: 3px auto;
  }
`;
