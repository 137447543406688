import styled from "@emotion/styled";

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  margin: 0px 32px;
  gap: 12px;

  p:nth-of-type(2) {
    align-self: center;

    font-family: Gotham Medium;
    text-align: right;
    margin-right: 12px;
  }

  *:nth-of-type() {
    min-width: 15%;
  }
`;

export default InfoRow;
