import FullPage from "@/Components_DEP/FullPage";
import styled from "@emotion/styled";

import { ReactComponent as Splash01 } from "@/assets/splash/01.svg";
import { ReactComponent as SystemSize } from "@/assets/design/system-size.svg";
import { ReactComponent as EstConsumption } from "@/assets/design/estimated-consumption.svg";
import { ReactComponent as EstGeneration } from "@/assets/design/estimated-generation.svg";

import Card from "@/Components_DEP/Card";
import DesignCard1 from "./DesignCard1";
import DesignCard2 from "./DesignCard2";
import { useContext, useState } from "react";
import Context from "@/Context";
import { commas, round } from "@/utils/helpers";
import ImageModal from "@/Components_DEP/ImageModal";
import StorageCard from "./StorageCard";
import { OptionalLicenseNumbers } from "@/Components/LicenseNumbers";

function Design() {
  const { proposal_dep } = useContext(Context);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  return (
    <Wrapper>
      {imageModalOpen && (
        <ImageModal
          src={proposal_dep?.designUrl || ""}
          setOpen={setImageModalOpen}
        />
      )}
      <div className="content">
        <h2>Solar System Design Overview</h2>

        <div className="row">
          <img
            className="design-img"
            src={proposal_dep?.designUrl}
            alt="Homeowner's Design"
            onClick={() => {
              setImageModalOpen(true);
            }}
          />
          <div className="col">
            <Card className="card-small">
              <SystemSize />
              <p>System Size</p>
              <p className="text">{round(proposal_dep?.size, 3)} kW</p>
            </Card>
            <Card className="card-small">
              <EstConsumption />
              <p>
                1<sup>st</sup> Year Estimated Consumption
              </p>
              <p className="text">{commas(proposal_dep?.consumption)} kWh</p>
            </Card>
            <Card className="card-small">
              <EstGeneration />
              <p>
                1<sup>st</sup> Year Estimated Generation
              </p>
              <p className="text">{commas(proposal_dep?.production)} kWh</p>
            </Card>
          </div>
        </div>
        <DesignCard1 />
        {proposal_dep?.generacSystem && <StorageCard />}
        <DesignCard2 />
        <p className="footer">
          <sup>*</sup>Design subject to change
          <OptionalLicenseNumbers
            orientation="horizontal"
            style={{ margin: "auto" }}
          />
        </p>
      </div>

      <div className="bg-wrapper">
        <Splash01 />
      </div>
    </Wrapper>
  );
}

export default Design;

const Wrapper = styled(FullPage)`
  position: relative;
  overflow-x: clip;
  height: 100%;

  .content {
    position: relative;
    z-index: 1000;
    padding-top: clamp(32px, calc(2rem + ((1vw - 3.44px) * 8.0163)), 70px);
    display: flex;
    flex-direction: column;
    max-width: 100%;

    gap: 1em;

    h2 {
      color: #336699;
      margin-bottom: 8px;
    }

    .design-img {
      max-width: 55%;
      flex: 1.6;
      aspect-ratio: 1;
      object-fit: cover;
      background-color: #00ccff;
      border-radius: 10px;
    }

    .card-small {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 6px;

      p {
        white-space: nowrap;
        font-family: Gotham Medium;
        color: #4b4b4b;
      }

      .text {
        color: #c5c5c5;
        font-size: 24px;
      }
    }
  }

  .row {
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    gap: 1em;
  }

  .col {
    min-height: 100%;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 1em;
  }

  .bg-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    top: -6em;

    z-index: 10;

    svg {
      position: absolute;
      top: 0%;
      left: -10%;
      width: 130%;
      /* height: auto; */
    }
  }

  .footer {
    text-align: center;
    margin-bottom: 32px;
    color: #cccccc;
  }
`;

const Line = styled.div`
  width: 300px;
  height: 1px;
  background-color: #cccccc66;
  margin: 4px auto;
`;
