import { ReactComponent as SplashBase } from "@/assets/savingsSummary/world-splash.svg";
import styled from "@emotion/styled";

const Splash = styled(SplashBase)`
  position: absolute;
  top: 0;
  z-index: 300;

  /* transform: rotate(0deg);

  -webkit-animation: spin 50s linear infinite;
  -moz-animation: spin 50s linear infinite;
  animation: spin 50s linear infinite;

  -webkit-animation-delay: 1.4s;
  -moz-animation-delay: 1.4s;
  animation-delay: 1.4s;

  @-moz-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  } */
`;

export default Splash;
