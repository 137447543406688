import { default as CardBase } from "@/Components_DEP/Card";
import CardHeader from "@/Components_DEP/Card/CardHeader";
import Context from "@/Context";
import {
  getLocalTotalCost,
  getLocalUtilityBill,
  getMonthlySolarBill,
  getMSSolarBill,
  getMSTotalCost,
  getMSUtilityBill,
  getPropertyTaxAbatement,
  getStateTaxCredit,
} from "@/utils/calculations";
import { money } from "@/utils/helpers";
import styled from "@emotion/styled";
import { useContext } from "react";
import InfoBar from "./InfoBar";
import InfoRow from "./InfoRow";

function SavingsByTheNumbers() {
  const { proposal_dep } = useContext(Context);
  const { utilityRate, solarRate, State, proposalType } = proposal_dep || {};
  return (
    <Card>
      <CardHeader label="Your Path to Savings by the Numbers" />
      <div className="content-wrapper">
        <div className="table">
          <div />
          <p className="column-head">Your Local Utility</p>
          <p className="column-head" style={{ transform: "translate(-56px)" }}>
            Momentum Solar{proposalType !== "MSES" && " + IGS"}
          </p>
          <p>Utility Rate</p>
          <p className="cell" style={{ textAlign: "start" }}>
            ${utilityRate?.toFixed(3)}/kWh
          </p>
          <p className="cell" style={{ textAlign: "start" }}>
            ${solarRate?.toFixed(3)}/kWh
          </p>
          <p>Utility Bill</p>
          <p className="light cell">
            {money(getLocalUtilityBill(proposal_dep))}
          </p>
          <p className="light cell">{money(getMSUtilityBill(proposal_dep))}</p>
          <p>Solar Bill</p>
          <p className="light cell">$0.00</p>
          <p className="light cell">{money(getMSSolarBill(proposal_dep))}</p>
          <div className="line" />
          <p>Total cost:</p>
          <p className="cell">{money(getLocalTotalCost(proposal_dep))}</p>
          <p className="cell">{money(getMSTotalCost(proposal_dep))}</p>
        </div>
        <InfoBar>
          {State === "NY" && (
            <InfoRow>
              <p>New York State Tax Credit:</p>
              <p>{money(getStateTaxCredit(proposal_dep))}</p>
            </InfoRow>
          )}
          {State === "NY" &&
            proposal_dep &&
            !!getPropertyTaxAbatement(proposal_dep) && (
              <InfoRow>
                <p>Property Tax Abatement: </p>
                <p>
                  {proposal_dep && money(getPropertyTaxAbatement(proposal_dep))}
                </p>
              </InfoRow>
            )}
          <InfoRow>
            <p> Total Upfront Cost to Go Solar</p>
            <p>$0.00</p>
          </InfoRow>
          {State === "FL" && (
            <InfoRow>
              <p> Estimated Monthly Cost</p>
              <p>{money(getMonthlySolarBill(proposal_dep))}</p>
            </InfoRow>
          )}
        </InfoBar>
      </div>
    </Card>
  );
}

export default SavingsByTheNumbers;

const Card = styled(CardBase)`
  position: relative;
  width: 100%;
  max-width: min(100%, calc(100vw - 96px));
  display: flex;
  flex-direction: column;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .table {
    display: grid;
    grid-template-columns: 40% 40% 20%;
    gap: 6px;
    padding: 12px;

    p.cell {
      font-family: Gotham Medium;
      white-space: nowrap;
      text-align: end;
      width: 92px;
    }

    p.light {
      font-family: Gotham Book;
    }

    .column-head {
      border: 1px solid #0cceff;
      color: #0cceff;
      border-radius: 100px;
      margin-right: auto;
      transform: translate(-8px);
      padding: 2px 6px;
      white-space: nowrap;
    }

    .line {
      grid-column: span 3;
      height: 1px;
      background: #4b4b4b;

      width: calc(100% + 56px);
      transform: translate(-28px);
    }
  }
`;
