import Context from "@/Context";
import styled from "@emotion/styled";
import { Dispatch, SetStateAction, useContext } from "react";
import Card from "../Card";
import { ReactComponent as XIcon } from "@/assets/x-icon.svg";
import { ReactComponent as CopyIcon } from "@/assets/copy-icon.svg";

type MoreDetailsModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function MoreDetailsModal({ open, setOpen }: MoreDetailsModalProps) {
  const { proposal_dep } = useContext(Context);
  const { CustomerName, City, Address, State, Zip, oppnum, _id } =
    proposal_dep || {};
  return (
    <Wrapper style={{ height: open ? "100%" : "0%" }}>
      <Background className={open ? "open" : "closed"}>
        {open && (
          <Card style={{ padding: "2em", position: "relative" }}>
            <CloseButton onClick={() => setOpen(false)}>
              <XIcon width={32} />
            </CloseButton>

            <h3>{CustomerName}</h3>
            <Line />
            <div>
              <p>{Address}</p>
              <p>
                {City}, {State} {Zip}
              </p>
            </div>

            <Content>
              <p>Opportunity: </p>
              <p>{oppnum}</p>
              <span />
              <p>Proposal Id: </p>
              <p>{_id}</p>
              <CopyButton
                onClick={() => navigator.clipboard.writeText(_id || "")}
              >
                <CopyIcon />
              </CopyButton>
            </Content>
          </Card>
        )}
      </Background>
    </Wrapper>
  );
}

export default MoreDetailsModal;

const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  width: 100%;
  top: 0;
  left: 0;

  .open {
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
  }

  .closed {
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
  }
`;

const Background = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  top: 0;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #336699;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  gap: 8px;
  align-items: center;
  margin-top: 1em;

  p {
    font-size: 18px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  color: lightgrey;
  background-color: transparent;
  border: none;
  padding: 1em;
`;

const CopyButton = styled.button`
  background-color: transparent;
  border: none;
  color: #336699;
`;
