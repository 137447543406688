import {
  getFederalTaxCreditRate,
  getNYAnnualCBCCost,
} from "../../calculations";
import { numberWithCommas, cleanData } from "../utilFuncs";

// ! if consumption - production is negative multiply by wholesale not utility; (Everything);
// ! for cash flow on nj cash
// ! epc is system cost
// ! production up to 100% of consumption * utility rate

const createCashTable = (data) => {
  let {
    EPC,
    consumption,
    production,
    utilityRate,
    utilityEscalator,
    wholesaleRate,
    rebate = 0,
    State,
  } = data;
  EPC = +EPC;
  consumption = +consumption;
  production = +production;
  utilityRate = +utilityRate;
  utilityEscalator = +utilityEscalator;
  wholesaleRate = +wholesaleRate;
  EPC -= +rebate;
  let federalTaxCredit = getFederalTaxCreditRate() * EPC;

  let stateTaxCredit = () => {
    if (State.toLowerCase() === "az") {
      if (EPC * 0.25 >= 1000) {
        return 1000;
      } else {
        return EPC * 0.25;
      }
    } else {
      if (EPC * 0.25 >= 5000) {
        return 5000;
      } else {
        return EPC * 0.25;
      }
    }
  };

  // keep in mind that this utilityEscaltor reassignment is with the format wholenumber/decimal being passed (i.e. 3.9%);
  utilityEscalator = utilityEscalator / 100 + 1;

  //full array to fill and return at the end;
  let fullArr = [];

  const electricBillSavings = (
    currProduction,
    currUtilityRate,
    futureWholesaleRate
  ) => {
    if (Math.sign(currProduction - consumption) === -1) {
      return currProduction * currUtilityRate - getNYAnnualCBCCost(data);
    } else {
      return (
        currProduction * currUtilityRate +
        (currProduction - consumption) * futureWholesaleRate -
        getNYAnnualCBCCost(data)
      );
    }
  };

  const electricBillAfterSolar = (
    currProduction,
    currUtilityRate,
    futureWholesaleRate
  ) => {
    if (Math.sign(consumption - currProduction) === -1) {
      return (
        (consumption - currProduction) * futureWholesaleRate +
        getNYAnnualCBCCost(data)
      );
    } else {
      return (
        (consumption - currProduction) * currUtilityRate +
        getNYAnnualCBCCost(data)
      );
    }
  };

  const savings = (
    currProduction,
    currUtilityRate,
    years,
    futureWholesaleRate
  ) => {
    let currSavings = electricBillSavings(
      currProduction,
      currUtilityRate,
      futureWholesaleRate
    );

    if (years === 1) {
      return currSavings - EPC + federalTaxCredit + stateTaxCredit();
    } else {
      return currSavings;
    }
  };

  const total = (currProduction, currUtilityRate, years, futureWholesaleRate) =>
    fullArr[years - 2]
      ? fullArr[years - 2][8] +
        savings(currProduction, currUtilityRate, years, futureWholesaleRate)
      : savings(currProduction, currUtilityRate, years, futureWholesaleRate);

  let futureUtilRate = utilityRate;
  let futureProductionRate = production;
  let futureWholesaleRate = wholesaleRate;
  for (let i = 1; i < 31; i++) {
    futureUtilRate = i > 1 ? futureUtilRate * utilityEscalator : utilityRate;
    futureProductionRate =
      i > 1
        ? futureProductionRate - futureProductionRate * 0.005
        : futureProductionRate;
    futureWholesaleRate =
      i > 1 ? futureWholesaleRate * utilityEscalator : futureWholesaleRate;

    fullArr.push([
      i,
      i === 1 ? EPC : "",
      electricBillSavings(
        futureProductionRate,
        futureUtilRate,
        futureWholesaleRate
      ),
      electricBillAfterSolar(
        futureProductionRate,
        futureUtilRate,
        futureWholesaleRate
      ),
      futureProductionRate,
      i === 1 ? federalTaxCredit : "",
      i === 1 ? stateTaxCredit() : "",
      savings(futureProductionRate, futureUtilRate, i, futureWholesaleRate),
      total(futureProductionRate, futureUtilRate, i, futureWholesaleRate),
    ]);
  }

  let electricBillTotal = 0;
  let electricBillAfterTotal = 0;
  let cashFlowTotal = 0;
  let pvGenTotal = 0;

  fullArr.forEach((x) => {
    electricBillTotal += +x[2];
    electricBillAfterTotal += +x[3];
    pvGenTotal += +x[4];
    cashFlowTotal += x[7];
  });

  const cashTableVar = {
    totalElectricBillSavings: electricBillTotal,
    totalElectricAfterSolar: electricBillAfterTotal,
    totalAnnualCashFlow: cashFlowTotal,
  };

  const lastArr = [
    "Total",
    "",
    cleanData(electricBillTotal),
    cleanData(electricBillAfterTotal),
    numberWithCommas(Math.round(pvGenTotal)),
    cleanData(federalTaxCredit),
    cleanData(stateTaxCredit()),
    cleanData(cashFlowTotal),
    "",
  ];

  const irrValues = fullArr.reduce((arr, curr, index) => {
    if (index !== fullArr.length) arr.push(curr[7]);
    return arr;
  }, []);

  return mappedCashVals(fullArr, lastArr, irrValues, cashTableVar);
};

const mappedCashVals = (vals, lastArr, irrValues, cashTableVar) => {
  let finalArray = vals.reduce((array, curr, index) => {
    let newArr = [];
    newArr.push(
      index + 1,
      curr[1] ? `(${cleanData(curr[1])})` : "",
      cleanData(curr[2]),
      cleanData(curr[3]),
      numberWithCommas(Math.round(curr[4])) || "",
      curr[5] ? cleanData(curr[5]) : "",
      curr[6] ? cleanData(curr[6]) : "",
      cleanData(curr[7]),
      cleanData(curr[8])
    );
    array.push(newArr);
    return array;
  }, []);

  return [[...finalArray, lastArr], irrValues, cashTableVar];
};

export default createCashTable;
