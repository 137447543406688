import styled from "@emotion/styled";
import { ReactNode, useRef, useState, LegacyRef } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useScrollNavigation } from "../ScrollNavigator";

type NavButtonProps = {
  Icon: any;
  label: string;
  sectionRef: any;
  setScrollOffset: any;
  index: number;
};

function NavButton({
  Icon,
  label,
  sectionRef,
  setScrollOffset,
  index,
}: NavButtonProps) {
  const [sectionIsVisible, setSectionIsVisible] = useState<boolean>(true);
  const { scrollToIndex } = useScrollNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const ref = useRef<any>();

  useEffect(() => {
    // TODO: this logic could maybe be moved inside useScrollNavigation
    const observer = new IntersectionObserver(
      ([entry]) => {
        setSectionIsVisible(entry.isIntersecting);
        if (entry.isIntersecting) {
          setScrollOffset(ref?.current?.offsetLeft ?? 0);
          setSearchParams((prev) => ({ ...prev, page: label }));
        }
      },
      { rootMargin: "-50%" }
    );
    if (!sectionRef) return;
    if (sectionRef.current) {
      const ref = sectionRef.current;
      observer.observe(ref);
      return () => observer.unobserve(ref);
    }
  }, []);

  const color = sectionIsVisible ? "#00ccff" : "inherit";

  return (
    <Wrapper onClick={() => scrollToIndex(index)} style={{ color }} ref={ref}>
      {Icon && (
        <Icon
          style={{
            color: sectionIsVisible ? "#00ccff" : "#d0d0d0",
            width: "30px",
            height: "28px",
            aspectRatio: 1,
          }}
        />
      )}
      <p>{label}</p>
    </Wrapper>
  );
}

export default NavButton;

const Wrapper = styled.button`
  background: none;
  border: none;

  p {
    font-size: 16px;
    font-family: Gotham Book;
    white-space: nowrap;
  }

  /* svg {
    color: #d9d9d9;
  } */
`;
