import { default as CardBase } from "@/Components_DEP/Card";
import CardHeader from "@/Components_DEP/Card/CardHeader";
import Context from "@/Context";
import { money } from "@/utils/helpers";

import styled from "@emotion/styled";
import { useContext } from "react";
import InfoBar from "./InfoBar";
import InfoRow from "./InfoRow";
import PillDisplay from "./PillOverlay";

function TotalSavings() {
  const { proposal } = useContext(Context);

  return (
    <Card>
      <CardHeader label="Total Savings" />
      <PillDisplay
        value={proposal?.estimated_lifetime_savings_percent}
        label="Estimated Lifetime Savings (%)"
      />
      <div className="card-content">
        <InfoRow>
          <p>Pre-Solar Cost</p>
          <p>{money(proposal?.pre_solar_cost || 0)}</p>
        </InfoRow>

        <InfoRow>
          <p>Post-Solar Cost</p>
          <p>{money(proposal?.post_solar_cost || 0)}</p>
        </InfoRow>

        {proposal?.incentive_qualifies_for_pbi && (
          <InfoRow>
            <p>Lifetime Estimated PBI Income</p>
            <p>{money(proposal?.incentive_lifetime_pbi_income || 0)}</p>
          </InfoRow>
        )}

        <InfoBar>
          <InfoRow>
            <p>Total Savings</p>
            <p>
              {money(
                (proposal?.total_savings || 0) +
                  (proposal?.incentive_lifetime_pbi_income ?? 0)
              )}
            </p>
          </InfoRow>
        </InfoBar>
      </div>
      <p className="footer">
        Tax credits, rebates and incentives may very depending on your
        circumstances. We do not provide tax advice and recommend you consult
        with your tax professional for guidance.
      </p>
    </Card>
  );
}

export default TotalSavings;

const Card = styled(CardBase)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    margin-top: 16px;
    height: 100%;
  }

  .footer {
    color: #a5a5a5;
    font-family: Gotham Medium;
    font-size: 8px;
    width: 50%;
    text-align: center;
    margin: auto;
  }
`;
