import { ReactComponent as Base } from "@/assets/savingsSummary/world-thermometer-base.svg";
import { ReactComponent as Bar } from "@/assets/savingsSummary/world-thermometer-bar.svg";
import styled from "@emotion/styled";

function Thermometer() {
  return (
    <Wrapper>
      <Base />
      <div className="bar" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  right: 32%;
  top: 35%;
  z-index: 1000;
  width: 4.3%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .bar {
    position: absolute;
    height: 70%;
    width: 20%;
    background-color: #fa8564;
    margin-bottom: 40%;
    border-radius: 120px;

    -webkit-animation: grow 3s cubic-bezier(0.68, -0.6, 0.32, 1.6) infinite
      alternate;
    -moz-animation: grow 3s cubic-bezier(0.68, -0.6, 0.32, 1.6) infinite
      alternate;
    animation: grow 3s cubic-bezier(0.68, -0.6, 0.32, 1.6) infinite alternate;
  }

  @-moz-keyframes grow {
    to {
      height: 50%;
    }
  }
  @-webkit-keyframes grow {
    to {
      height: 50%;
    }
  }
  @keyframes grow {
    to {
      height: 55%;
    }
  }
`;

export default Thermometer;
