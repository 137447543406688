import Context from "@/Context";
import { getMonthlyStorageCost, getStorageLoan } from "@/utils/calculations";
import { money } from "@/utils/helpers";
import { useContext } from "react";
import OverviewCard from "./OverviewCard";

function StorageOverview() {
  const { proposal_dep } = useContext(Context);
  const { generacSystem, loanTerm, interest } = proposal_dep || {};

  const { batteryCost = 0, batteryRebate = 0 } = generacSystem || {};
  return (
    <OverviewCard header="Storage Overview">
      <p>Storage Cost: </p>
      <p>{money(batteryCost)}</p>
      <p>Battery Rebate: </p>
      <p>{money(batteryRebate)}</p>
      {/* <p>Storage Loan: </p>
      <p>{money(getStorageLoan(proposal))}</p> */}
      <p>Monthly Storage System Cost </p>
      <p>{money(getMonthlyStorageCost(proposal_dep))}</p>
      <p>Battery Term: </p>
      <p>{loanTerm} years</p>
      <p>Term Interest Rate: </p>
      <p>{interest || 2.9}%</p>
    </OverviewCard>
  );
}

export default StorageOverview;
