import { ReactNode, useEffect, useRef } from "react";
import { useScrollNavigation } from "./ScrollNavigatorContext";
import { ScrollSectionProps } from "./ScrollSection";

type ScrollSectionRefProps = ScrollSectionProps & {
  children?: ReactNode | ReactNode[];
  setScrollRefs?: any;
  index: number;
};

function ScrollSectionRef({
  children,
  index,
  Icon,
  label,
}: ScrollSectionRefProps) {
  const sectionRef = useRef<any>();
  const { setScrollRefs } = useScrollNavigation();

  useEffect(() => {
    setScrollRefs((prev: any) => {
      prev[index] = { sectionRef, Icon, label };
      return prev;
    });
  }, []);

  return (
    <div ref={sectionRef} style={{ scrollSnapAlign: "start" }}>
      {children}
    </div>
  );
}

export default ScrollSectionRef;
