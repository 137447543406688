import FullPage from "@/Components_DEP/FullPage";
import styled from "@emotion/styled";

import { ReactComponent as Splash } from "@/assets/splash/02.svg";

import ReferralCard1 from "./ReferralCard1";
import ReferralCard2 from "./ReferralCard2";
import ReferralCard3 from "./ReferralCard3";
import { OptionalLicenseNumbers } from "@/Components/LicenseNumbers";

function Referrals() {
  return (
    <Wrapper>
      <div className="content">
        <h2>Referrals</h2>
        <ReferralCard1 />
        <ReferralCard2 />
        {/* <ReferralCard3 /> */}
        <OptionalLicenseNumbers
          orientation="horizontal"
          style={{ margin: "auto" }}
          color="#fff"
        />
      </div>
      <div className="bg-wrapper">
        <div className="splash-wrapper">
          <Splash />
        </div>
      </div>
    </Wrapper>
  );
}

export default Referrals;

const Wrapper = styled(FullPage)`
  position: relative;
  height: 100%;
  padding-top: 70px;
  min-height: 100vh;

  .content {
    position: relative;
    z-index: 500;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  h2 {
    color: #336699;
    justify-self: flex-start;
  }

  .bg-wrapper {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: -8vh;
    left: 0%;
    z-index: 100;

    .splash-wrapper {
      width: 100%;
      margin: 0px;
      display: flex;
      overflow: hidden;
      position: relative;
      z-index: 1;

      svg {
        width: 100%;
        transform: scale(1.25) translateY(3%);
      }
    }
  }
`;
