import styled from "@emotion/styled";

import { ReactComponent as HomeInstall } from "@/assets/homeValue/home-install-base.svg";
import Sun from "./Sun";
import Truck from "./Truck";
import Equipment from "./Equipment";
import Worker from "./Worker";
import Workers from "./Workers";
import { useRef } from "react";
import useIntersectionObserver from "@/utils/useIntersectionObserver";

function HomeAnimation() {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { rootMargin: "-50%" });
  const isVisible = !!entry?.isIntersecting;

  return (
    <Wrapper>
      <div ref={ref}>
        <Sun />
        {isVisible && (
          <>
            <Truck />
            <Equipment />
            <Worker />
            <Workers />
          </>
        )}
        <HomeInstall />
      </div>
    </Wrapper>
  );
}

export default HomeAnimation;

const Wrapper = styled.div`
  position: relative;
`;
