import styled from "@emotion/styled";
import { ReactComponent as World } from "@/assets/savingsSummary/world-base.svg";
import { ReactComponent as RadialGradient } from "@/assets/splash/radial-gradient.svg";
import Splash from "./Splash";
import Leaves from "./Leaves";
import Clouds from "./Clouds";
import Squiggles from "./Squiggles";
import Thermometer from "./Thermometer";

function WorldAnimation() {
  return (
    <Wrapper>
      <RadialGradient className="world-bg" />
      <Splash />
      <World height={"240px"} style={{ zIndex: 500 }} />
      <Leaves />
      <Clouds />
      <Squiggles />
      <Thermometer />
    </Wrapper>
  );
}

export default WorldAnimation;

const Wrapper = styled.div`
  position: relative;
  margin: 42px 0px;
  grid-row: span 2;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .world-bg {
    position: absolute;
    color: white;
    width: min(93vw, 1080px);
    z-index: 300;
  }
`;
