import styled from "@emotion/styled";
import React, { PropsWithChildren } from "react";

type CardHeaderProps = PropsWithChildren<{ label?: string }>;

function CardHeader({ label, children }: CardHeaderProps) {
  return (
    <Wrapper>
      {label && <h6 className="header-label">{label}</h6>}
      {children}
    </Wrapper>
  );
}

export default CardHeader;

const Wrapper = styled.div`
  background: #eafbff;
  min-height: 54px;
  width: calc(100% + 32px); // 100% + (2 x Card padding)

  margin: -16px; // -1 x Card padding to get background to edge of element
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
