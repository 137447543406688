import { default as CardBase } from "@/Components_DEP/Card";

import styled from "@emotion/styled";

function ReferralCard2() {
  return (
    <Card>
      <h2>
        Wouldn't you like an extra <span>$300</span> yourself?
      </h2>
      <p>
        Connect friends and loved ones with Solar Connect
        <br />
        and achieve uncapped earnings.
      </p>
    </Card>
  );
}

export default ReferralCard2;
const Card = styled(CardBase)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  background-color: transparent;
  border: 2px solid #336699;
  text-align: center;

  h2 {
    color: white !important;
  }

  svg {
    margin-bottom: 16px;
  }

  p {
    font-family: Gotham Book;
    text-align: center;
  }

  .item {
    text-align: center;
  }

  span {
    color: #336699;
  }
`;
