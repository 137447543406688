import FullPage from "@/Components_DEP/FullPage";
import styled from "@emotion/styled";
import { ReactComponent as MSLogo } from "@/assets/msLogo-text.svg";
import MSLogoPNG from "@/assets/msLogo-text.png";
import ReviewCard from "./ReviewCard";
import FooterCard from "./FooterCard";
import { useRef } from "react";
import useIntersectionObserver from "@/utils/useIntersectionObserver";

function ThankYou() {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  return (
    <Wrapper>
      {/* <MSLogo style={{ marginTop: "70px", zIndex: 500 }} /> */}
      <img
        className="logo"
        src={MSLogoPNG}
        alt="Momentum Solar"
        style={{ marginTop: "2em", zIndex: 500 }}
      />
      <div ref={ref}>
        <h1 className={`${isVisible ? "animate" : ""}`}>Thank You</h1>
      </div>
      <ReviewCard />
      <FooterCard />
    </Wrapper>
  );
}

export default ThankYou;

const Wrapper = styled(FullPage)`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 70px;

  .animate {
    -webkit-animation: fade 0.4s ease-out forwards;
    -moz-animation: fade 0.4s ease-out forwards;
    animation: fade 0.4s ease-out forwards;

    -webkit-animation-delay: 0.3s;
    -moz-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  h1 {
    color: #0cceff;
    margin: 25px 0;
    z-index: 500;
    -webkit-transform: translate(10px);
    transform: translate(10px);
    opacity: 0;
  }

  img.logo {
    width: 272px;
  }

  @-moz-keyframes fade {
    to {
      -webkit-transform: translate(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade {
    to {
      -webkit-transform: translate(0px);
      opacity: 1;
    }
  }
  @keyframes fade {
    to {
      transform: translate(0px);
      -webkit-transform: translate(0px);
      opacity: 1;
    }
  }
`;
