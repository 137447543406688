import { cleanData } from "../utilFuncs";

const createLoanTable = (data) => {
  let {
    consumption,
    production,
    utilityRate,
    loanTerm,
    wholesaleRate,
    oldAnnualBill,
    utilityEscalator,
    loanPayment,
    utility,
  } = data;
  utilityEscalator = +utilityEscalator;
  consumption = +consumption;
  oldAnnualBill = +oldAnnualBill;
  production = +production;
  utilityRate = +utilityRate;
  loanTerm = +loanTerm;
  loanPayment = +loanPayment;
  wholesaleRate = +wholesaleRate;

  utilityEscalator = utilityEscalator / 100 + 1;

  let annualRECIncome = 0; //TODO: rename and replace this for actual PPA rules

  const yearly = (currentProduction, futureUtilRate, futureWholesaleRate) => {
    if (process.env.ENV === "DEV") {
      return (consumption - currentProduction) * futureUtilRate;
    }

    if (Math.sign(consumption - currentProduction) === -1) {
      return (consumption - currentProduction) * futureWholesaleRate;
    } else {
      return (consumption - currentProduction) * futureUtilRate;
    }
  };

  let fullArr = [];

  const futureVal = (investment, rate, index, years) => {
    return fullArr[years - 2] ? fullArr[years - 2][index] * rate : investment;
  };

  const savings = (oldVal, newVal, increase, years) => {
    let old = futureVal(oldVal, increase, 1, years);
    let current = newVal;

    if (years < +loanTerm + 1) {
      return old - current - loanPayment * 12;
    } else {
      return old - current;
    }
  };

  const total = (oldVal, newVal, increase, years) =>
    fullArr[years - 2]
      ? fullArr[years - 2][6] + savings(oldVal, newVal, increase, years)
      : savings(oldVal, newVal, increase, years);
  let futureProductionRate = production;
  let futureUtilRate = utilityRate;
  let futureWholesaleRate = wholesaleRate;
  for (let i = 1; i < 31; i++) {
    futureUtilRate = i > 1 ? futureUtilRate * utilityEscalator : utilityRate;
    futureProductionRate =
      i > 1
        ? futureProductionRate - futureProductionRate * 0.005
        : futureProductionRate;
    futureWholesaleRate =
      i > 1 ? futureWholesaleRate * utilityEscalator : futureWholesaleRate;
    // annualRECIncome = i > 20 ? 0 : annualRECIncome;,

    fullArr.push([
      i,
      futureVal(oldAnnualBill, utilityEscalator, 1, i),
      yearly(futureProductionRate, futureUtilRate, futureWholesaleRate),
      i < +loanTerm + 1 ? loanPayment * 12 : null,
      annualRECIncome,
      savings(
        oldAnnualBill,
        yearly(futureProductionRate, futureUtilRate, futureWholesaleRate) -
          annualRECIncome,
        utilityEscalator,
        i
      ),
      total(
        oldAnnualBill,
        yearly(futureProductionRate, futureUtilRate, futureWholesaleRate) -
          annualRECIncome,
        utilityEscalator,
        i
      ),
    ]);
  }
  let oldPayments = 0;
  let newPayments = 0;
  let solar = 0;
  let yearlyFull = 0;

  fullArr.forEach((x) => {
    oldPayments += +x[1];
    newPayments += +x[2];
    solar += +x[3];
    yearlyFull += +x[5];
  });

  const loanTableBar = { oldPayments, newPayments, yearlyFull };

  // Builds array of totals and labels. If utility is Eversource, add a placeholder "-" to keep spacing
  const lastArr = [
    "Total",
    cleanData(oldPayments),
    cleanData(newPayments),
    `(${cleanData(solar)})`,
  ];
  utility === "Eversource" && lastArr.push("-");
  lastArr.push(cleanData(yearlyFull), "-");

  return mappedVals(fullArr, lastArr, loanTableBar, utility);
};

const mappedVals = (vals, lastArr, loanTableBar, utility) => {
  let finalArray = vals.reduce((array, curr, index) => {
    // Builds array of values. If utility is Eversource, add the RECIncentive value to final array
    let newArr = [];
    newArr.push(
      index + 1,
      cleanData(curr[1]),
      cleanData(curr[2]),
      curr[3] ? `(${cleanData(curr[3])})` : "-"
    );
    utility === "Eversource" && newArr.push(curr[4] ? cleanData(curr[4]) : "-");
    newArr.push(cleanData(curr[5]), cleanData(curr[6]));

    array.push(newArr);
    return array;
  }, []);

  return [[...finalArray, lastArr], loanTableBar];
};

export default createLoanTable;
