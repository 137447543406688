import styled from "@emotion/styled";
import { ReactComponent as SunSvg } from "@/assets/homeValue/home-install-sun.svg";

const Sun = styled(SunSvg)`
  position: absolute;
  aspect-ratio: 1;
  left: 4%;
  top: 4%;
  height: 25%;
  -webkit-animation: rotate 50s linear infinite;
  -moz-animation: rotate 50s linear infinite;
  animation: rotate 50s linear infinite;

  @-moz-keyframes rotate {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
export default Sun;
