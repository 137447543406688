import styled from "@emotion/styled";

import { ReactComponent as Barrel } from "@/assets/savingsSummary/barrel.svg";
import { ReactComponent as Bottle } from "@/assets/savingsSummary/bottle.svg";
import { ReactComponent as Co2 } from "@/assets/savingsSummary/co2.svg";
import { ReactComponent as Trees } from "@/assets/savingsSummary/trees.svg";

import SavingsItem from "./SavingsItem";
import WorldAnimation from "./WorldAnimation";
import { useContext } from "react";
import Context from "@/Context";
import { commas } from "@/utils/helpers";

function SavingsCard2() {
  const { proposal } = useContext(Context);
  return (
    <Wrapper>
      <SavingsItem
        Icon={Co2}
        text="CO2 Emissions Reduced"
        value={commas(proposal?.offset_c02) + " Metric Tons"}
      />
      <WorldAnimation />
      <SavingsItem
        Icon={Barrel}
        text="Barrels of Oil Not Used"
        value={commas(proposal?.offset_oil_barrel)}
      />
      <SavingsItem
        Icon={Trees}
        text="Trees Planted"
        value={commas(proposal?.offset_trees)}
      />
      <SavingsItem
        Icon={Bottle}
        text="Bags of Recycling"
        value={commas(proposal?.offset_recycling)}
      />
      <p className="footnote">
        *Estimated 30 year values based on reports from the USDA and EPA.
        <br />
        Actual environmental offsets will very depending on location, household,
        local governance, time of year, etc.
      </p>
    </Wrapper>
  );
}

export default SavingsCard2;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 1em 0em;
  justify-content: center;
  align-content: center;
  grid-gap: 1em 1em;
  position: relative;

  .footnote {
    color: white;
    font-size: 12px;
    text-align: center;
    position: absolute;
    justify-self: center;
    bottom: -8px;
  }
`;
