import styled from "@emotion/styled";
import { CSSProperties, ReactNode } from "react";
type ColorProps = {
  background?: string;
  color?: string;
  style?: CSSProperties;
};
type OverviewCardProps = {
  header?: string | number;
  children?: JSX.Element | ReactNode | ReactNode[];
} & ColorProps;

function OverviewCard({
  header = "",
  children,
  background = "#f8f8f8",
  color = "#4b4b4b",
  style = {},
}: OverviewCardProps) {
  return (
    <Wrapper background={background} color={color} style={style}>
      <h6 className="header">{header}</h6>
      <div className="card">{children}</div>
    </Wrapper>
  );
}

export default OverviewCard;

const Wrapper = styled.div<ColorProps>`
  display: flex;
  flex-direction: column;
  height: 100%;

  h6.header {
    color: #4b4b4b;
    font-size: ${window.innerWidth > 900
      ? "14px"
      : window.innerWidth > 792
      ? "12px"
      : "10px"};
    font-family: Gotham Medium;
    margin-bottom: 8px;
  }

  .line {
    width: 100%;
    height: 1px;
    background: ${({ color }) => color};
    grid-column: span 2;
  }

  .card {
    border-radius: 8px;
    padding: 8px;
    background: ${({ background }) => background};
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    column-gap: 12px;
    row-gap: 2px;
    align-items: center;
    height: 100%;

    p {
      font-family: Gotham Medium;
      font-size: ${window.innerWidth > 900
        ? "13px"
        : window.innerWidth > 792
        ? "12px"
        : "10px"};
    }

    p:nth-of-type(odd) {
      color: ${({ color }) => color};
    }
  }
`;
