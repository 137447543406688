import React, { useRef } from "react";
import panel03 from "@/assets/design/panel03.png";
import styled from "@emotion/styled";
import useIntersectionObserver from "@/utils/useIntersectionObserver";

function PanelAnimation() {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;
  return (
    <Wrapper ref={ref}>
      {
        <img
          src={panel03}
          className={isVisible ? "animate" : ""}
          alt="Solar Panel"
        />
      }
    </Wrapper>
  );
}

export default PanelAnimation;

const Wrapper = styled.div`
  img {
    height: 200px;
    transform: rotate(-30deg);
    margin-left: 24px;

    -webkit-mask-image: linear-gradient(
      45deg,
      #000 25%,
      rgba(0, 0, 0, 0.2) 50%,
      #000 75%
    );
    mask-image: linear-gradient(
      45deg,
      #000 25%,
      rgba(0, 0, 0, 0.2) 50%,
      #000 75%
    );
    -webkit-mask-size: 800%;
    mask-size: 800%;
    -webkit-mask-position: 0;
    mask-position: 0;
  }

  .animate {
    transition-delay: 0.5s;
    transition: mask-position 3s ease, -webkit-mask-position 3s ease;
    -webkit-mask-position: 120%;
    mask-position: 120%;
    opacity: 1;
  }
`;
