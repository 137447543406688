import Context from "@/Context";

import { money, round } from "@/utils/helpers";
import { useContext } from "react";
import OverviewCard from "./OverviewCard";

function MonthlyOverview() {
  const { proposal } = useContext(Context);
  const {
    financing_monthly_payment,
    financing_term,
    financing_interest_rate,
    type,
  } = proposal || {};
  const isRoofing = type === "DecoLoan" || type === "DecoCash";
  return (
    <OverviewCard
      header="Monthly Overview"
      background="#E6F3FF"
      color="#336699"
    >
      <p>Monthly Solar Cost: </p>
      <p>{money(financing_monthly_payment)}</p>

      {/* {isRoofing ? (
        <>
          <p>Monthly Roofing Cost: </p>
          <p>{money(getMonthlyRoofLoanCost(proposal))}</p>
        </>
      ) : (
        <> */}
      <p>Monthly Storage Cost: </p>
      <p>{money(proposal?.battery_monthly_cost || 0)}</p>
      {/* </>
      )} */}

      <p>Solar + {isRoofing ? "Roofing" : "Storage"} Term: </p>
      <p>{financing_term || 0} years</p>
      <p>Interest Rate: </p>
      <p>{round(financing_interest_rate)}%</p>
      <div className="line" />
      <p>Total Monthly Cost: </p>
      <p>
        {money(
          (proposal?.financing_monthly_payment || 0) +
            (proposal?.battery_monthly_cost || 0)
        )}
      </p>
    </OverviewCard>
  );
}

export default MonthlyOverview;
