import styled from "@emotion/styled";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { useScrollNavigation } from "../ScrollNavigator";
import NavArrow from "./NavArrow";
import NavButton from "./NavButton";
import NavDashes from "./NavDashes";

function Navigation() {
  const { scrollRefs = [], scrollNext, scrollPrev } = useScrollNavigation();
  const [buttons, setButtons] = useState<ReactNode[]>([]);
  const [scrollOffset, setScrollOffset] = useState(0);

  useEffect(() => {
    setButtons(renderButtons());
  }, [scrollRefs]);

  const renderButtons = () => {
    return scrollRefs?.map((scrollRef: any, i: number) => {
      return (
        <div className="nav-scroll-wrapper" key={"nav-scroll-wrapper" + i}>
          {i > 0 && i < scrollRefs.length && <NavDashes />}
          <NavButton
            {...scrollRef}
            setScrollOffset={setScrollOffset}
            index={i}
          />
        </div>
      );
    });
  };

  return (
    <StyledNav>
      <NavArrow onClick={scrollPrev} />

      <div
        className="nav-buttons"
        style={{
          transform: `translate(${0 - scrollOffset}px)`,
        }}
      >
        {buttons}
      </div>
      <NavArrow onClick={scrollNext} direction="right" />
    </StyledNav>
  );
}

export default Navigation;

const StyledNav = styled.nav`
  background-color: white;
  z-index: 1000;
  width: 100%;
  max-width: 100vw;

  position: fixed;
  bottom: 0px;
  height: 70px;
  display: flex;
  flex-direction: row;

  align-items: center;

  .nav-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s;
  }

  .nav-scroll-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
