import { ReactComponent as Loader } from "@/assets/msLogo-animated.svg";

import styled from "@emotion/styled";
import { motion } from "framer-motion";

const Loading = () => {
  const animate = { opacity: 1 };
  const transition = {
    yoyo: Infinity,
    duration: 0.8,
  };
  return (
    <Wrapper>
      <Loader width="40%" height="30%" />
      <div className="dot-wrapper">
        <motion.div
          className="dot"
          animate={animate}
          transition={transition}
        ></motion.div>
        <motion.div
          className="dot"
          animate={animate}
          transition={{ ...transition, delay: 0.2 }}
        ></motion.div>
        <motion.div
          className="dot"
          animate={animate}
          transition={{ ...transition, delay: 0.4 }}
        ></motion.div>
      </div>
      <h2>momentum</h2>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  width: 100%;
  margin: auto;

  h2 {
    color: #336699;
    font-size: 40px;
    margin-top: 0px;
  }

  .dot-wrapper {
    display: flex;
    margin-top: 8px;
  }

  .dot {
    margin: 8px;

    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #336699;
    opacity: 0.2;
  }

  .dot:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  .dot:nth-of-type(3) {
    animation-delay: 0.4s;
  }
`;

export default Loading;
