import { default as CardBase } from "@/Components_DEP/Card";
import styled from "@emotion/styled";

import { ReactComponent as PanelIcon } from "@/assets/design/panel.svg";
import { ReactComponent as InverterIcon } from "@/assets/design/inverter.svg";
import { useContext } from "react";
import Context from "@/Context";

function DesignCard1() {
  const { proposal } = useContext(Context);

  return (
    <Card>
      <div className="item-wrapper">
        <PanelIcon />
        <div className="text-wrapper">
          <p>Panel Type & Quantity</p>
          <p>
            {proposal?.system_panel_name}
            <br /> Qty: {proposal?.system_panel_count}
          </p>
        </div>
      </div>

      <div className="line" />
      <div className="item-wrapper">
        <InverterIcon />
        <div className="text-wrapper">
          <p>Inverter Type & Quantity</p>
          <p>
            {/* {proposal?.inverter} <span>|</span> Qty: {proposal?.panels} */}
            {proposal?.system_inverter_name} <br /> Qty:{" "}
            {proposal?.system_inverter_count}
          </p>
        </div>
      </div>
    </Card>
  );
}

export default DesignCard1;

const Card = styled(CardBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  .line {
    min-height: 70%;
    width: 1px;
    background: #dbdbdb;
    margin: 0 1em;
  }

  .item-wrapper {
    display: flex;
    flex: 1;
    min-width: 45%;

    gap: 12px;
    align-items: center;

    p {
      font-family: Gotham Medium;
      color: #4b4b4b;
      flex: 1;
    }

    p:nth-of-type(2) {
      font-size: 14px;
      color: #c6c6c6;
      margin-top: 6px;
    }

    span {
      margin: 0 px 0.5em;
    }
  }
`;
