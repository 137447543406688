import Context from "@/Context";
import {
  getEstimatedLifetimeCost,
  getNetSolarAndStorageCost,
  getRebates,
  getSolarAndBatteryITC,
  getTotalCost,
  getTotalLoanAmount,
  getTotalSavings,
} from "@/utils/calculations";
import { money } from "@/utils/helpers";
import { useContext } from "react";
import OverviewCard from "./OverviewCard";

function TotalCostOverview() {
  const { proposal_dep } = useContext(Context);
  const { EPC, generacSystem, proposalType, roof_cost } = proposal_dep || {};
  const { batteryCost = 0 } = generacSystem || {};
  const isRoofing = proposalType === "DecoLoan" || proposalType === "DecoCash";
  return (
    <OverviewCard
      header="Total Cost Overview"
      background="#F0FCFF"
      color="#0CCEFF"
      style={{ gridRow: "span 2" }}
    >
      <p>Total Solar System Cost: </p>
      <p>{EPC && money(EPC)}</p>

      {isRoofing ? (
        <>
          <p>Total Roof Cost: </p>
          <p>{roof_cost && money(+roof_cost)}</p>
        </>
      ) : (
        <>
          <p>Total Battery System Cost: </p>
          <p>{money(batteryCost)}</p>
        </>
      )}
      <div className="line" />
      {isRoofing ? (
        <>
          <p>Total Solar + Roofing Cost: </p>
          <p>{money(getTotalCost(proposal_dep))}</p>
        </>
      ) : (
        <>
          <p>Total Solar + Battery System Cost: </p>
          <p>{money(getTotalCost(proposal_dep))}</p>
        </>
      )}

      <p>Applicable Rebate(s): </p>
      <p>({money(getRebates(proposal_dep))})</p>

      <div className="line" />
      {isRoofing ? (
        <>
          <p>Total Solar + Roofing Loan Amount: </p>
          <p>{money(getTotalLoanAmount(proposal_dep))}</p>
        </>
      ) : (
        <>
          <p>Total Solar + Battery Loan Amount: </p>
          <p>{money(getTotalLoanAmount(proposal_dep))}</p>
        </>
      )}

      <p>Less ITC:</p>
      <p>({money(getSolarAndBatteryITC(proposal_dep))})</p>

      {isRoofing ? (
        <>
          <p>Net Solar + Roofing: </p>
          <p>{money(getNetSolarAndStorageCost(proposal_dep))}</p>
        </>
      ) : (
        <>
          <p>Net Solar + Storage: </p>
          <p>{money(getNetSolarAndStorageCost(proposal_dep))}</p>
        </>
      )}

      <p>Less Solar Savings: </p>
      <p>({money(getTotalSavings(proposal_dep))})</p>

      <div className="line" />

      <p>Estimated Total Lifetime Cost*: </p>
      <p>{money(getEstimatedLifetimeCost(proposal_dep))}</p>
    </OverviewCard>
  );
}

export default TotalCostOverview;
