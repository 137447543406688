import Context from "@/Context";
import { getWithoutSolar, getWithSolar } from "@/utils/calculations";
import { money } from "@/utils/helpers";
import useIntersectionObserver from "@/utils/useIntersectionObserver";
import styled from "@emotion/styled";
import { Chart } from "chart.js/auto"; //Needed for <Line /> component from react-chartjs-2
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import GraphInfo from "./GraphInfo";
import Label from "./Label";

//CHART.JS
const DATA_COUNT = 10;
const pointRadius = [];
const labels = [];
const dataPoints = [];

//Builds arrays for pointRadius, labels, and dataPoints for graph
for (let i = 0; i < DATA_COUNT; ++i) {
  i === DATA_COUNT - 1 ? pointRadius.push(15) : pointRadius.push(null);
  labels.push("");
  dataPoints.push(i ** (i ** 0.2));
}

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
      title: {
        display: true,
        text: "Value",
      },
      suggestedMin: 1,
    },
  },
};
const chartData = {
  labels: labels,
  datasets: [
    {
      data: dataPoints,
      backgroundColor: "#d4d4d4",
      borderColor: "#d4d4d4",
      borderWidth: 1,
      borderDash: [7, 7],
      tension: 0,
      fill: false,
      cubicInterpolationMode: "monotone",
      pointRadius,
      animations: {
        borderColor: {
          type: "color",
          duration: 1200,
          easing: "linear",
          from: "white",
          to: "#d4d4d4",
          loop: false,
        },
        backgroundColor: {
          type: "color",
          duration: 1200,
          easing: "linear",
          from: "white",
          to: "#d4d4d4",
          loop: false,
        },
      },
    },
    {
      data: dataPoints.map((point) => point * 0.3),
      backgroundColor: "#0cceff",
      borderColor: "#0cceff",
      borderWidth: 1,
      borderDash: [7, 7],
      tension: 1,
      fill: false,
      cubicInterpolationMode: "monotone",
      pointRadius,
      animations: {
        borderColor: {
          type: "color",
          duration: 1200,
          easing: "linear",
          from: "white",
          to: "#0cceff",
          loop: false,
        },
        backgroundColor: {
          type: "color",
          duration: 1200,
          easing: "linear",
          from: "white",
          to: "#0cceff",
          loop: false,
        },
      },
    },
  ],
};

function Graph() {
  const { proposal_dep } = useContext(Context);
  const ref = useRef(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  return (
    <Wrapper>
      <GraphInfo />
      <div className="chart-wrapper" ref={ref}>
        {isVisible && (
          <>
            <Line options={chartOptions} data={chartData} className="chart" />
            <Label y="106%" value={money(getWithoutSolar(proposal_dep))} />
            <Label
              y="56%"
              color="#0CCEFF"
              value={money(getWithSolar(proposal_dep))}
            />
            <div className="labels">
              <p>Year 1</p>
              <p> Year 25</p>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
}

export default Graph;

const Wrapper = styled.div`
  grid-row: span 2;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  position: relative;
  padding: 8px;
  display: flex;

  .chart-wrapper {
    position: absolute;
    bottom: 32px;
    width: 90%;
    height: 70%;

    .labels {
      width: 105%;
      display: flex;
      justify-content: space-between;
      font-family: Gotham Medium;
      border-top: 0.5px solid #d4d4d4;
      padding-top: 6px;
    }
  }
`;
