import Context from "@/Context";
import {
  getFederalTaxCredit,
  getFederalTaxCreditRate,
  getLoanPayment,
  getMonthlyLoanPayment,
} from "@/utils/calculations";
import { money } from "@/utils/helpers";
import styled from "@emotion/styled";
import React, { useContext } from "react";

/*
 The payment factor on the proposals for sunlight assumes that the 
 customer will opt into the bridge payment in the firspt 16 months. If they decide no to,
 these are the new rates
 */
const ALT_LOAN_DETAILS = [
  { term: 15, interest: 3.99, paymentFactor: 0.0078145 },
  { term: 20, interest: 5.99, paymentFactor: 0.007523 },
  { term: 25, interest: 4.49, paymentFactor: 0.0057743 },
  { term: 25, interest: 6.99, paymentFactor: 0.0073913 },
];

function SunlightCopyCard() {
  const { proposal_dep } = useContext(Context);
  const { loanTerm, interest, loanAmount = 0 } = proposal_dep || {};

  const altLoan = ALT_LOAN_DETAILS.find(
    (d) => d.term === loanTerm && d.interest === d.interest
  );
  // console.log(altLoan);

  if (proposal_dep?.finnancialPartner !== "Sunlight") return null;

  const remainingPayments = proposal_dep && getLoanPayment(proposal_dep) - 16;
  return (
    <Wrapper>
      <h3>Sunlight Disclosure</h3>
      <p>
        No down payment required. The fixed Annual Percentage Rate for a{" "}
        {loanTerm} year loan is {interest}%. The first monthly payment on the
        loan is due approximately 90 days after installation. The amount of your
        first 16 payments will be $
        {proposal_dep && getMonthlyLoanPayment(proposal_dep)}. If you make a
        voluntary payment of{" "}
        {proposal_dep && money(getFederalTaxCredit(proposal_dep))}(
        {getFederalTaxCreditRate() * 100}% of your amount financed) by payment
        16, your remaining {remainingPayments} payments will be approximately $
        {proposal_dep && getMonthlyLoanPayment(proposal_dep)}. If no voluntary
        payment is made, the remaining {remainingPayments} payments will be{" "}
        {money(loanAmount * (altLoan?.paymentFactor || NaN))}. The numbers
        presented here are estimates only. The payment term stated in your loan
        documents will govern. Your loan agreement may assume you have not
        elected to pay by ACH.
      </p>
    </Wrapper>
  );
}

export default SunlightCopyCard;

const Wrapper = styled.div`
  /* border: 2px solid #336699; */
  padding: 20px;
  border-radius: 10px;
  margin-top: 16px;
  background-color: #ffffff66;

  h3 {
    color: #336699;
    /* color: #fff; */
    margin-bottom: 8px;
  }

  p {
    line-height: 1.4;
    color: grey;
  }
`;
