import styled from "@emotion/styled";

const GraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  height: calc(100% - 1em);
  max-height: 224px;
  align-self: center;

  gap: 16px;
`;

export default GraphWrapper;
