import { getUtilityRate } from "../calculations";

export const getAverageRIMonthlyPPAIncome = (proposal) => {
  if (proposal.tsrf >= 80) return 0;

  const incentiveTerm = getIncentiveTerm(proposal);
  //To get average monthly we divide by loan incentive and then by months in a year
  // console.log("Lifetime: ", getLifetimePBIIncome(proposal));
  return getLifetimePBIIncome(proposal) / 12 / incentiveTerm;
};

export const getLifetimePBIIncome = (proposal) => {
  if (proposal.tsrf >= 80 || proposal.State !== "RI") return 0;

  /*FOR TESTING EXACT VALUES; 
  TODO: do not leave in prod */
  // proposal.utilityRate = 0.22;
  // proposal.monthly_consumption = [
  //   511, 465, 439, 448, 430, 553, 759, 1017, 682, 463, 470, 596,
  // ];
  // proposal.monthly_production = [
  //   286, 347, 500, 568, 657, 668, 696, 603, 501, 365, 272, 214,
  // ];
  // proposal.consumption = 6833;
  // proposal.production = 5677;
  // proposal.size = 5.84;
  // EXPECTED = 3796;
  /*End section */

  /*FOR TESTING EXACT VALUES; 
  TODO: do not leave in prod */
  // proposal.utilityRate = 0.22;
  // proposal.monthly_consumption = [
  //   783, 713, 672, 687, 659, 848, 1164, 1559, 1045, 709, 721, 914,
  // ];
  // proposal.monthly_production = [
  //   725, 757, 966, 980, 962, 996, 1050, 1022, 918, 735, 638, 563,
  // ];
  // proposal.consumption = 8400;
  // proposal.production = 14599;
  // proposal.size = 8.4;
  // EXPECTED = 4506;
  /*End section */

  /*FOR TESTING EXACT VALUES; 
  TODO: do not leave in prod */
  // proposal.size = 13.51;
  // proposal.utilityRate = 0.22;
  // proposal.monthly_consumption = [
  //   1122, 1020, 963, 983, 944, 1215, 1666, 2233, 1496, 1016, 1033, 1309,
  // ];
  // proposal.monthly_production = [
  //   889, 1050, 1285, 1387, 1479, 1498, 1608, 1480, 1262, 1065, 919, 749,
  // ];
  // proposal.consumption = 15000;
  // proposal.production = 14599;
  // EXPECTED = 4443;

  /*End section */

  const ppaRate = proposal.size < 15000 ? 0.2615 : 0.2715;
  const incentiveTerm = getIncentiveTerm(proposal);
  let lifetimePBIIncome = 0;

  console.table({
    size: proposal.size,
    utilityRate: proposal.utilityRate,
    utilityEscalator: proposal.utilityEscalator,
    incentiveTerm,
    ppaRate,
  });

  // Iterate through the years that the incentive is valid
  for (let year = 1; year <= incentiveTerm; year++) {
    const netMeterRate = Math.max(ppaRate - getUtilityRate(proposal, year), 0);

    for (let month = 0; month < 12; month++) {
      const monthProduction =
        proposal?.monthly_production[month] * 0.995 ** (year - 1);
      const monthConsumption = proposal?.monthly_consumption[month];
      const monthSurplus = monthProduction - monthConsumption;

      if (monthSurplus > 0) {
        lifetimePBIIncome +=
          monthSurplus * ppaRate + monthConsumption * netMeterRate;
      } else {
        lifetimePBIIncome += monthProduction * netMeterRate;
      }
    }
  }

  return lifetimePBIIncome;
};

const getIncentiveTerm = (proposal) => {
  //Part of the terms of the inventive: if the size is above 15kw,
  //than the term of the incentive is 20 years. otherwise 15
  return proposal.size < 15000 ? 15 : 20;
};
