import FullPage from "@/Components_DEP/FullPage";
import styled from "@emotion/styled";
import React from "react";
import MSLogoPNG from "@/assets/msLogo-text.png";

import { ReactComponent as EFSLogo } from "@/assets/EFS/EFS-logo.svg";
import { ReactComponent as HeroImg } from "@/assets/EFS/hero-image.svg";
import Card from "@/Components_DEP/Card";

function ApplyingForEFS() {
  return (
    <Wrapper>
      <h2>EFS Application</h2>
      <div
        style={{
          alignSelf: "center",
          transform: "translateY(46px)",
          position: "relative",
        }}
      >
        <HeroImg />
        <EFSLogo className="efs-logo" />
      </div>
      <div style={{ width: "100%" }}>
        <h3>Important Information!</h3>
        <h3 style={{ color: "#336699" }}>Applying for EFS</h3>
      </div>
      <Card className="text-wrapper">
        <p>
          We are thrilled to partner with NYSERDA to offer a new and exciting
          financing option for customers in our New York markets!
        </p>
        <p>You can complete the loan application below:</p>
        <a
          href="https://slipstream2.my.site.com/NY/nPortal__SelfRegistration?product=a0u4W00000WbqzkQAB&core=es5%20"
          target="_blank"
          rel="noreferrer"
        >
          Apply Here
        </a>
      </Card>

      <Footer>
        <p>
          <span>
            Please note that it takes up to 48 hours for Momentum Solar to
            receive credit approval or denial.
          </span>
          At that point, our team will reach out to you for next steps.
        </p>
      </Footer>
    </Wrapper>
  );
}

export default ApplyingForEFS;

const Wrapper = styled(FullPage)`
  display: flex;
  flex-direction: column;
  padding-top: 34px;
  gap: 32px;

  .efs-logo {
    position: absolute;
    right: -44px;
    top: 10%;
    width: 150px;
    height: 76px;
  }

  .logo-row {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
  }

  a {
    color: white;
    font-family: "Gotham Bold";
    background-color: #336699;
    border: none;
    border-radius: 100px;
    font-size: 16px;
    padding: 12px 80px;
    margin: auto;
    text-decoration: none;
  }
`;

const Footer = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: transparent;
  border: 2px solid #336699;

  span {
    color: #336699;
    font-family: Lato-Bold;
    margin-right: 0.5ch;
  }
`;
