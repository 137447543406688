import styled from "@emotion/styled";

const GraphBar = styled.div`
  min-width: 84px;
  min-height: 30%;
  background: #d4d4d4;
  border-radius: 11px;
`;

export default GraphBar;
