import { cleanData, handleSRECIncome } from "../utilFuncs";
// const SMARTIncentiveFactor = (util) => {
//   return util.toLowerCase() === "eversource"
//     ? 0.07706
//     : util.toLowerCase() === "nationalgrid"
//     ? 0.0109
//     : 0;
// };

const RECIncentiveMA = 0.037;
const createLoanTable = (data) => {
  let {
    consumption,
    production,
    utilityRate,
    loanTerm,
    oldAnnualBill,
    State,
    EPC,
    utilityEscalator,
    loanPayment,
    wholesaleRate,
    utility,
  } = data;

  utilityEscalator = +utilityEscalator;
  consumption = +consumption;
  oldAnnualBill = +oldAnnualBill;
  production = +production;
  utilityRate = +utilityRate;
  loanTerm = +loanTerm;
  loanPayment = +loanPayment;
  wholesaleRate = +wholesaleRate;

  utilityEscalator = utilityEscalator / 100 + 1;
  const srecIncome = handleSRECIncome(production / 1000, State);

  const yearly = (currentProduction, futureUtilRate, futureWholesaleRate) => {
    if (Math.sign(consumption - currentProduction) === -1) {
      return (consumption - currentProduction) * futureWholesaleRate;
    } else {
      return (consumption - currentProduction) * futureUtilRate;
    }
  };

  let fullArr = [];

  const futureVal = (investment, rate, index, years) => {
    return fullArr[years - 2] ? fullArr[years - 2][index] * rate : investment;
  };

  const savings = (oldVal, newVal, increase, years) => {
    let old = futureVal(oldVal, increase, 1, years);
    let current = newVal;
    const currStateYears = State === "NJ" ? 16 : State === "MA" ? 31 : 11;
    let tax = 0.25 * EPC < 1000 ? 0.25 * EPC : 1000;
    let srecAddition;
    if (years < currStateYears && srecIncome) {
      srecAddition = srecIncome[years - 1];
    } else {
      srecAddition = 0;
    }
    if (State !== "MA") {
      if (years < +loanTerm + 1) {
        return old + srecAddition - current - loanPayment * 12;
      } else {
        return old + srecAddition - current;
      }
    } else {
      if (years === 1) {
        // console.log("check these vals for solar");
        // console.log(old);
        // console.log(current);
        // console.log(loanPayment);
        // console.log(loanPayment * 12);
        // console.log(+(+production * SMARTIncentiveFactor(utility)));
        // console.log(+tax);
        // console.log(EPC);
        // console.log(
        //   old -
        //     current -
        //     loanPayment * 12 +
        //     +tax +
        //     +(+production * SMARTIncentiveFactor(utility)) -
        //     EPC
        // );
        //NO EPC FOR LOAN
        // return (
        //   old -
        //   current -
        //   loanPayment * 12 +
        //   +tax +
        //   +(+production * SMARTIncentiveFactor(utility)) -
        //   EPC
        // );
        return (
          old -
          current -
          loanPayment * 12 +
          +tax +
          +(+production * RECIncentiveMA)
        );
      } else if (years > 2 && years < currStateYears) {
        return (
          old - current - loanPayment * 12 + +(+production * RECIncentiveMA)
        );
      } else {
        return old - current - loanPayment * 12;
      }
    }
  };

  const srecFunction = (years) => {
    const currStateYears = State === "NJ" ? 16 : State === "MA" ? 31 : 11;
    if (years < currStateYears && srecIncome) {
      return srecIncome[years - 1];
    } else {
      return "-";
    }
  };

  const total = (oldVal, newVal, increase, years) =>
    fullArr[years - 2]
      ? fullArr[years - 2][6] + savings(oldVal, newVal, increase, years)
      : savings(oldVal, newVal, increase, years);

  let futureUtilRate = utilityRate;
  let futureProductionRate = production;
  let futureWholesaleRate = wholesaleRate;
  for (let i = 1; i < 31; i++) {
    futureUtilRate = i > 1 ? futureUtilRate * utilityEscalator : utilityRate;
    futureProductionRate =
      i > 1
        ? futureProductionRate - futureProductionRate * 0.005
        : futureProductionRate;
    futureWholesaleRate =
      i > 1 ? futureWholesaleRate * utilityEscalator : futureWholesaleRate;
    fullArr.push([
      i,
      futureVal(oldAnnualBill, utilityEscalator, 1, i),
      yearly(futureProductionRate, futureUtilRate, futureWholesaleRate),
      i < +loanTerm + 1 ? loanPayment * 12 : null,
      srecFunction(i),
      savings(
        oldAnnualBill,
        yearly(futureProductionRate, futureUtilRate, futureWholesaleRate),
        utilityEscalator,
        i
      ),
      total(
        oldAnnualBill,
        yearly(futureProductionRate, futureUtilRate, futureWholesaleRate),
        utilityEscalator,
        i
      ),
    ]);
  }
  let oldPayments = 0;
  let newPayments = 0;
  let solar = 0;
  let yearlyFull = 0;

  fullArr.forEach((x) => {
    oldPayments += +x[1];
    newPayments += +x[2];
    solar += +x[3];
    yearlyFull += +x[5];
  });
  const firstSREC = srecIncome[0] / 12;
  const loanTableBar = { oldPayments, newPayments, yearlyFull, firstSREC };

  const lastArr =
    State === "MA"
      ? [
          "Total",
          cleanData(oldPayments),
          cleanData(newPayments),
          `(${cleanData(solar)})`,
          "-",
          "-",
          cleanData(yearlyFull),
          "-",
        ]
      : [
          "Total",
          cleanData(oldPayments),
          cleanData(newPayments),
          `(${cleanData(solar)})`,
          "-",
          cleanData(yearlyFull),
          "-",
        ];

  if (State.toLowerCase() === "ma") {
    //let me see fullArr before we manipulate
    // console.log("we want to see full arr before manip");
    // console.log(fullArr);
    let i = 0;

    let tax = 0.25 * EPC < 1000 ? 0.25 * EPC : 1000;
    while (i < 30) {
      if (i === 0) {
        // finalVal[0][i].splice(7, 0, finalVal[0][i][6]);
        fullArr[i].splice(6, 0, fullArr[i][5]);
        fullArr[i][5] = +tax;
      } else {
        // finalVal[0][i].splice(7, 0, finalVal[0][i][6]);

        fullArr[i].splice(6, 0, fullArr[i][5]);
        fullArr[i][5] = "";
      }
      if (i < 30) {
        fullArr[i][4] = +(+production * RECIncentiveMA);
      } else {
        fullArr[i][4] = "";
      }
      i++;
    }
  }
  // console.log("here are mappedVals");
  // console.log(mappedVals);
  let finalVal = mappedVals(fullArr, lastArr, loanTableBar);
  return finalVal;
  // return mappedVals(fullArr, lastArr, loanTableBar);
};

const mappedVals = (vals, lastArr, loanTableBar) => {
  let finalArray = vals.reduce((array, curr, index) => {
    let newArr = [];
    newArr.push(
      index + 1,
      cleanData(curr[1]),
      cleanData(curr[2]),
      curr[3] ? `(${cleanData(curr[3])})` : "-",
      curr[4] ? cleanData(curr[4]) : "",
      curr[5] !== "" ? cleanData(curr[5]) : "",
      cleanData(curr[6]),
      curr[7] && cleanData(curr[7])
    );
    array.push(newArr);
    return array;
  }, []);

  return [[...finalArray, lastArr], loanTableBar];
};

export default createLoanTable;
