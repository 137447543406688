import Card from "@/Components_DEP/Card";
import styled from "@emotion/styled";

type HomeValueCardProps = {
  label: string;
  value: string | number;
  text: string;
  source: {
    href: string;
    text: string;
  };
};
function HomeValueCard({ label, value, text, source }: HomeValueCardProps) {
  return (
    <Wrapper>
      <h1 className="value">{value}</h1>
      <h2 className="label">{label}</h2>
      <div className="line" />
      <p>{text}</p>
      <div className="footnote">
        <p>
          Source from:{" "}
          <a href={source.href} target="_blank" rel="noreferrer">
            {source.text}
          </a>
        </p>
      </div>
    </Wrapper>
  );
}

export default HomeValueCard;

const Wrapper = styled(Card)`
  flex: 1;
  text-align: center;
  padding: 24px;
  position: relative;
  z-index: 1000;

  .value {
    color: #0cceff;
    font-family: Gotham Bold;
    line-height: 0.75;
    font-size: clamp(3rem, 1.918vw + 2.58rem, 3.875rem);
  }

  .label {
    color: #d5d5d5;
    line-height: 1;
    text-transform: uppercase;
    font-size: 28px;
  }

  p {
    font-size: 14px;
    color: #707070;
    text-align: center;
  }

  .line {
    background: #0cceff;
    width: 62px;
    height: 4px;
    margin: 8px auto;
  }

  .footnote {
    position: absolute;
    align-self: flex-end;
    justify-self: flex-end;
    bottom: -16px;
    right: 6px;

    p {
      color: #b2b2b2;
      text-align: end;
      font-size: 12px;
    }

    a {
      color: #b2b2b2;
    }
  }
`;
