import { getNYAnnualCBCCost, isIGS } from "../../calculations";

const IGSNY = (data) => {
  let {
    consumption,
    production,
    utilityRate,
    utilityEscalator,
    solarRate,
    solarEscalator,
    wholesaleRate,
    proposalType,
    annualSolarBill,
  } = data;
  utilityEscalator = +utilityEscalator;
  consumption = +consumption;
  production = +production;
  utilityRate = +utilityRate;
  solarRate = +solarRate;
  solarEscalator = +solarEscalator;
  wholesaleRate = +wholesaleRate;

  utilityEscalator = utilityEscalator / 100 + 1;
  solarEscalator = solarEscalator / 100 + 1;

  let isLease = proposalType.toLowerCase() === "igslease";

  let oldAnnualBill = utilityRate * consumption;

  let fullArr = [];

  let savingsIGS = (
    futureSolarRate,
    futureUtilRate,
    futureAnnualUtilBill,
    futureProduction,
    futureWholesaleRate
  ) => {
    let newSolarBill = solarBill(futureSolarRate);

    let newUtilBill = newUtilityBill(
      futureUtilRate,
      futureProduction,
      futureWholesaleRate
    );

    return futureAnnualUtilBill - (newUtilBill + newSolarBill);
  };

  let solarBill = (futureSolarRate) => production * futureSolarRate;

  let preSolar = (futureUtilRate) => consumption * futureUtilRate;

  let newTaxCredit = (futureSolarRate) => solarBill(futureSolarRate) * 0.25;

  let newUtilityBill = (
    futureUtilRate,
    currProduction,
    futureWholesaleRate
  ) => {
    if (Math.sign(consumption - currProduction) === -1) {
      return (
        (consumption - currProduction) * futureWholesaleRate +
        getNYAnnualCBCCost(data)
      );
    } else {
      return (
        (consumption - currProduction) * futureUtilRate +
        getNYAnnualCBCCost(data)
      );
    }
  };

  let totals = (
    futureSolarRate,
    futureUtilRate,
    futureAnnualUtilBill,
    futureProduction,
    futureWholesaleRate
  ) =>
    savingsIGS(
      futureSolarRate,
      futureUtilRate,
      futureAnnualUtilBill,
      futureProduction,
      futureWholesaleRate
    ) + newUtilityBill(futureUtilRate, futureProduction, futureWholesaleRate);

  let futureUtilRate = utilityRate;
  let futureSolarRate = solarRate;
  let futureAnnualUtilBill = oldAnnualBill;
  let futureWholesaleRate = wholesaleRate;

  for (let i = 1; i < 26; i++) {
    futureUtilRate = i > 1 ? futureUtilRate * utilityEscalator : futureUtilRate;
    futureSolarRate =
      i > 1 ? futureSolarRate * solarEscalator : futureSolarRate;
    futureAnnualUtilBill =
      i > 1 ? futureAnnualUtilBill * utilityEscalator : futureAnnualUtilBill;
    futureWholesaleRate =
      i > 1 ? futureWholesaleRate * utilityEscalator : futureWholesaleRate;

    fullArr.push([
      savingsIGS(
        futureSolarRate,
        futureUtilRate,
        futureAnnualUtilBill,
        production,
        futureWholesaleRate
      ),
      newUtilityBill(futureUtilRate, production, futureWholesaleRate),
      preSolar(futureUtilRate),
      solarBill(futureSolarRate),
      i < 16 ? newTaxCredit(futureSolarRate) : 0,
      totals(
        futureSolarRate,
        futureUtilRate,
        futureAnnualUtilBill,
        production,
        futureWholesaleRate
      ),
    ]);
  }

  let savings = 0;
  let newPayments = 0;
  let oldPayments = 0;
  let allSolarBills = 0;
  let allTaxCredits = 0;

  fullArr.forEach((x) => {
    savings += +x[0];
    newPayments += +x[1];
    oldPayments += x[2];
    allSolarBills += +x[3];
    allTaxCredits += +x[4];
  });

  let num;

  if (allTaxCredits >= 5000) {
    num = 5000;
  } else {
    num = allTaxCredits;
  }

  let twentyFiveYearSavings = num + savings;
  let twentyFiveYearCost = allSolarBills - num + newPayments;
  let firstYearSavings = isLease
    ? fullArr[0][0] + fullArr[0][3]
    : fullArr[0][0] + fullArr[0][4];
  let firstPreSolarAnnualUtility = fullArr[0][2] / 12;
  let firstPostSolarAnnualUtilityBill = fullArr[0][1] / 12;
  let firstAnnualSolarBill = fullArr[0][3] / 12;
  let stateTaxCredit = allTaxCredits > 5000 ? 5000 : allTaxCredits;

  const igsVals = {
    savings,
    newPayments,
    oldPayments,
    allSolarBills,
    allTaxCredits,
    firstYearSavings,
    twentyFiveYearSavings,
    twentyFiveYearCost,
    firstPreSolarAnnualUtility,
    firstPostSolarAnnualUtilityBill,
    firstAnnualSolarBill,
    stateTaxCredit,
  };

  return igsVals;
};

export default IGSNY;
