import { useEffect, useRef } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import MainStack from "./Pages/MainStack";
import { ContextProvider } from "./Context";
import MapPage from "./Pages/Map";

import { default as appData } from "../package.json";
import NoGuid from "./Pages/NoGuid";

function App() {
  const queryClient = new QueryClient();

  useEffect(() => {
    console.log("Proposal v" + appData.version);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<NoGuid />}></Route>
            <Route path="/:guid/" element={<MainStack />}></Route>
            <Route path="/:guid/map" element={<MapPage />} />
          </Routes>
        </BrowserRouter>
      </ContextProvider>
    </QueryClientProvider>
  );
}

export default App;
