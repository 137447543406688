import styled from "@emotion/styled";
import { ReactComponent as WorkerSVG } from "@/assets/homeValue/home-install-worker.svg";

const Worker = styled(WorkerSVG)`
  position: absolute;
  aspect-ratio: 1;
  right: 26.5%;
  bottom: 14%;
  height: 20%;

  /* transform: translate(5px, 5px); */
  opacity: 0;
  -webkit-animation: fade-in 0.8s ease-out forwards;
  -moz-animation: fade-in 0.8s ease-out forwards;
  animation: fade-in 0.8s ease-out forwards;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;

  @-moz-keyframes fade-in {
    100% {
      -webkit-transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-in {
    100% {
      -webkit-transform: translate(0, 0);
      opacity: 1;
    }
  }
  @keyframes fade-in {
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
`;
export default Worker;
