import CarouselContext from "@/Components_DEP/Carousel";
import styled from "@emotion/styled";
import TotalSystemCost from "./TotalSystemCost";
import LifetimeSavings from "./LifetimesSavings";
import MonthlyPayments from "./MonthlyPayments";
import SavingsByTheNumbers from "./SavingsByTheNumbers";
import SavingsGraph from "./SavingsGraph";
import TotalSavings from "./TotalSavings";
import StorageAndCostOverview from "./StorageAndCostOverview";
import { useContext, useEffect, useRef, useState } from "react";
import Context from "@/Context";
import AddonOldVsNewLoanGraph from "./AddonOldVsNewLoanGraph";

function SavingsCard1() {
  const { proposal_dep } = useContext(Context);
  const renderCards = () => {
    switch (proposal_dep?.proposalType) {
      case "MSES":
      case "IGSPPA":
      case "IGSLEASE":
        return [
          <SavingsGraph key="card-1" />,
          <SavingsByTheNumbers key="card-2" />,
        ];

      case "SolarStorage":
      case "GeneracLoan":
      case "JEALoan":
      case "Loan":
        return proposal_dep?.generacSystem
          ? [
              <MonthlyPayments key="card-1" />,
              <LifetimeSavings key="card-2" />,
              <StorageAndCostOverview key="card-3" />,
            ]
          : [
              <MonthlyPayments key="card-1" />,
              <LifetimeSavings key="card-2" />,
            ];
      case "Cash":
      default:
        return [
          <TotalSystemCost key="card-1" />,
          <TotalSavings key="card-2" />,
        ];
    }
  };

  return (
    <Wrapper>
      <CarouselContext>{renderCards()}</CarouselContext>
    </Wrapper>
  );
}

export default SavingsCard1;

const Wrapper = styled.div`
  z-index: 500;
  height: 356px;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: visible;

  margin: 0px -32px; // need for correct x/y overflow
  padding: 0px 32px; // need for correct x/y overflow
  padding-bottom: 16px; // needed to show dots correctly
`;
