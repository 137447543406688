import Header from "../Components_DEP/Header";
import Navigation from "../Components_DEP/Navigation";
import Home from "./Home";
import Design from "./Design";
import SavingsSummary from "./SavingsSummary";
import HomeValueBenefits from "./HomeValueBenefits";

import { ReactComponent as designIcon } from "../assets/navIcons/design.svg";
import { ReactComponent as homeIcon } from "../assets/navIcons/home.svg";
import { ReactComponent as homeBenefitsIcon } from "../assets/navIcons/homeBenefits.svg";
// import { ReactComponent as lifetimeSavingsIcon } from "../assets/navIcons/lifetimeSavings.svg";
import { ReactComponent as referralIcon } from "../assets/navIcons/referral.svg";
import { ReactComponent as savingsSummaryIcon } from "../assets/navIcons/savingsSummary.svg";
import { ReactComponent as thankYouIcon } from "../assets/navIcons/thankYou.svg";
import { ReactComponent as EFSIcon } from "../assets/navIcons/efs.svg";
import Referrals from "./Referrals";
import ThankYou from "./ThankYou";
import {
  ScrollNavigatorContext,
  ScrollSection,
} from "@/Components_DEP/ScrollNavigator";
import { useContext, useEffect, useState } from "react";
import Context from "@/Context";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getPositionFromSearchParams } from "./Map/mapHelpers";
import Loading from "./Loading";
import NoGuid from "./NoGuid";
import ApplyingForEFS from "./ApplyingForEFS";

function MainStack() {
  const { proposal_dep, setGuid, setUserLocation } = useContext(Context);
  const { guid } = useParams();
  const [searchParams] = useSearchParams();
  const [doneLoading, setDoneLoading] = useState(!!proposal_dep);

  useEffect(() => {
    guid && setGuid(guid);
  }, [guid]);

  useEffect(() => {
    const pos = getPositionFromSearchParams(searchParams);
    if (pos?.lat && pos?.lng) setUserLocation(pos);
  }, [searchParams]);

  useEffect(() => {
    setTimeout(() => {
      setDoneLoading(true);
    }, 1000);
  }, []);
  const isEFS =
    proposal_dep?.finnancialPartner === "EFS" ||
    proposal_dep?.finnancialPartner === "EFSBridge";

  return proposal_dep && doneLoading ? (
    <>
      <Header />
      <ScrollNavigatorContext
        navigationElement={<Navigation />}
        headerElement={<Header />}
      >
        <ScrollSection label="Home" Icon={homeIcon}>
          <Home />
        </ScrollSection>

        <ScrollSection label="Design" Icon={designIcon}>
          <Design />
        </ScrollSection>

        {/* <ScrollSection
    
          label="Lifetime Savings"
          Icon={lifetimeSavingsIcon}
          >
          <FullPage style={{ background: "#336699" }} />
        </ScrollSection> */}

        <ScrollSection label="Savings Summarized" Icon={savingsSummaryIcon}>
          <SavingsSummary />
        </ScrollSection>
        <ScrollSection label="Home Value Benefits" Icon={homeBenefitsIcon}>
          <HomeValueBenefits />
        </ScrollSection>
        <ScrollSection label="Referral" Icon={referralIcon}>
          <Referrals />
        </ScrollSection>
        {isEFS && (
          <ScrollSection label="EFS" Icon={EFSIcon}>
            <ApplyingForEFS />
          </ScrollSection>
        )}
        <ScrollSection label="Thank You" Icon={thankYouIcon}>
          <ThankYou />
        </ScrollSection>
      </ScrollNavigatorContext>
    </>
  ) : !proposal_dep && doneLoading ? (
    <NoGuid />
  ) : (
    <Loading />
  );
}

export default MainStack;
