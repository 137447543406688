import FullPage from "@/Components_DEP/FullPage";
import styled from "@emotion/styled";
import SavingsCard1 from "./SavingsCard1";
import SavingsCard2 from "./SavingsCard2";
import SavingsCard3 from "./SavingsCard3";
import SunlightCopyCard from "@/Components/SunlightCopyCard";
import { OptionalLicenseNumbers } from "@/Components/LicenseNumbers";

function SavingsSummary() {
  return (
    <Wrapper>
      <div className="content">
        <h2>Savings Summarized</h2>
        <SavingsCard1 />
        <SavingsCard2 />
        <SavingsCard3 />
        <SunlightCopyCard />
        <OptionalLicenseNumbers
          orientation="horizontal"
          style={{ margin: "auto", marginTop: "8px" }}
          color="#fff"
        />
      </div>
      <div className="bg-wrapper" />
    </Wrapper>
  );
}

export default SavingsSummary;

const Wrapper = styled(FullPage)`
  position: relative;

  .content {
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: clamp(32px, calc(2rem + ((1vw - 3.44px) * 8.0163)), 70px) 0px;

    h2 {
      color: #336699;

      margin-bottom: 32px;
    }
  }

  .bg-wrapper {
    position: absolute;
    background: linear-gradient(#0cceff00, #0cceff, #0cceff00);
    margin: 0 -32px;
    min-height: 120%;
    width: 100%;
    top: -10%;
    z-index: 1;
  }
`;
