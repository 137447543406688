import { ReactComponent as Cloud } from "@/assets/savingsSummary/world-cloud.svg";
import delay from "@/utils/animationDelay";
import styled from "@emotion/styled";

function Clouds() {
  return (
    <Wrapper>
      <Cloud style={delay(0)} />
      <Cloud style={delay(15)} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  svg {
    position: absolute;
    opacity: 0;
    transform: translate(0%);
    -webkit-animation: move 30s linear infinite;
    -moz-animation: move 30s linear infinite;
    animation: move 30s linear infinite;
    z-index: 600;
  }

  @-moz-keyframes move {
    0% {
      -webkit-transform: translateX(10%);
      opacity: 0;
    }
    10% {
      -webkit-transform: translateX(18%);
      opacity: 1;
    }
    90% {
      -webkit-transform: translateX(82%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(90%);
      transform: translateX(90%);
      opacity: 0;
    }
  }
  @-webkit-keyframes move {
    0% {
      -webkit-transform: translateX(10%);
      opacity: 0;
    }
    10% {
      -webkit-transform: translateX(18%);
      opacity: 1;
    }
    90% {
      -webkit-transform: translateX(82%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(90%);
      transform: translateX(90%);
      opacity: 0;
    }
  }
  @keyframes move {
    0% {
      -webkit-transform: translateX(10%);
      transform: translateX(10%);
      opacity: 0;
    }
    10% {
      -webkit-transform: translateX(18%);
      transform: translateX(18%);
      opacity: 1;
    }
    90% {
      -webkit-transform: translateX(82%);
      transform: translateX(82%);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(90%);
      transform: translateX(90%);
      opacity: 0;
    }
  }
`;

export default Clouds;
