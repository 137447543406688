import { getFederalTaxCreditRate } from "../../calculations";
import { numberWithCommas, cleanData, handleSRECIncome } from "../utilFuncs";

// ! if consumption - production is negative multiply by wholesale not utility; (Everything);
// ! for cash flow on nj cash
// ! epc is system cost
// ! production up to 100% of consumption * utility rate

// const SMARTIncentiveFactor = (util) => {
//   return util.toLowerCase() === "eversource"
//     ? 0.07706
//     : util.toLowerCase() === "nationalgrid"
//     ? 0.0109
//     : 0;
// };
const RECIncentiveMA = 0.037;
const createCashTable = (data) => {
  let {
    EPC,
    consumption,
    production,
    utilityRate,
    State,
    utilityEscalator,
    wholesaleRate,
  } = data;

  EPC = +EPC;
  consumption = +consumption;
  production = +production;
  utilityRate = +utilityRate;
  // srecIncome = JSON.parse(srecIncome).map(x => +x);
  utilityEscalator = +utilityEscalator;
  wholesaleRate = +wholesaleRate;

  // keep in mind that this utilityEscaltor reassignment is with the format wholenumber/decimal being passed (i.e. 3.9%);
  utilityEscalator = utilityEscalator / 100 + 1;

  let federalTaxCredit = getFederalTaxCreditRate() * EPC;

  //full array to fill and return at the end;
  let fullArr = [];

  const srecIncome = handleSRECIncome(production / 1000, State);

  const electricBillSavings = (
    currProduction,
    currUtilityRate,
    futureWholesaleRate
  ) => {
    if (Math.sign(currProduction - consumption) === -1) {
      return currProduction * currUtilityRate;
    } else {
      return (
        currProduction * currUtilityRate +
        (currProduction - consumption) * futureWholesaleRate
      );
    }
  };

  const electricBillAfterSolar = (
    currProduction,
    currUtilityRate,
    futureWholesaleRate
  ) => {
    if (Math.sign(consumption - currProduction) === -1) {
      return (consumption - currProduction) * futureWholesaleRate;
    } else {
      return (consumption - currProduction) * currUtilityRate;
    }
  };

  const srecFunction = (years) => {
    const currStateYears = State === "NJ" ? 16 : State === "MA" ? 31 : 11;
    if (years < currStateYears && srecIncome) {
      return srecIncome[years - 1];
    } else {
      return "";
    }
  };

  const savings = (
    currProduction,
    currUtilityRate,
    years,
    futureWholesaleRate
  ) => {
    let currSavings = electricBillSavings(
      currProduction,
      currUtilityRate,
      futureWholesaleRate
    );
    let tax = 0.25 * EPC < 1000 ? 0.25 * EPC : 1000;
    let srecAddition;
    const currStateYears = State === "NJ" || State === "MA" ? 16 : 11;

    if (years < currStateYears && srecIncome) {
      srecAddition = srecIncome[years - 1];
    } else {
      srecAddition = 0;
    }
    if (State !== "MA") {
      if (years === 1) {
        return currSavings + srecAddition - EPC + federalTaxCredit;
      } else {
        return currSavings + srecAddition;
      }
    } else {
      if (years === 1) {
        return (
          currSavings +
          +tax +
          +(+production * RECIncentiveMA) -
          EPC +
          federalTaxCredit
        );
      } else if (years > 2 && years < 11) {
        return currSavings + +(+production * RECIncentiveMA);
      } else {
        return currSavings;
      }
    }
  };

  const total = (currProduction, currUtilityRate, years, futureWholesaleRate) =>
    fullArr[years - 2]
      ? fullArr[years - 2][8] +
        savings(currProduction, currUtilityRate, years, futureWholesaleRate)
      : savings(currProduction, currUtilityRate, years, futureWholesaleRate);

  let futureUtilRate = utilityRate;
  let futureProductionRate = production;
  let futureWholesaleRate = wholesaleRate;
  for (let i = 1; i < 31; i++) {
    futureUtilRate = i > 1 ? futureUtilRate * utilityEscalator : utilityRate;
    futureProductionRate =
      i > 1
        ? futureProductionRate - futureProductionRate * 0.005
        : futureProductionRate;
    futureWholesaleRate =
      i > 1 ? futureWholesaleRate * utilityEscalator : futureWholesaleRate;
    fullArr.push([
      i,
      i === 1 ? EPC : "",
      electricBillSavings(
        futureProductionRate,
        futureUtilRate,
        futureWholesaleRate
      ),
      electricBillAfterSolar(
        futureProductionRate,
        futureUtilRate,
        futureWholesaleRate
      ),
      futureProductionRate,
      i === 1 ? federalTaxCredit : "",
      srecFunction(i),
      savings(futureProductionRate, futureUtilRate, i, futureWholesaleRate),
      total(futureProductionRate, futureUtilRate, i, futureWholesaleRate),
    ]);
  }

  let electricBillTotal = 0;
  let electricBillAfterTotal = 0;
  let pvGenTotal = 0;
  let cashFlowTotal = 0;

  fullArr.forEach((x) => {
    electricBillTotal += +x[2];
    electricBillAfterTotal += +x[3];
    pvGenTotal += +x[4];
    cashFlowTotal += x[7];
  });

  const cashTableVar = {
    totalElectricBillSavings: electricBillTotal,
    totalElectricAfterSolar: electricBillAfterTotal,
    totalAnnualCashFlow: cashFlowTotal,
  };

  const lastArr =
    State === "MA"
      ? [
          "Total",
          "",
          cleanData(electricBillTotal),
          cleanData(electricBillAfterTotal),
          numberWithCommas(Math.round(pvGenTotal)),
          "",
          "",
          "",
          cleanData(cashFlowTotal),
          "",
        ]
      : [
          "Total",
          "",
          cleanData(electricBillTotal),
          cleanData(electricBillAfterTotal),
          numberWithCommas(Math.round(pvGenTotal)),
          "",
          "",
          cleanData(cashFlowTotal),
          "",
        ];

  const irrValues = fullArr.reduce((arr, curr, index) => {
    if (index !== fullArr.length) arr.push(curr[7]);
    return arr;
  }, []);

  if (State.toLowerCase() === "ma") {
    let i = 0;

    let tax = 0.25 * EPC < 1000 ? 0.25 * EPC : 1000;
    while (i < 30) {
      if (i === 0) {
        fullArr[i].splice(8, 0, fullArr[i][7]);
        fullArr[i][7] = +tax;
      } else {
        fullArr[i].splice(8, 0, fullArr[i][7]);
        fullArr[i][7] = "";
      }
      //REC
      if (i < 30) {
        fullArr[i][6] = +(+production * RECIncentiveMA);
      } else {
        fullArr[i][6] = "";
      }
      //redo addition
      i++;
    }
  }
  // console.log("here is ended full arr");
  // console.log(fullArr);
  // console.log(lastArr);
  // console.log(mappedCashVals(fullArr, lastArr, irrValues, cashTableVar));
  let finalVal = mappedCashVals(fullArr, lastArr, irrValues, cashTableVar);

  return finalVal;
  // return mappedCashVals(fullArr, lastArr, irrValues, cashTableVar);
};

const mappedCashVals = (vals, lastArr, irrValues, cashTableVar) => {
  // console.log("here is last array? What is it?");
  // console.log(lastArr);

  let finalArray = vals.reduce((array, curr, index) => {
    // console.log(`here is curr ${index}`);
    // console.log(curr);
    let newArr = [];

    newArr.push(
      index + 1,
      curr[1] ? `(${cleanData(curr[1])})` : "",
      cleanData(curr[2]),
      cleanData(curr[3]),
      numberWithCommas(Math.round(curr[4])) || "",
      curr[5] ? cleanData(curr[5]) : "",
      curr[6] ? cleanData(curr[6]) : "",
      curr[7] !== "" ? cleanData(curr[7]) : "",
      cleanData(curr[8]),
      curr[9] && cleanData(curr[9])
      // curr[8]
    );
    array.push(newArr);
    return array;
  }, []);

  // console.log(finalArray[1]);
  // console.log(finalArray);
  // console.log(lastArr);
  // console.log(irrValues);

  return [[...finalArray, lastArr], irrValues, cashTableVar];
};

export default createCashTable;
