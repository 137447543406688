import { handleSRECIncome, NYCZipcodes } from "./stateCalculations/utilFuncs";
import tables from "./stateCalculations";
import { irr } from "node-irr";
import { newTotalSavings } from "./helpers";
import { getAverageRIMonthlyPPAIncome } from "./newStateCalculations/RI";

export const getTotalSolarSystemCost = (proposal) => {
  const {
    proposalType,
    EPC,
    generacSystem = {},
    roof_cost = 0,
  } = proposal || {};
  const { batteryCost = 0 } = generacSystem || {};
  return proposalType === "JEALoan"
    ? (batteryCost || 0) + EPC
    : proposalType === "DecoLoan"
    ? EPC + +roof_cost
    : EPC;
};

export const getSolarLoan = (proposal) => {
  const { proposalType, loanAmount, generacSystem } = proposal || {};
  return proposalType === "JEALoan"
    ? generacSystem.solarPlusBatteryCost
    : loanAmount;
};

export const getLoanPayment = (proposal) => {
  return (proposal?.loanTerm || proposal?.financing_term) * 12 - 1;
};

export const getMonthlyPaymentWithSolar = (proposal) => {
  const UTILITY_NUMBER = getUtilityOffset(proposal);
  return getMonthlyLoanPayment(proposal) + UTILITY_NUMBER;
};

export const getMonthlyLoanPayment = (proposal) => {
  const {
    proposalType,
    generacSystem,
    loanPayment,
    paymentFactor,
    loanAmount,
  } = proposal;

  //This value should be calculated on proposal dash, but for some reason, some were coming in blank
  const loanPaymentAmount = loanPayment ?? paymentFactor * loanAmount;

  return proposalType === "DecoLoan"
    ? loanPaymentAmount + getMonthlyRoofLoanCost(proposal)
    : proposalType === "JEALoan"
    ? generacSystem.solarPlusBatteryMonthlyPayment
    : loanPaymentAmount;
};

export const getUtilityOffset = (proposal) => {
  const { production, consumption, oldMonthlyBill } = proposal;
  return (
    oldMonthlyBill -
    oldMonthlyBill * (production / consumption) +
    getNYAnnualCBCCost(proposal) / 12
  );
};

export const getEstimatedITC = (proposal = {}) => {
  const {
    proposalType,
    solarLoanITC,
    generacSystem,
    finnancialPartner,
    rebate = 0,
  } = proposal || {};

  return proposalType === "JEALoan"
    ? solarLoanITC +
        (generacSystem.batteryCost || 0) * getFederalTaxCreditRate()
    : solarLoanITC;
};

export const getStorageLoan = (proposal) => {
  const { batteryCost = 0 } = proposal?.generacSystem || {};
  if (proposal?.generacSystem) {
    return batteryCost;
  } else {
    return 0;
  }

  // If you want the include rebate in calculation:
  // return (generacSystem.batteryCost || 0) - (generacSystem.batteryRebate || 0);
};

export const getMonthlyStorageCost = (proposal) => {
  const { generacSystem, paymentFactor } = proposal || {};
  return getStorageLoan({ generacSystem }) * paymentFactor;
};

export const getMonthlyRoofLoanCost = (proposal) => {
  const { roof_loan = 0, paymentFactor = 0 } = proposal || {};
  return roof_loan * paymentFactor;
};

export const getTotalMonthlyCost = (proposal) => {
  const { loanPayment = 0 } = proposal;
  return (
    getMonthlyStorageCost(proposal) + loanPayment
    // + (getNYAnnualCBCCost(proposal) || 0 / 12) //+ roofing cost
  );
};

export const getTotalCost = (proposal) => {
  const { generacSystem, EPC = 0, roof_cost = 0 } = proposal || {};
  const { batteryCost = 0 } = generacSystem || {};
  return (batteryCost || 0) + roof_cost + EPC;
};

export const getRebates = (proposal) => {
  const { rebate = 0, generacSystem } = proposal || {};
  const { batteryRebate = 0, enphaseRebate = 0 } = generacSystem || {};
  return rebate + batteryRebate + enphaseRebate;
};

export const getTotalLoanAmount = (proposal) => {
  const { generacSystem, loanAmount, roof_loan = 0 } = proposal || {};
  const { batteryCost = 0 } = generacSystem || {};
  return loanAmount + roof_loan + batteryCost;
};

export const getSolarAndBatteryITC = (proposal) => {
  const {
    loanAmount,
    generacSystem,
    rebate = 0,
    roof_loan = 0,
    finnancialPartner,
  } = proposal || {};
  const { batteryCost = 0, batteryRebate = 0 } = generacSystem || {};

  return (
    (loanAmount + roof_loan + batteryCost - batteryRebate - rebate) *
    getFederalTaxCreditRate()
  );
};

export const getNetSolarAndStorageCost = (proposal) => {
  const { EPC, rebate = 0, generacSystem, roof_cost = 0 } = proposal;
  const { batteryCost = 0, batteryRebate = 0 } = generacSystem || {};
  const ITC = getSolarAndBatteryITC(proposal);
  const adjEPC = EPC - rebate;
  const adjBatteryEpc = batteryCost - batteryRebate;

  return adjEPC + roof_cost + adjBatteryEpc - ITC;
};

export const getFederalTaxCredit = (proposal) => {
  const { EPC, rebate = 0, finnancialPartner } = proposal;
  return (EPC - rebate) * getFederalTaxCreditRate();
};

export const getIrrValues = (proposal) => {
  let irrValues, irrValue, igsVals;

  if (proposalSwitch(proposal.proposalType) === "Cash") {
    [igsVals, irrValues] =
      tables[proposalSwitch(proposal.proposalType)][proposal.State](proposal);
    irrValue = irr(irrValues) * 100;
  }
  if (isIGS(proposal) || proposal.proposalType === "MSES") {
    igsVals =
      tables[proposalSwitch(proposal.proposalType)][proposal.State](proposal);
  }

  return { irrValues, irrValue, igsVals };
};

export const getNetSystemValue = (proposal) => {
  const { EPC = 0, rebate = 0, roof_cost = 0 } = proposal || {};
  let base = EPC + roof_cost;

  return (
    base -
    rebate -
    getStateTaxCredit(proposal) -
    getFederalTaxCredit(proposal) -
    getPropertyTaxAbatement(proposal)
  );
};

export const getTotalSavings = (proposal) => {
  const savingsData = newTotalSavings(proposal);
  return savingsData.thirdVal;
};

export const getEstimatedLifetimeCost = (proposal) => {
  return (
    getTotalLoanAmount(proposal) -
    getSolarAndBatteryITC(proposal) -
    getTotalSavings(proposal)
  );
};

export const getFirstYearSavings = (proposal) => {
  const { proposalType, State, Zip } = proposal;
  const result = tables[proposalSwitch(proposalType)][State](proposal);
  return (
    result.firstYearSavings +
    (State === "NY" ? getPropertyTaxAbatement(proposal) / 4 : 0)
  ); //NYC Property Tax Abatement is applied over 4 years
};

export const get25YearSavings = (proposal) => {
  const { proposalType, State } = proposal;
  const result = tables[proposalSwitch(proposalType)][State](proposal);
  return State === "NY"
    ? result.twentyFiveYearSavings + getPropertyTaxAbatement(proposal)
    : result.savings;
};

export const getWithoutSolar = (proposal) => {
  const { proposalType, State } = proposal;
  const result = tables[proposalSwitch(proposalType)][State](proposal);
  return result.oldPayments;
};

export const getWithSolar = (proposal) => {
  const { proposalType, State } = proposal;
  const result = tables[proposalSwitch(proposalType)][State](proposal);
  return result.twentyFiveYearCost;
};

export const getLocalUtilityBill = (proposal) => {
  const { proposalType, State } = proposal;
  const result = tables[proposalSwitch(proposalType)][State](proposal);
  return State === "FL"
    ? result.firstPreSolarAnnualUtility * 12
    : result.firstPreSolarAnnualUtility;
};

export const getMSUtilityBill = (proposal) => {
  const { proposalType, State } = proposal;
  const result = tables[proposalSwitch(proposalType)][State](proposal);
  return State === "FL"
    ? result.firstPostSolarAnnualUtilityBill * 12
    : result.firstPostSolarAnnualUtilityBill;
};

export const getMSSolarBill = (proposal) => {
  const { proposalType, State } = proposal;
  const result = tables[proposalSwitch(proposalType)][State](proposal);
  return State === "FL"
    ? result.firstAnnualSolarBill * 12
    : result.firstAnnualSolarBill;
};

export const getLocalTotalCost = (proposal) => {
  const { proposalType, State } = proposal;
  const result = tables[proposalSwitch(proposalType)][State](proposal);
  return State === "FL"
    ? result.firstPreSolarAnnualUtility * 12
    : result.firstPreSolarAnnualUtility;
};

export const getMSTotalCost = (proposal) => {
  const { proposalType, State } = proposal;
  const allData = tables[proposalSwitch(proposalType)][State](proposal);
  const result =
    allData.firstPostSolarAnnualUtilityBill + allData.firstAnnualSolarBill;
  return State === "FL" ? result * 12 : result;
};

export const proposalSwitch = (type) => {
  switch (type) {
    case "GeneracCash":
    case "DecoCash":
      return "Cash";
    case "GeneracLoan":
    case "JEALoan":
    case "DecoLoan":
      return "Loan";
    case "MSES":
    case "IGSLEASE":
      return "IGSPPA";
    default:
      return type;
  }
};

export const getSavingsBreakdown = (proposal) => {
  const { proposalType, State, loanPayment, generacSystem } = proposal;
  const allData = tables[proposalSwitch(proposalType)][State]({
    ...proposal,
    loanPayment:
      proposalType === "JEALoan"
        ? generacSystem.solarPlusBatteryMonthlyPayment
        : loanPayment,
  });

  const EFSDiff =
    proposal.efs_balloon_payment_amount - proposal.efs_bridge_payment_amount;

  if (proposalSwitch(proposalType) === "Cash") {
    let {
      totalElectricBillSavings,
      totalElectricAfterSolar,
      totalAnnualCashFlow,
    } = allData[2];
    let total = totalElectricBillSavings + totalElectricAfterSolar;

    return {
      total: total,
      secondVal: total - totalAnnualCashFlow,
      thirdVal: totalAnnualCashFlow,
      percent: ((totalAnnualCashFlow / total) * 100).toFixed(1),
    };
  } else {
    const { oldPayments = [], yearlyFull } = allData[1];
    return {
      total: oldPayments,
      secondVal: oldPayments - yearlyFull,
      thirdVal: yearlyFull,
      percent: ((yearlyFull / oldPayments) * 100).toFixed(1),
    };
  }
};

export const getStateTaxCredit = (proposal) => {
  const { proposalType = "", State = "", EPC = 0, rebate = 0 } = proposal || {};
  if (!proposalType || !State) return 0;

  switch (State) {
    case "AZ":
    case "MA":
      return 0.25 * EPC < 1000 ? 0.25 * EPC : 1000;
    case "NY":
      return (EPC - rebate) * 0.25 >= 5000 ? 5000 : (EPC - rebate) * 0.25;
    case "FL":
    default:
      const allData = tables[proposalSwitch(proposalType)][State](proposal);
      return allData.stateTaxCredit || 0;
  }
};

export const getMonthlySolarBill = (proposal) => {
  const { proposalType, State } = proposal;
  const allData = tables[proposalSwitch(proposalType)][State](proposal);
  return State === "FL"
    ? allData.firstAnnualSolarBill
    : allData.firstAnnualSolarBill / 12;
};

export const getNYAnnualCBCCost = (proposal) => {
  const { utility, size } = proposal || {};
  let CBCRate;
  switch (utility) {
    case "ConEdison":
      CBCRate = 1.09;
      break;
    case "CentralHudson":
      CBCRate = 1.33;
      break;
    case "NationalGrid":
      CBCRate = 1.15;
      break;
    case "NYSEG":
      CBCRate = 0.92;
      break;
    case "Orange&Rockland":
      CBCRate = 1.34;
      break;
    case "RG&E":
      CBCRate = 1.02;
      break;
    default:
      CBCRate = 0;
      break;
  }
  return CBCRate * size * 12;
};

export const getMonthlyIncentiveIncome = (proposal) => {
  const { utility, State, production } = proposal;

  // CT Incentive
  if (State === "CT" && utility.toLowerCase() === "eversource") {
    return (0.0318 * production) / 12;
  }

  if (State === "MA") {
    const incentiveRate = 0.037;
    return (incentiveRate * production) / 12;
  }

  if (State === "RI") {
    return getAverageRIMonthlyPPAIncome(proposal);
  }

  return handleSRECIncome(production / 1000, State)[0] / 12;
};

export const getIncentiveLabel = (proposal) => {
  const { State } = proposal || {};
  return State === "NJ" || State === "PA"
    ? "SREC Income"
    : State === "MA"
    ? "REC Income"
    : // : State === "CT"
    // ? "RES Income"
    State === "RI"
    ? "PBI Income"
    : "PPA Income";
};

const moneyToNumber = (val) => {
  const res =
    typeof val === "string" ? parseFloat(val.replace(/[^\d.-]/g, "")) : +val;
  return res;
};

export const getPropertyTaxAbatement = (proposal) => {
  const { State, Zip, EPC, rebate = 0 } = proposal || {};
  return ["NY", "AZ"].includes(State) && NYCZipcodes.includes(+Zip)
    ? (EPC - rebate) * 0.3
    : 0;
};

export const getFederalTaxCreditRate = () => {
  return 0.3;
};

export const isIGS = (proposal) => {
  const proposalType = proposal?.proposalType || proposal?.type;
  return proposalType?.toLowerCase().includes("igs");
};

export const getEstimatedLifetimeSavings = (proposal = {}) => {
  const EFSDiff =
    proposal.efs_balloon_payment_amount - proposal.efs_bridge_loan_amount || 0;

  return getSavingsBreakdown(proposal).thirdVal - EFSDiff;
};

export const get30YearSavings = (proposal = {}) => {
  const prop = proposalSwitch(proposal.proposalType);
  const EFSDiff =
    proposal.efs_balloon_payment_amount - proposal.efs_bridge_loan_amount || 0;
  switch (prop) {
    case "Cash":
    case "Loan":
      return getSavingsBreakdown(proposal).thirdVal - EFSDiff;
    case "IGSPPA":
      return (get25YearSavings(proposal) / 25) * 30;
    default:
      return null;
  }
};

const RES_RATE = 0.0318;
const PANEL_DEGRADATION_RATE = 0.995; // Each panel looses 0.005 (0.5%) production each year
const TERM_LIMIT = 20; // years

export const getLifetimeCT_RESIncome = (proposal, years = TERM_LIMIT) => {
  const { production, utility } = proposal;
  let totalIncome = 0;
  if (utility.toLowerCase() === "eversource") {
    for (let year = 1; year <= years; year++) {
      totalIncome += getAnnualCT_RESIncome(production, year);
    }
  }

  return Math.round((totalIncome + Number.EPSILON) * 100) / 100; //Round to cents
};

const getAnnualCT_RESIncome = (production = 0, year = 1) => {
  const adjustedProduction = production * PANEL_DEGRADATION_RATE ** (year - 1);
  return adjustedProduction * RES_RATE;
};

export const getProductionOverNYears = (proposal, years = 1) => {
  let totalProduction = 0;
  for (let i = 1; i <= years; i++) {
    totalProduction += proposal.production * 0.995 ** (years - 1);
  }
  return Math.round(totalProduction);
};

const getC02OffsetInLbs = (proposal, years = 1) => {
  //www.bostonsolar.us/solar-blog-resource-center/blog/how-much-co2-do-solar-panels-save/
  const offsetInLbs = getProductionOverNYears(proposal, years) * 0.846;
  return Math.round(offsetInLbs * 100) / 100;
};
export const getC02OffsetInMetricTons = (proposal, years = 30) => {
  const offsetInMetricTons = getC02OffsetInLbs(proposal, years) * 0.000453592;
  return Math.round(offsetInMetricTons * 100) / 100;
};
export const getTreesOffset = (proposal) => {
  //www.usda.gov/media/blog/2015/03/17/power-one-tree-very-air-we-breathe#:~:text=According to the Arbor Day,and release oxygen in exchange.
  // USDA site says that 1 tree can absorb roughly 48 lbs of co2 from the atmosphere.
  // by that logic
  // trees = co2 offset in lbs / 48;
  return Math.round(getC02OffsetInLbs(proposal, 1) / 48); //Should always be 1 year because the same trees produce over many years
};

export const getOilBarrelsOffset = (proposal, years = 1) => {
  //https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#:~:text=The%20average%20carbon%20dioxide%20coefficient,gallon%20barrel%20(EPA%202022)
  // according to the usda 1 barrel = 0.0426 metric tons of co2 emissions (426.1 kg).
  // barrels = offset in metric tons / 0.0426.
  // 70.89 = 3.023 / 0.0426

  return Math.round(getC02OffsetInMetricTons(proposal, years) / 0.0426);
};

export const getRecycleOffset = (proposal, years = 1) => {
  //see README for details on how we get these numbers
  // 24.4 kgs is a rough estimate as to the co2 offset of one bag of recycling

  const recycleOffset =
    (getC02OffsetInMetricTons(proposal, years) * 1000) / 24.4;
  return Math.round(recycleOffset);
};

//First year param is 1, not 0
export const getUtilityRate = (proposal, years = 1) => {
  //Escalator comes in as a percent (ex: 3.5) and is normalized to between 1 and 2 (1.035)
  const utilityEscalator = proposal.utilityEscalator / 100 + 1;

  // return (
  //   Math.round(proposal.utilityRate * utilityEscalator ** (years - 1) * 1000) /
  //   1000
  // );
  return proposal.utilityRate * utilityEscalator ** (years - 1);
};

//First year param is 1, not 0
