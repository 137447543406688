import Context from "@/Context";
import { commas } from "@/utils/helpers";
import styled from "@emotion/styled";
import React, { useContext } from "react";

function GraphInfo() {
  const { proposal } = useContext(Context);
  const {
    utility_annual_consumption,
    utility_rate,
    utility_escalator,
    system_annual_production,
    utility_solar_escalator,
  } = proposal || {};
  return (
    <Wrapper>
      <p>Electricity Consumption</p>
      <p>{commas(utility_annual_consumption)} kWh/year</p>
      <p>Utility Rate</p>
      <p>{utility_rate?.toFixed(3)} kWh</p>
      <p>Utility Escalator</p>
      <p>{utility_escalator}%</p>
      <p>Year 1 Solar Generation</p>
      <p>{commas(system_annual_production)} kWh/year</p>
      <p>Solar Escalator</p>
      <p>{utility_solar_escalator}%</p>
    </Wrapper>
  );
}

export default GraphInfo;

const Wrapper = styled.div`
  border-radius: 8px;
  padding: 8px;
  background-color: #f5f5f5;
  align-self: flex-start;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 6px;
  column-gap: 1em;
  font-size: 14px;
  letter-spacing: -0.6;

  p:nth-of-type(odd) {
    font-family: Gotham Medium;
  }
  p:nth-of-type(even) {
    font-family: Gotham Book;
  }
`;
