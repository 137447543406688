import styled from "@emotion/styled";
import { Dispatch, SetStateAction } from "react";
import { ReactComponent as XIcon } from "@/assets/x-icon.svg";
type ImageModalProps = {
  src: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function ImageModal({ src, setOpen }: ImageModalProps) {
  return (
    <Wrapper>
      <XIcon
        width="32px"
        height="32px"
        className="close-btn"
        style={{ color: "white" }}
        onClick={() => setOpen(false)}
      />
      <img src={src} alt="Homeowner's design" />
    </Wrapper>
  );
}

export default ImageModal;

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 90%;
    max-width: 90%;
    height: 80%;
    max-height: 80%;
    object-fit: contain;
  }

  .close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
  }
`;
