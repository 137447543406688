import styled from "@emotion/styled";

export default function NavDashes() {
  return (
    <Wrapper>
      <div className="dash" />
      <div className="dash" />
      <div className="dash" />
      <div className="dash" />
      <div className="dash" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin: 0em 1em;
  align-self: center;

  .dash {
    width: 1em;
    height: 2px;
    background-color: #e3e3e3;
  }
`;
