import styled from "@emotion/styled";
import { ReactComponent as MSLogo } from "../../assets/msLogo-gradient.svg";
import CustomerDetails from "./CustomerDetails";

import { default as appData } from "../../../package.json";
function Header() {
  return (
    <StyledHeader>
      <div className="content">
        <div style={{ flex: 1 }}>
          <p>PV Design</p>
          <p style={{ fontSize: 10 }}>v{appData.version}</p>
        </div>
        <MSLogo style={{ flex: 2 }} />
        <CustomerDetails />
      </div>
    </StyledHeader>
  );
}

export default Header;

const StyledHeader = styled.div`
  top: 0;
  background: white;
  z-index: 1000;

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;
    font-family: Gotham Medium;
    background-color: white;
    height: 60px;
  }

  p {
    font-family: Lato;
    font-weight: lighter;
    color: #707070;
    font-size: 14px;
  }

  p.address {
    font-size: 11px;
  }

  h6 {
    color: #00ccff;
    font-size: 16px;
    white-space: nowrap;
  }
`;
