import { default as CardBase } from "@/Components_DEP/Card";
import styled from "@emotion/styled";

import panel01 from "@/assets/design/panel01.png";
import panel02 from "@/assets/design/panel02.png";
import useIntersectionObserver from "@/utils/useIntersectionObserver";
import { useRef } from "react";
import PanelAnimation from "./PanelAnimation";

function DesignCard2() {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  return (
    <Card>
      <div className="card-content" ref={ref}>
        <PanelAnimation />
        <p>
          Solar energy is the best way to lower energy costs and help make the
          world a better place.
        </p>
      </div>
      <div className={`bg-wrapper ${isVisible ? "animate" : ""}`}>
        <img src={panel01} alt="An opaque solar panel background" />
      </div>
    </Card>
  );
}

export default DesignCard2;

const Card = styled(CardBase)`
  position: relative;
  overflow: hidden;

  .card-content {
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 1000;
    align-items: center;

    p {
      font-size: 24px;
      font-family: Gotham Bold;
      margin: 0 54px;
      background: linear-gradient(#0cceff, #336699 140%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .bg-wrapper {
    position: absolute;

    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    opacity: 0.2;
    z-index: 1;

    background: linear-gradient(35deg, #fdd83c 25%, #0cceff, #336699);

    img {
      height: 170px;
      position: absolute;
      bottom: 0;
    }
  }

  .animate {
    -webkit-animation: gradient 2s linear forwards;
    -moz-animation: gradient 2s linear forwards;
    animation: gradient 2s ease forwards;

    background-size: 200% 200%;
  }

  @-moz-keyframes gradient {
    0% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
  @-webkit-keyframes gradient {
    0% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }

  @keyframes gradient {
    0% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;
