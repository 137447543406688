import { ReactComponent as Leaf1 } from "@/assets/savingsSummary/world-leaf1.svg";
import { ReactComponent as Leaf2 } from "@/assets/savingsSummary/world-leaf2.svg";
import styled from "@emotion/styled";

function Leaves() {
  return (
    <Wrapper>
      <Leaf2 width="78px" className="leaf2" />
      <Leaf1 width="70px" className="leaf1" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  top: 66%;
  left: 37%;
  transform: rotate(180deg);
  z-index: 300;

  svg {
    position: absolute;
    -webkit-animation: shake 1s cubic-bezier(0.64, 0.57, 0.67, 1.53) infinite
      alternate;
    -moz-animation: shake 1s cubic-bezier(0.64, 0.57, 0.67, 1.53) infinite
      alternate;
    animation: shake 1s cubic-bezier(0.64, 0.57, 0.67, 1.53) infinite alternate;
  }

  .leaf2 {
    transform: rotate(-2deg);
    top: -3px;
    left: -2px;

    -webkit-animation-delay: 0.7s;
    -moz-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }

  @-moz-keyframes shake {
    100% {
      -webkit-transform: rotate(2deg);
    }
  }
  @-webkit-keyframes shake {
    100% {
      -webkit-transform: rotate(2deg);
    }
  }
  @keyframes shake {
    100% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
  }
`;

export default Leaves;
