const IGSData = (data) => {
  let {
    consumption,
    production,
    utilityRate,
    wholesaleRate,
    utilityEscalator,
    solarRate,
    solarEscalator,
    utility,
  } = data;

  utilityEscalator = +utilityEscalator;
  consumption = +consumption;
  production = +production;
  utilityRate = +utilityRate;
  solarRate = +solarRate;
  solarEscalator = +solarEscalator;
  wholesaleRate = +wholesaleRate;

  utilityEscalator = utilityEscalator / 100 + 1;
  solarEscalator = solarEscalator / 100 + 1;

  let oldAnnualBill = utilityRate * consumption;

  let fullArr = [];

  // let annualRECIncome =
  //   utility === "Eversource" ? +(production * 0.0318).toFixed(2) : 0;
  let annualRECIncome = 0; //CT REC is not currently offered;

  // ! wholesale rate

  let savingsIGS = (
    futureSolarRate,
    futureUtilRate,
    futureAnnualBill,
    futureProduction,
    futureWholesaleRate
  ) => {
    let newSolarBill = solarBill(futureSolarRate);
    let newUtilBill = newUtilityBill(
      futureUtilRate,
      futureProduction,
      futureWholesaleRate
    );
    return futureAnnualBill - (newUtilBill + newSolarBill) + annualRECIncome;
  };

  let solarBill = (futureSolarRate) => production * futureSolarRate;

  let newUtilityBill = (
    futureUtilRate,
    currProduction,
    futureWholesaleRate
  ) => {
    if (Math.sign(consumption - currProduction) === -1) {
      return (consumption - currProduction) * futureWholesaleRate;
    } else {
      return (consumption - currProduction) * futureUtilRate;
    }
  };

  let totals = (
    futureSolarRate,
    futureUtilRate,
    futureAnnualBill,
    futureProduction,
    futureWholesaleRate
  ) =>
    savingsIGS(
      futureSolarRate,
      futureUtilRate,
      futureAnnualBill,
      futureProduction,
      futureWholesaleRate
    ) + newUtilityBill(futureUtilRate, futureProduction, futureWholesaleRate);

  let preSolar = (futureUtilRate) => consumption * futureUtilRate;

  let futureUtilRate = utilityRate;
  let futureSolarRate = solarRate;
  let futureAnnualBill = oldAnnualBill;
  let futureWholesaleRate = wholesaleRate;

  for (let i = 1; i < 26; i++) {
    futureUtilRate = i > 1 ? futureUtilRate * utilityEscalator : futureUtilRate;
    futureSolarRate =
      i > 1 ? futureSolarRate * solarEscalator : futureSolarRate;
    futureAnnualBill =
      i > 1 ? futureAnnualBill * utilityEscalator : futureAnnualBill;
    futureWholesaleRate =
      i > 1 ? futureWholesaleRate * utilityEscalator : futureWholesaleRate;
    // annualRECIncome = i > 20 ? 0 : annualRECIncome;  //CT REC is not currently offered;
    fullArr.push([
      savingsIGS(
        futureSolarRate,
        futureUtilRate,
        futureAnnualBill,
        production,
        futureWholesaleRate
      ),
      newUtilityBill(futureUtilRate, production, futureWholesaleRate),
      solarBill(futureSolarRate),
      preSolar(futureUtilRate),
      totals(
        futureSolarRate,
        futureUtilRate,
        futureAnnualBill,
        production,
        futureWholesaleRate
      ),
    ]);
  }

  let savings = 0;
  let newPayments = 0;
  let oldPayments = 0;
  let allSolarBills = 0;

  //   fullArr.forEach(x => {
  //     oldPayments += +x[0];
  //     newPayments += +x[1];
  //     yearlyFull += +x[2];
  //   });
  fullArr.forEach((x) => {
    savings += +x[0];
    newPayments += +x[1];
    allSolarBills += x[2];
    oldPayments += +x[3];
  });
  //   console.log(savings, newPayments, oldPayments, allSolarBills, allTaxCredits);
  let twentyFiveYearCost = allSolarBills + newPayments;
  let firstYearSavings = fullArr[0][0];
  let firstAnnualSolarBill = fullArr[0][2] / 12;
  let firstPostSolarAnnualUtilityBill = fullArr[0][1] / 12;
  let firstPreSolarAnnualUtility = fullArr[0][3] / 12;

  const igsVals = {
    savings,
    newPayments,
    oldPayments,
    allSolarBills,
    twentyFiveYearCost,
    firstYearSavings,
    firstPreSolarAnnualUtility,
    firstPostSolarAnnualUtilityBill,
    firstAnnualSolarBill,
  };
  //   };

  return igsVals;
};

export default IGSData;
