import Context from "@/Context";
import { money } from "@/utils/helpers";
import { useContext } from "react";
import OverviewCard from "./OverviewCard";

function StorageOverview() {
  const { proposal } = useContext(Context);
  const { battery_cost, financing_term, financing_interest_rate } =
    proposal || {};

  return (
    <OverviewCard header="Storage Overview">
      <p>Storage Cost: </p>
      <p>{money(battery_cost)}</p>
      <p>Battery Rebate: </p>
      {/* <p>{money(batteryRebate)}</p> */}
      <p>{money(proposal?.battery_rebate || 0)}</p>
      {/* <p>Storage Loan: </p>
      <p>{money(getStorageLoan(proposal))}</p> */}
      <p>Monthly Storage System Cost </p>
      <p>{money(proposal?.battery_monthly_cost)}</p>
      <p>Battery Term: </p>
      <p>{financing_term} years</p>
      <p>Term Interest Rate: </p>
      <p>{financing_interest_rate || 2.9}%</p>
    </OverviewCard>
  );
}

export default StorageOverview;
