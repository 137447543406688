import Card from "@/Components_DEP/Card";
import styled from "@emotion/styled";

import { ReactComponent as Travel } from "@/assets/savingsSummary/travel.svg";
import { ReactComponent as Donate } from "@/assets/savingsSummary/donate.svg";
import { ReactComponent as Home } from "@/assets/savingsSummary/home.svg";
import { ReactComponent as Tuition } from "@/assets/savingsSummary/tuition.svg";

function SavingsCard3() {
  return (
    <Wrapper>
      <h3>Ways To Reinvest Savings</h3>
      <div className="items-wrapper">
        <div className="item">
          <Travel />
          <p className="text">Travel</p>
        </div>
        <div className="item">
          <Donate />
          <p className="text">Donate</p>
        </div>

        <div className="item">
          <Home />
          <p className="text">Home Repair</p>
        </div>
        <div className="item">
          <Tuition />
          <p className="text">Tuition</p>
        </div>
      </div>
    </Wrapper>
  );
}

export default SavingsCard3;

const Wrapper = styled(Card)`
  position: relative;
  z-index: 600;

  h3 {
    margin: 32px;
    margin-top: 4px;
    color: #336699;
  }

  .items-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 16px;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
        height: 62px;
      }
    }

    .text {
      margin-top: 12px;
    }
  }
`;
