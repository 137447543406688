import styled from "@emotion/styled";

type PillDisplayProps = {
  value?: number | string;
  label?: string;
  variant?: "header" | "" | undefined;
};

function PillDisplay({
  value = "VAL",
  label = "",
  variant = "",
}: PillDisplayProps) {
  return (
    <Wrapper>
      {variant && <div className="line" />}
      <div className={`text-wrapper ${variant}`}>
        <p>
          <span>{value}</span> {label}
        </p>
      </div>
    </Wrapper>
  );
}

export default PillDisplay;

const Wrapper = styled.div`
  position: relative;
  display: flex;

  .text-wrapper {
    height: 28px;
    border: 2px solid #0cceff;
    border-radius: 100px;
    background-color: white;
    z-index: 500;
    overflow: hidden;
    margin: auto;
    padding: 0px 8px;
    margin-top: 1em;
  }

  .header {
    margin-top: -15px;
  }

  p {
    font-family: Gotham Medium;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #336699;
    background-color: white;
    z-index: 400;

    span {
      font-size: 20px;
      margin-right: 6px;
    }
  }

  .line {
    position: absolute;
    width: 100vw;
    height: 2px;
    background: #0cceff;
    z-index: 1;
    left: -16px;
  }
`;
