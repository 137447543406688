import Context from "@/Context";
import styled from "@emotion/styled";
import { useContext } from "react";
import MoreDetailsButton from "./MoreDetailsButton";

function CustomerDetails() {
  const { proposal_dep } = useContext(Context);
  return (
    <Wrapper>
      <MoreDetailsButton />
      <h6>{proposal_dep?.CustomerName}</h6>
      <p className="address">{proposal_dep?.Address}</p>
      <p className="address">
        {proposal_dep?.City}, {proposal_dep?.State} {proposal_dep?.Zip}
      </p>
      {/* <p className="address">{proposal?.oppnum}</p> */}
    </Wrapper>
  );
}

export default CustomerDetails;

const Wrapper = styled.div`
  flex: 1; // this is important for spacing, see parent wrapper
  text-transform: capitalize;
  position: relative;
`;
