import { createContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import {
  post30YearEstimatedSavings,
  postCT_RESIncentive,
  postBattery,
} from "./utils/api";
import { Proposal_DEP } from "./types/Proposal_DEP";
import { Proposal } from "./types/Proposal";

export const getDEPProposal = async ({ queryKey }: { queryKey: string[] }) => {
  const [_, guid] = queryKey;
  if (!guid) return;
  const response = await axios.post(
    "https://tld49hyei2.execute-api.us-east-1.amazonaws.com/latest/v1/prod/get_completed_proposal",
    {
      guid,
    }
  );

  return response.data;
};

const getProposal = async (draft_id: string): Promise<Proposal> => {
  const { data } = await axios.get(
    `https://api.momentumsolar.io/proposal/draft/${draft_id}`
  );
  console.log(data);
  return data;
};

export const getPins = async (state: string, next: string) => {
  const body = next ? { next } : { value: state.toUpperCase(), type: "state" };
  const response = await axios.post(
    "https://api.momentumsolar.io/crm_micro/installed_map",
    body
  );
  return response.data;
};

interface ContextProps {
  proposal?: Proposal;
  proposal_dep?: Proposal_DEP;
  setGuid: (guid: string) => void;
  pinsRef: React.MutableRefObject<any[]> | undefined;
  userLocation: any;
  setUserLocation: (userLocation: any) => void;
  setOnPinsUpdated: (fn: any) => void;
}

const Context = createContext<ContextProps>({
  proposal: undefined,
  proposal_dep: undefined,
  setGuid: () => {},
  pinsRef: undefined,
  userLocation: undefined,
  setUserLocation: () => {},
  setOnPinsUpdated: () => {},
});

export function ContextProvider({ children }: { children: React.ReactNode }) {
  const [guid, setGuid] = useState("");
  const [userLocation, setUserLocation] = useState<
    { lat: number; lng: number } | undefined
  >();
  const pinsRef = useRef([]);
  const onPinsUpdatedRef = useRef(() => {});

  const getPaginatedPins = async (
    state: string,
    pinsRef: any,
    next?: string
  ) => {
    try {
      const res = await getPins(state, next || "");
      if (res.value) {
        pinsRef.current = [...pinsRef.current, ...res.value];
        onPinsUpdatedRef.current();
      }
      if (res.next) {
        getPaginatedPins(state, pinsRef, res.next);
      }
    } catch (err) {
      console.log(err);
      alert("Something went wrong fetching pins.\n\n" + err);
    }
  };

  const { data: proposal_dep } = useQuery({
    queryKey: ["get-dep-proposal", guid],
    queryFn: getDEPProposal,
  });

  const { data: proposal } = useQuery({
    queryKey: ["get-proposal", guid],
    queryFn: () => getProposal(guid),
  });

  useEffect(() => {
    if (proposal_dep && proposal_dep.State && guid) {
      post30YearEstimatedSavings(proposal_dep, guid);
      postCT_RESIncentive(proposal_dep, guid);
      postBattery(proposal_dep, guid);
      getPaginatedPins(proposal_dep.State, pinsRef);
    }

    if (proposal && proposal.property_state && guid) {
      post30YearEstimatedSavings(proposal, guid);
      postCT_RESIncentive(proposal, guid);
      postBattery(proposal, guid);
      getPaginatedPins(proposal.property_state, pinsRef);
    }
  }, [proposal_dep, proposal, guid]);

  document.addEventListener("click", (e) => {
    e.stopPropagation();
    if (!userLocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const { latitude: lat, longitude: lng } = pos.coords;
        setUserLocation({ lat, lng });
      });
    }
  });

  document.addEventListener("touch", (e) => {
    e.stopPropagation();
    if (!userLocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const { latitude: lat, longitude: lng } = pos.coords;
        setUserLocation({ lat, lng });
      });
    }
  });

  return (
    <Context.Provider
      value={{
        proposal,
        proposal_dep,
        setGuid,
        pinsRef,
        userLocation,
        setUserLocation,
        setOnPinsUpdated: (fn) => {
          onPinsUpdatedRef.current = fn;
        },
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
