import styled from "@emotion/styled";

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  margin: 0px 12px;
  gap: 12px;

  p:nth-of-type(2) {
    width: 92px;
    font-family: Gotham Medium;
    text-align: end;
  }

  *:nth-of-type() {
    min-width: 15%;
  }
`;

export default InfoRow;
