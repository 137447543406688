import { css, Global } from "@emotion/react";

export const GlobalStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: Charlotte Southern;
        src: url("fonts/charlotte-southern-webfont.woff2") format("woff2"),
          url("fonts/charlotte-southern-webfont.woff") format("woff");
        font-weight: normal;
        font-style: normal;
      }
      @font-face {
        font-family: Gotham Bold;
        src: url("fonts/gotham-bold-webfont.woff2") format("woff2"),
          url("fonts/gotham-bold-webfont.woff") format("woff");
        font-weight: bold;
        font-style: normal;
      }

      @font-face {
        font-family: Gotham Book;
        src: url("fonts/gotham-book-webfont.woff2") format("woff2"),
          url("fonts/gotham-book-webfont.woff") format("woff");
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: Gotham Medium;
        src: url("fonts/gotham-medium-webfont.woff2") format("woff2"),
          url("fonts/gotham-medium-webfont.woff") format("woff");
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: Lato;
        src: url("fonts/Lato-Regular.ttf") format("truetype"),
          url("fonts/Lato-Regular.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: Lato Bold;
        src: url("fonts/Lato-Bold.ttf") format("truetype"),
          url("fonts/Lato-Bold.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
      }

      html,
      body {
        width: 100%;
        height: 100%;
        min-height: 100%;
        padding: 0;
        margin: 0;
        scroll-behavior: smooth;
        background-color: #f5f5f5;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        margin: 0;
        font-family: Gotham Medium;
        font-smooth: antialiased;
        -webkit-font-smoothing: antialiased;
      }

      p {
        font-family: Lato;
        color: #24272a;
      }

      h1 {
        font-family: Charlotte Southern;
        font-size: clamp(52px, calc(3.25rem + ((1vw - 3.44px) * 6.8702)), 88px);
        font-weight: lighter;
      }

      h2 {
        font-family: Gotham Bold;
        font-size: clamp(
          22px,
          calc(1.375rem + ((1vw - 3.44px) * 1.3587)),
          32px
        );
        color: #00ccff;
      }

      h3 {
        font-family: Gotham Bold;
        color: #00ccff;
      }

      h6 {
        letter-spacing: -0.6;
        font-family: Gotham Medium;
        color: #00ccff;
        font-size: 24px;
      }

      .shadow {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
      }
    `}
  />
);
