import styled from "@emotion/styled";

import { ReactComponent as SquiggleBase } from "@/assets/savingsSummary/world-squiggle.svg";
import delay from "@/utils/animationDelay";

function Squiggles() {
  return (
    <Wrapper>
      <Squiggle style={delay(0)} />
      <Squiggle style={delay(0.2)} />
      <Squiggle style={delay(0.4)} />
    </Wrapper>
  );
}

export default Squiggles;

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
  display: flex;
  gap: 4px;
  top: 13%;
  transform: translate(6px);
`;

const Squiggle = styled(SquiggleBase)`
  /* transform: rotate(0deg); */

  /* background-color: white; */

  transform: translateY(0px);
  -webkit-animation: dash 1s ease-in-out infinite alternate;
  -moz-animation: dash 1s ease-in-out infinite alternate;
  animation: dash 1s ease-in-out infinite alternate;

  @keyframes dash {
    to {
      transform: translateY(5px);
    }
  }
`;
