import styled from "@emotion/styled";

type SavingsItemProps = {
  Icon: any;
  text: string | number;
  value: string | number;
};

function SavingsItem({ Icon, text, value }: SavingsItemProps) {
  return (
    <Wrapper>
      <Icon height="64" width="64" style={{ overflow: "visible" }} />
      <p>{text}</p>
      <p>{value}</p>
    </Wrapper>
  );
}

export default SavingsItem;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
  z-index: 500;

  p:first-of-type {
    font-family: Gotham Medium;
    color: #4b4b4b;
  }

  p:nth-of-type(2) {
    font-family: Gotham Bold;
    font-size: 24px;
    color: white;
  }
`;
