import Context from "@/Context";
import {
  getEstimatedLifetimeCost,
  getNetSolarAndStorageCost,
  getRebates,
  getSolarAndBatteryITC,
  getTotalCost,
  getTotalLoanAmount,
  getTotalSavings,
} from "@/utils/calculations";
import { money } from "@/utils/helpers";
import { useContext } from "react";
import OverviewCard from "./OverviewCard";

function TotalCostOverview() {
  const { proposal } = useContext(Context);

  const {
    battery_cost = 0,
    battery_rebate = 0,
    system_epc = 0,
    incentive_rebate = 0,
    financing_amount = 0,
    incentive_itc = 0,
    total_savings = 0,
  } = proposal || {};

  const batteryItc = incentive_itc + (battery_cost - battery_rebate) * 0.3;
  const netSolarAndStorageCost =
    system_epc + battery_cost - batteryItc - battery_rebate - incentive_rebate;

  return (
    <OverviewCard
      header="Total Cost Overview"
      background="#F0FCFF"
      color="#0CCEFF"
      style={{ gridRow: "span 2" }}
    >
      <p>Total Solar System Cost: </p>
      <p>{money(system_epc)}</p>

      {/* {isRoofing ? (
        <>
          <p>Total Roof Cost: </p>
          <p>{money(roof_cost)}</p>
        </>
      ) : ( */}
      <>
        <p>Total Battery System Cost: </p>
        <p>{money(battery_cost)}</p>
      </>
      {/* )} */}
      <div className="line" />
      {/* {isRoofing ? (
        <>
          <p>Total Solar + Roofing Cost: </p>
          <p>{money(getTotalCost(proposal))}</p>
        </>
      ) : (
        <> */}
      <p>Total Solar + Battery System Cost: </p>
      <p>{money((proposal?.system_epc || 0) + battery_cost)}</p>
      {/* </>
      )} */}

      <p>Applicable Rebate(s): </p>
      <p>
        (
        {money(
          (proposal?.incentive_rebate || 0) + (proposal?.battery_rebate || 0)
        )}
        )
      </p>

      <div className="line" />
      {/* {isRoofing ? (
        <>
          <p>Total Solar + Roofing Loan Amount: </p>
          <p>{money(getTotalLoanAmount(proposal))}</p>
        </>
      ) : (
        <> */}
      <p>Total Solar + Battery Loan Amount: </p>
      <p>{money(financing_amount + battery_cost)}</p>
      {/* </>
      )} */}

      <p>Less ITC:</p>
      <p>({money(batteryItc)})</p>

      {/* {isRoofing ? (
        <>
          <p>Net Solar + Roofing: </p>
          <p>{money(getNetSolarAndStorageCost(proposal))}</p>
        </>
      ) : (
        <> */}
      <p>Net Solar + Storage: </p>
      <p>{money(netSolarAndStorageCost)}</p>
      {/* </>
      )} */}

      <p>Less Solar Savings: </p>
      <p>({money(total_savings || 0)})</p>

      <div className="line" />

      <p>Estimated Total Lifetime Cost*: </p>
      <p>
        {money(financing_amount + battery_cost - total_savings - batteryItc)}
      </p>
    </OverviewCard>
  );
}

export default TotalCostOverview;
