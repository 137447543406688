import { default as CardBase } from "@/Components_DEP/Card";
import styled from "@emotion/styled";
import homeImage from "@/assets/home-img1.png";
import reviewUser from "@/assets/thankyou/review-user.png";
import { ReactComponent as MSLogo } from "@/assets/msLogo-transparent.svg";
import { ReactComponent as Bracket } from "@/assets/thankyou/bracket.svg";
import { ReactComponent as Quote } from "@/assets/thankyou/quote.svg";
import Stars from "./Stars";
function ReviewCard() {
  return (
    <Card>
      <div className="content">
        <h3>A Word From Our Happy Customers</h3>
        <div className="row">
          <div className="img-wrapper">
            <Bracket className="flipped" />
            <Bracket />
            <img src={homeImage} alt="A home on a sunny day with Solar" />
          </div>
          <div>
            <div className="row" style={{ marginBottom: "16px" }}>
              <div className="user-wrapper">
                <img
                  className="user-icon"
                  src={reviewUser}
                  alt="Review User's Icon"
                />
                <p>Christopher D.</p>
              </div>
              <Stars />
            </div>

            <div className="row">
              <Quote className="quote" />
              <p>
                I couldn’t be happier with my experience with Momentum Solar.
                The single biggest reason to use this company vs. their
                competitors is their top-notch customer service and installation
                team. My system is fantastic.
              </p>
            </div>
          </div>
        </div>
      </div>
      <MSLogo className="bg-logo" />
    </Card>
  );
}

export default ReviewCard;

const Card = styled(CardBase)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  padding: 24px;
  overflow: visible;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 28px;
    z-index: 300;
  }

  .img-wrapper {
    position: relative;

    img {
      height: 254px;
    }

    @media screen and (max-width: 856px) {
      img {
        height: 172px;
      }
    }
    svg {
      position: absolute;
      bottom: 0px;
      right: -4px;
    }

    svg.flipped {
      top: -4px;
      left: -4px;
      transform: rotate(180deg);
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .quote {
    min-width: 30px;
  }

  .user-icon {
    aspect-ratio: 1;
    height: 38px;
    width: 38px;
  }

  .user-wrapper {
    display: flex;
    gap: 1em;
    align-items: center;
    font-family: Gotham Medium;
    font-size: 13px;
  }

  .bg-logo {
    position: absolute;
    margin: auto;
    z-index: 1;
  }
`;
