import Context from "@/Context";
import {
  getMonthlyRoofLoanCost,
  getMonthlyStorageCost,
  getTotalMonthlyCost,
} from "@/utils/calculations";
import { money, round } from "@/utils/helpers";
import { useContext } from "react";
import OverviewCard from "./OverviewCard";

function MonthlyOverview() {
  const { proposal_dep } = useContext(Context);
  const { loanPayment, loanTerm, interest, proposalType, roof_loan_payment } =
    proposal_dep || {};
  const isRoofing = proposalType === "DecoLoan" || proposalType === "DecoCash";
  return (
    <OverviewCard
      header="Monthly Overview"
      background="#E6F3FF"
      color="#336699"
    >
      <p>Monthly Solar Cost: </p>
      <p>{money(loanPayment)}</p>

      {isRoofing ? (
        <>
          <p>Monthly Roofing Cost: </p>
          <p>{proposal_dep && money(getMonthlyRoofLoanCost(proposal_dep))}</p>
        </>
      ) : (
        <>
          <p>Monthly Storage Cost: </p>
          <p>{proposal_dep && money(getMonthlyStorageCost(proposal_dep))}</p>
        </>
      )}

      <p>Solar + {isRoofing ? "Roofing" : "Storage"} Term: </p>
      <p>{loanTerm || 0} years</p>
      <p>Interest Rate: </p>
      <p>{round(interest)}%</p>
      <div className="line" />
      <p>Total Monthly Cost: </p>
      <p>
        {money(
          getTotalMonthlyCost(proposal_dep)
          //+ getMonthlyRoofLoanCost(proposal_dep)
        )}
      </p>
    </OverviewCard>
  );
}

export default MonthlyOverview;
