import styled from "@emotion/styled";
import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg";

type Props = { direction?: "right" | "left"; onClick: () => void };

function NavArrow({ direction = "left", onClick }: Props) {
  return (
    <Wrapper className={direction}>
      {direction === "left" ? (
        <ArrowLeft className="arrow" onClick={onClick} />
      ) : (
        <ArrowRight className="arrow" onClick={onClick} />
      )}
    </Wrapper>
  );
}

export default NavArrow;

const Wrapper = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  height: 100%;
  aspect-ratio: 1;
  z-index: 1000;

  background: linear-gradient(to left, transparent, white 40%);

  &.right {
    background: linear-gradient(to right, transparent, white 40%);
    position: absolute;
    right: 0;
  }

  .arrow {
    padding: 16px;
  }
`;
