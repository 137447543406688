import { default as CardBase } from "@/Components_DEP/Card";
import { ReactComponent as Wallet } from "@/assets/referrals/wallet.svg";
import { ReactComponent as Environment } from "@/assets/referrals/environment.svg";
import { ReactComponent as Home } from "@/assets/referrals/home.svg";
import { ReactComponent as SolarConnect } from "@/assets/solar-connect.svg";

import styled from "@emotion/styled";

function ReferralCard1() {
  return (
    <>
      <SolarConnect
        style={{ alignSelf: "center", position: "absolute", top: -16 }}
      />
      <Card>
        <h2>Who do you know that...</h2>
        <div className="item">
          <Wallet />
          <p>
            likes keeping <span>money</span> in their pocket instead of giving
            it to the utility company?
          </p>
        </div>
        <div className="item">
          <Environment />
          <p>
            wants to help the <span>environment</span> by reducing their carbon
            impact?
          </p>
        </div>
        <div className="item">
          <Home />
          <p>
            wants to <span>increase</span> their home's overall value?
          </p>
        </div>
      </Card>
    </>
  );
}

export default ReferralCard1;
const Card = styled(CardBase)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;
  gap: 24px;

  svg {
    margin-bottom: 16px;
    height: 56px;
  }

  p {
    font-family: Gotham Book;
  }

  .item {
    text-align: center;
  }

  span {
    font-family: Gotham Medium;
    color: #336699;
  }
`;
