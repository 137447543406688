import styled from "@emotion/styled";
import { ReactNode } from "react";

type InfoBarProps = {
  children?: ReactNode | ReactNode[] | JSX.Element;
};

function InfoBar({ children }: InfoBarProps) {
  return <Wrapper>{children}</Wrapper>;
}

export default InfoBar;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px -16px;
  padding: 12px 16px;
  background-color: #0cceff;

  p {
    color: white;
    font-family: Gotham Bold;
  }
`;
