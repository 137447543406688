import { default as CardBase } from "@/Components_DEP/Card";
import CardHeader from "@/Components_DEP/Card/CardHeader";
import Context from "@/Context";
import {
  getFederalTaxCredit,
  getFederalTaxCreditRate,
  getIrrValues,
  getNetSystemValue,
  getPropertyTaxAbatement,
  getStateTaxCredit,
} from "@/utils/calculations";
import { money } from "@/utils/helpers";
// import { NYCZipcodes } from "@/utils/stateCalculations/utilFuncs";
import styled from "@emotion/styled";
import { useContext } from "react";
import InfoBar from "./InfoBar";
import InfoRow from "./InfoRow";
import PillDisplay from "./PillOverlay";
import { NYCZipcodes } from "@/utils/stateCalculations/utilFuncs";

function TotalSystemCost() {
  const { proposal_dep } = useContext(Context);
  const {
    EPC = 0,
    rebate = 0,
    State = "",
    Zip = 0,
    proposalType,
    roof_cost = 0,
  } = proposal_dep || {};
  const { irrValue } = getIrrValues(proposal_dep);
  const isRoofing = proposalType === "DecoLoan" || proposalType === "DecoCash";

  return (
    <Card>
      <CardHeader label="Total System Cost" />
      <PillDisplay
        value={`${irrValue?.toFixed(2)}%`}
        label="Estimated 30-Year Inter Rate of Return (IRR)"
        variant="header"
      />
      <div className="card-content">
        <InfoRow>
          <p>Solar System Cost</p>
          <p>{money(EPC)}</p>
        </InfoRow>
        {isRoofing && (
          <InfoRow>
            <p>Roof Cost</p>
            <p>{money(+roof_cost)}</p>
          </InfoRow>
        )}
        {/* {["TX", "CT", "NY", "AZ"].includes(State) && ( */}
        {rebate > 0 && (
          <>
            <InfoRow>
              <p>Rebate</p>
              <p>{money(rebate)}</p>
            </InfoRow>
            <InfoBar>
              <InfoRow>
                <p>Net Solar System Cost After Rebate</p>
                <p>{money(EPC + +roof_cost - rebate)}</p>
              </InfoRow>
            </InfoBar>
          </>
        )}
        <InfoRow>
          <p>
            Less: Estimated {getFederalTaxCreditRate() * 100}% Federal Tax
            Credit
          </p>
          <p>{money(getFederalTaxCredit(proposal_dep))}</p>
        </InfoRow>
        {["NY", "AZ", "MA"].includes(State || "") && (
          <InfoRow>
            <p>{State === "NY" ? `NY State Tax Credit` : `State Tax Credit`}</p>
            {(State === "NY" || State === "AZ" || State === "MA") && (
              <p>{money(getStateTaxCredit(proposal_dep))}</p>
            )}
          </InfoRow>
        )}
        {["NY", "AZ"].includes(State || "") && NYCZipcodes.includes(+Zip) && (
          <InfoRow>
            <p>Property Tax Abatement</p>
            <p>{money(getPropertyTaxAbatement(proposal_dep))}</p>
          </InfoRow>
        )}
        <InfoBar>
          <InfoRow>
            <p>Net Solar System Cost</p>
            <p>{money(getNetSystemValue(proposal_dep))}</p>
          </InfoRow>
        </InfoBar>
      </div>
      <p className="footer">
        Tax credits, rebates and incentives may very depending on your
        circumstances. We do not provide tax advice and recommend you consult
        with your tax professional for guidance.
      </p>
    </Card>
  );
}

export default TotalSystemCost;

const Card = styled(CardBase)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;

    margin-top: 16px;
    height: 100%;
  }

  .footer {
    color: #a5a5a5;
    font-family: Gotham Medium;
    font-size: 8px;
    width: 80%;
    text-align: center;
    margin: 3px auto;
  }
`;
