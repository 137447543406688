import { default as CardBase } from "@/Components_DEP/Card";
import CardHeader from "@/Components_DEP/Card/CardHeader";
import styled from "@emotion/styled";
import MonthlyOverview from "./MonthlyOverview";
import StorageOverview from "./StorageOverview";
import TotalCostOverview from "./TotalCostOverview";

function StorageAndCostOVerview() {
  return (
    <Card>
      <CardHeader label="Storage and Cost Overview" />
      <div className="card-content">
        <StorageOverview />
        <MonthlyOverview />
        <TotalCostOverview />
        <div className="footer-wrapper">
          <p className="footer">
            The payment amount disclosed assumes that you make your Incentive
            Payment as provided in your loan agreement. If you do no make the
            Incentive Payment, your payment amount will increase. Please see the
            terms of your loan agreement for additional details regarding the
            timing and amount of the incentive.
          </p>
          <div className="footer-line" />
          <p className="footer">
            *Tax credits, rebates and incentives may vary depending on your
            circumstances. We do not provide tax advice and recommend you
            consult with your tax professional for guidance.
          </p>
        </div>
      </div>
    </Card>
  );
}

export default StorageAndCostOVerview;

const Card = styled(CardBase)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .card-content {
    display: grid;
    gap: 8px;
    grid-template-columns: auto auto auto;
    margin-top: 8px;
    align-items: stretch;
  }

  .footer-wrapper {
    grid-column: span 2;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer {
    font-size: 8px;
    color: #848484;
    max-width: 90%;
  }

  .footer-line {
    width: 70%;
    height: 1px;
    background: #d3d3d3;
    margin: 2px 0;
  }
`;
