import Context from "@/Context";
import { money } from "@/utils/helpers";
import styled from "@emotion/styled";
import React, { useContext } from "react";

/*
 The payment factor on the proposals for sunlight assumes that the 
 customer will opt into the bridge payment in the firspt 16 months. If they decide no to,
 these are the new rates
 */
const ALT_LOAN_DETAILS = [
  { term: 15, interest: 3.99, paymentFactor: 0.0054045 },
  { term: 20, interest: 5.99, paymentFactor: 0.0052783 },
  { term: 25, interest: 4.49, paymentFactor: 0.0040463 },
  { term: 25, interest: 6.99, paymentFactor: 0.005219115 },
];

function SunlightCopyCard() {
  const { proposal } = useContext(Context);
  const {
    financing_term,
    financing_interest_rate,
    financing_amount,
    financing_financial_partner,
  } = proposal || {};

  const altLoan = ALT_LOAN_DETAILS.find(
    (d) => d.term === financing_term && financing_interest_rate === d.interest
  );

  const remainingPayments =
    (proposal?.financing_number_of_loan_payments || 0) - 16;

  if (financing_financial_partner !== "Sunlight") return null;
  return (
    <Wrapper>
      <h3>Sunlight Disclosure</h3>
      <p>
        No down payment required. The fixed Annual Percentage Rate for a{" "}
        {financing_term} year loan is {financing_interest_rate}%. The first
        monthly payment on the loan is due approximately 90 days after
        installation. The amount of your first 16 payments will be $
        {proposal?.financing_monthly_loan_payment}. If you make a voluntary
        payment of {money(proposal?.incentive_federal_tax_credit || 0)}( 30% of
        your amount financed) by payment 16, your remaining {remainingPayments}{" "}
        payments will be approximately $
        {proposal?.financing_monthly_loan_payment}. If no voluntary payment is
        made, the remaining {remainingPayments} payments will be{" "}
        {money((financing_amount || 0) * (altLoan?.paymentFactor || NaN))}. The
        numbers presented here are estimates only. The payment term stated in
        your loan documents will govern. Your loan agreement may assume you have
        not elected to pay by ACH.
      </p>
    </Wrapper>
  );
}

export default SunlightCopyCard;

const Wrapper = styled.div`
  /* border: 2px solid #336699; */
  padding: 20px;
  border-radius: 10px;
  margin-top: 16px;
  background-color: #ffffff66;

  h3 {
    color: #336699;
    /* color: #fff; */
    margin-bottom: 8px;
  }

  p {
    line-height: 1.4;
    color: grey;
  }
`;
