import Context from "@/Context";
import React, { useContext, useState } from "react";
import MoreDetailsModal from "./MoreDetailsModal";
import { ReactComponent as InfoIcon } from "@/assets/information-icon.svg";
import styled from "@emotion/styled";

function MoreDetailsButton() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonPosition>
        <InfoButton onClick={() => setOpen((prev) => !prev)}>
          <InfoIcon height={16} />
        </InfoButton>
      </ButtonPosition>
      <MoreDetailsModal open={open} setOpen={setOpen} />
    </>
  );
}

export default MoreDetailsButton;
const InfoButton = styled.button`
  border-radius: 100px;
  aspect-ratio: 1;
  border: 1px solid #00ccff;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  color: #00ccff;
`;

const ButtonPosition = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translate(calc(-100% - 8px));
`;
