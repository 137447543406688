import Context from "@/Context";
import { CSSProperties, useContext } from "react";

const LICENSE_NUMBERS: Record<string, Record<string, string>> = {
  FL: { Solar: "CVC57036", Electrical: "EC1300821" },
};

export function OptionalLicenseNumbers({
  style = {},
  orientation = "vertical",
  color = "#cccccc",
  className = "",
}: {
  style?: CSSProperties;
  orientation?: "horizontal" | "vertical";
  color?: string;
  className?: string;
}) {
  const { proposal_dep, proposal } = useContext(Context);
  const state = (
    proposal_dep?.State || proposal?.property_state
  )?.toUpperCase();

  if (!state || !LICENSE_NUMBERS[state]) return null;
  const orientationStyle = orientation === "horizontal" ? "row" : "column";
  return (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        flexDirection: orientationStyle,
        alignItems: "center",
        justifyContent: "center",
        gap: orientation === "horizontal" ? "0.5em" : "0",
        ...style,
      }}
      className={className}
    >
      <p style={{ fontSize: "12px", color }}>
        Solar: {LICENSE_NUMBERS[state].Solar}
      </p>
      <p style={{ fontSize: "12px", color }}>
        Electrical: {LICENSE_NUMBERS[state].Electrical}
      </p>
    </div>
  );
}
