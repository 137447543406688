import styled from "@emotion/styled";

type DotsParams = {
  index: number;
  length: number;
};

function Dots({ index, length = 0 }: DotsParams) {
  return (
    <Wrapper>
      {new Array(length).fill(null).map((_, i) => (
        <Dot
          key={"dot-" + i}
          style={{ background: i === index ? "#336699" : "transparent" }}
        />
      ))}
    </Wrapper>
  );
}

export default Dots;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
`;
const Dot = styled.div`
  width: 8px;
  border-radius: 1000px;
  border: 1px solid #336699;
  aspect-ratio: 1;
`;
