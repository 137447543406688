const mapStyle = [
  {
    featureType: "administrative",

    elementType: "all",

    stylers: [
      {
        saturation: "0",
      },
    ],
  },

  {
    featureType: "administrative",

    elementType: "labels.text.fill",

    stylers: [
      {
        color: "#444444",
      },
    ],
  },

  {
    featureType: "landscape",

    elementType: "all",

    stylers: [
      {
        color: "#f1f1f1",
      },
    ],
  },

  {
    featureType: "poi",

    elementType: "all",

    stylers: [
      {
        visibility: "off",
      },
    ],
  },

  {
    featureType: "road",

    elementType: "all",

    stylers: [
      {
        saturation: -100,
      },

      {
        lightness: 45,
      },
    ],
  },

  {
    featureType: "road.highway",

    elementType: "all",

    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },

  {
    featureType: "road.arterial",

    elementType: "labels.icon",

    stylers: [
      {
        visibility: "off",
      },
    ],
  },

  {
    featureType: "transit",

    elementType: "all",

    stylers: [
      {
        visibility: "off",
      },
    ],
  },

  {
    featureType: "water",

    elementType: "all",

    stylers: [
      {
        color: "#ace6f0",
      },

      {
        visibility: "on",
      },

      {
        saturation: "0",
      },

      {
        lightness: "0",
      },
    ],
  },
];

export default mapStyle;
