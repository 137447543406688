import { default as CardBase } from "@/Components_DEP/Card";
import Context from "@/Context";
import { get25YearSavings, getFirstYearSavings } from "@/utils/calculations";
import { money } from "@/utils/helpers";
import styled from "@emotion/styled";
import { useContext, useEffect, useRef, useState } from "react";
import Graph from "./Graph";
import GraphCard from "./GraphCard";

function SavingsGraph() {
  const { proposal } = useContext(Context);

  return (
    <Card>
      <GraphCard style={{ background: "#336699" }}>
        <p>1st Year Savings Estimate</p>
        <p> {money(proposal?.first_year_savings || 0)}</p>
      </GraphCard>
      <Graph />
      <GraphCard style={{ background: "#6EE2FF" }}>
        <p>25-Year Savings Estimate</p>
        <p> {money(proposal?.twenty_five_year_savings)}</p>
      </GraphCard>
    </Card>
  );
}

export default SavingsGraph;

const Card = styled(CardBase)`
  width: 100%;
  display: grid;
  grid-template-columns: 162px auto;
  grid-template-rows: 50%;
  gap: 10px;
`;
