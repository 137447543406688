import styled from "@emotion/styled";
import { motion } from "framer-motion";
import GraphBar from "./GraphBar";
import { ReactNode } from "react";

type SingleBarProps = {
  value?: number | string;
  color?: string;
  label?: number | string;
  height?: string;
  topLabel?: string | Element | ReactNode | undefined;
};

const fadeInitial = { opacity: 0 };
const fadeAnimate = { opacity: 1 };
const fadeTransition = { duration: 0.5, delay: 0.8 };

function SingleBar({
  value = "NO VAL",
  color,
  label,
  height = "100%",
  topLabel,
}: SingleBarProps) {
  return (
    <Wrapper>
      {!label && (
        <motion.div
          className="label-div"
          initial={fadeInitial}
          animate={fadeAnimate}
          transition={fadeTransition}
        >
          <p
            style={{
              color: color ? "#4B4B4B" : "#d4d4d4",
            }}
          >
            {value}
          </p>
          {topLabel && (
            //@ts-ignore
            <p className={`top-label ${color && "right"}`}>{topLabel}</p>
          )}
        </motion.div>
      )}
      <motion.div
        className="motion-div"
        initial={{ height: "0%" }}
        animate={{ height }}
        style={{ height }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <GraphBar
          className="shadow"
          style={{ height: "100%", background: color }}
        />
        {label && (
          <motion.div
            className="value-wrapper"
            initial={fadeInitial}
            animate={fadeAnimate}
            transition={fadeTransition}
          >
            <p>{value}</p>
            <p>{label}</p>
          </motion.div>
        )}
      </motion.div>
    </Wrapper>
  );
}

export default SingleBar;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;

  .motion-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    position: relative;
    z-index: 400;
  }

  .label-div {
    position: relative;
    width: 100%;

    .top-label {
      position: absolute;
      left: calc(-200%);
      width: 200%;
      font-size: 14px;
      top: 0;
      color: #d4d4d4;
    }

    .top-label.right {
      left: calc(100% + 8px);
    }
  }

  p {
    font-family: Gotham Medium;
    letter-spacing: -0.6;
    width: 100%;
    text-align: center;
  }

  .value-wrapper {
    position: absolute;
    bottom: 0px;
    margin: 6px 0px;
    height: 38px;

    max-width: 100%;
    text-align: center;

    p {
      font-family: Gotham Medium;
      font-size: 12px;
      color: white;
    }

    p:last-of-type {
      font-size: 9px;
    }
  }
`;
