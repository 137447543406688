import styled from "@emotion/styled";
import { ReactComponent as EquipmentSVG } from "@/assets/homeValue/home-install-equipment.svg";

const Equipment = styled(EquipmentSVG)`
  position: absolute;
  aspect-ratio: 1;
  left: 31%;
  bottom: 13.5%;
  height: 41%;

  /* transform: translate(33px, -23px); */
  opacity: 0;
  -webkit-animation: fade-in 0.5s ease-in-out forwards;
  -moz-animation: fade-in 0.5s ease-in-out forwards;
  animation: fade-in 0.5s ease-in-out forwards;
  -webkit-animation-delay: 1.4s;
  -moz-animation-delay: 1.4s;
  animation-delay: 1.4s;

  @-moz-keyframes fade-in {
    100% {
      -webkit-transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-in {
    100% {
      -webkit-transform: translate(0, 0);
      opacity: 1;
    }
  }
  @keyframes fade-in {
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
`;
export default Equipment;
