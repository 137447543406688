const locations = {
  AZ: {
    position: { lat: 33.41407120829076, lng: -112.02978418236856 },
    zoom: 10,
  },
  CA: {
    position: { lat: 35.33167160376264, lng: -119.77172872121359 },
    zoom: 7,
  },
  CT: {
    position: { lat: 41.59978967411257, lng: -72.66888570142505 },
    zoom: 9,
  },
  FL: {
    position: { lat: 27.765547871945227, lng: -82.3382239418086 },
    zoom: 7,
  },
  GA: {
    position: { lat: 33.78099627418402, lng: -84.33393864177005 },
    zoom: 9,
  },
  MA: {
    position: { lat: 42.330491269765965, lng: -71.54504587771498 },
    zoom: 8,
  },
  NJ: {
    position: { lat: 40.275683296478554, lng: -74.47667847852463 },
    zoom: 8.8,
  },
  NV: {
    position: { lat: 36.160835812781016, lng: -115.13581349991823 },
    zoom: 11,
  },
  NY: {
    position: { lat: 40.8296012409814, lng: -73.30548188400866 },
    zoom: 9.5,
  },
  PA: {
    position: { lat: 40.03753371112367, lng: -76.27189209659747 },
    zoom: 8.5,
  },
  TX: {
    position: { lat: 31.499798697527794, lng: -99.14900735774997 },
    zoom: 6.5,
  },
};

export default locations;
