import { ReactNode } from "react";

export type ScrollSectionProps = {
  Icon?: any;
  label?: string;
  children?: ReactNode | ReactNode[];
};

export function ScrollSection({ children }: ScrollSectionProps) {
  return <>{children}</>;
}
