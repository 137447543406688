import styled from "@emotion/styled";

type SplitCardHeaderProps = {
  label1?: string;
  label2?: string;
};

function SplitCardHeader({ label1, label2 }: SplitCardHeaderProps) {
  return (
    <Wrapper>
      <div className="header-cell">
        {label1 && <h6 className="header-label">{label1}</h6>}
      </div>
      <div className="header-cell">
        {label2 && <h6 className="header-label">{label2}</h6>}
      </div>
    </Wrapper>
  );
}

export default SplitCardHeader;

const Wrapper = styled.div`
  background: #eafbff;
  min-height: 42px;
  width: calc(100% + 32px); // 100% + (2 x Card padding)
  margin: -16px; // -1 x Card padding to get background to edge of element
  margin-bottom: 16px;
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;

  .header-cell {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .header-cell:first-of-type {
    background: #cecece;
    h6 {
      color: white;
    }
  }

  .header-label {
    font-size: 24px;
    font-family: Gotham Medium;
    color: #0cceff;
    letter-spacing: -0.6;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
`;
