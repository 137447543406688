import DoubleBar from "@/Components_DEP/BarGraph/DoubleBar";
import GraphWrapper from "@/Components_DEP/BarGraph/GraphWrapper";
import SingleBar from "@/Components_DEP/BarGraph/SingleBar";
import { default as CardBase } from "@/Components_DEP/Card";
import CardHeader from "@/Components_DEP/Card/CardHeader";
import Context from "@/Context";
import { money } from "@/utils/helpers";
import useIntersectionObserver from "@/utils/useIntersectionObserver";
import styled from "@emotion/styled";
import { useContext, useRef } from "react";

function MonthlyPayments() {
  const { proposal } = useContext(Context);
  const {
    utility_old_monthly_utility_bill,
    property_state,
    system_tsrf,
    battery_no_net_meter,
    battery_monthly_cost,
  } = proposal || {};

  // If this state or utility company has as a residential solar credit of some kind
  // we need to use the four bar variant of this graph
  // const isCTEversource = State === "CT" && utility === "Eversource";
  const hasRIIncentive = property_state === "RI" && (system_tsrf || 0) < 80;
  const hasIncentive =
    ["PA", "NJ", "MA"].includes(property_state || "") ||
    hasRIIncentive; /* || isCTEversource; */

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { rootMargin: "100%" });
  const isVisible = !!entry?.isIntersecting;
  const showSecondYear = false;

  const monthlyPayment =
    (proposal?.financing_monthly_loan_payment || 0) +
    ((battery_no_net_meter && battery_monthly_cost) || 0);
  const monthlyPaymentWithOffset =
    monthlyPayment + (proposal?.offset_utility || 0);
  return (
    <Card>
      <CardHeader label="Monthly Payments" />
      <div ref={ref} className="card-content">
        <div className="label">
          <h6>Without Solar</h6>
        </div>
        {isVisible && (
          <GraphWrapper>
            <SingleBar
              value={money(utility_old_monthly_utility_bill)}
              topLabel={
                <>
                  Current Monthly <br /> Utility Payment
                </>
              }
            />
            <DoubleBar
              totalValue={money(monthlyPayment)}
              loanValue={money(monthlyPaymentWithOffset)}
              utilityValue={money(proposal?.offset_utility || 0)}
              variant={hasIncentive ? "compact" : undefined}
              topLabel={showSecondYear ? "" : "Solar Loan + Est. Utility"}
              hideLabels={showSecondYear}
              bottomText={showSecondYear ? "Year 1" : ""}
            />
            {showSecondYear && (
              <DoubleBar
                totalValue={"$XX.XX"}
                loanValue={"XX.XX"}
                utilityValue={money(proposal?.offset_utility || 0)}
                variant={hasIncentive ? "compact" : undefined}
                topLabel={"Solar Loan + Est. Utility"}
                bottomText={"Year 2"}
              />
            )}
            {hasIncentive && (
              <>
                <SingleBar
                  value={money(proposal?.incentive_monthly_income)}
                  label={proposal?.incentive_label}
                  color="#FFD466"
                  height="50%"
                />
                <SingleBar
                  value={money(
                    (proposal?.financing_monthly_loan_payment || 0) +
                      (proposal?.offset_utility || 0) -
                      (proposal?.incentive_monthly_income || 0)
                  )}
                  label={"Net Estimated\nSolar + Utility"}
                  color="#95CE9E"
                  height="40%"
                />
              </>
            )}
          </GraphWrapper>
        )}
        <div className="label">
          <h6>With Solar</h6>
        </div>
      </div>
    </Card>
  );
}

export default MonthlyPayments;

const Card = styled(CardBase)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 16px;
    height: 100%;

    .label {
      flex: 1;
      padding: 0px 14px;
      font-size: 24px;

      p {
        color: #c6c6c6;
        font-size: 14px;
      }

      h6 {
        color: #c6c6c6;
      }
    }

    .label:last-of-type {
      text-align: end;
    }
    .label:last-of-type > h6 {
      color: #336699;
    }

    .footer {
      text-align: center;
      width: 30%;
      font-size: 7px;
      color: #a5a5a5;
      margin: 0em 2em;
      position: absolute;
      bottom: 6px;
    }
  }
`;
