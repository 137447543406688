import tables from "./stateCalculations";
import { cleanData, numberWithCommas } from "./stateCalculations/utilFuncs";

export const commas = (num = 0) => {
  const result = num + "";
  return result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const round = (num = 0, toFixedVal = 2) => {
  if (num === undefined || num === null) return;
  return num.toFixed(toFixedVal);
};

export const money = (val = 0) => {
  if (val === undefined || val === null) val = 0;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(+val.toFixed(2));
};

const propSwitch = (type) => {
  if (type === "GeneracCash" || type === "DecoCash") {
    return "Cash";
  }
  if (type === "GeneracLoan" || type === "JEALoan" || type === "DecoLoan") {
    return "Loan";
  }
  return type;
};

//copied from old
export const allData = (info) => {
  const result = !info
    ? {
        total: 0,
        secondVal: 0,
        thirdVal: 0, // total lifetime savings
        percent: 0,
      }
    : tables[propSwitch(info.proposalType)][info.State](info);
  return result;
};
//copied from old
export let valueSet = (proposalType, allData) => {
  if (!Array.isArray(allData)) return allData;
  if (!allData) {
    return {
      total: 0,
      secondVal: 0,
      thirdVal: 0, // total lifetime savings
      percent: 0,
    };
  }

  if (proposalType === "Cash") {
    const {
      totalElectricBillSavings,
      totalElectricAfterSolar,
      totalAnnualCashFlow,
    } = allData[2];
    let total = totalElectricBillSavings + totalElectricAfterSolar;

    return {
      total: cleanData(total),
      secondVal: cleanData(total - totalAnnualCashFlow),
      thirdVal: cleanData(totalAnnualCashFlow),
      percent: numberWithCommas(
        ((totalAnnualCashFlow / total) * 100).toFixed(1)
      ),
    };
  } else {
    const { oldPayments, yearlyFull } = allData[1];

    return {
      total: cleanData(oldPayments),
      secondVal: cleanData(oldPayments - yearlyFull),
      thirdVal: yearlyFull, // total lifetime savings
      percent: numberWithCommas(((yearlyFull / oldPayments) * 100).toFixed(1)),
    };
  }
};

//Copied from old code to use in multiple components
export const newTotalSavings = (info) => {
  let allD = allData(info);
  let distelledD = valueSet(propSwitch(info.proposalType), allD);

  return distelledD;
};
