import styled from "@emotion/styled";

const Card = styled.div`
  padding: 16px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
`;

export default Card;
