import styled from "@emotion/styled";

import FullPage from "@/Components_DEP/FullPage";

import homeBG from "@/assets/home-bg.png";
import MSLogoPNG from "@/assets/msLogo-text.png";
import { useContext, useRef } from "react";
import Context from "@/Context";
import useIntersectionObserver from "@/utils/useIntersectionObserver";
import delay from "@/utils/animationDelay";
import { OptionalLicenseNumbers } from "@/Components/LicenseNumbers";

function Home() {
  const { proposal_dep } = useContext(Context);
  const lastName = (() => {
    const arr = proposal_dep?.CustomerName.split(" ") ?? [];
    return arr[arr.length - 1] ?? "";
  })();
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  return (
    <Wrapper>
      {/* <MSLogo /> */}
      <img className="logo" src={MSLogoPNG} alt="Momentum Solar" />
      <div className={`h1-wrapper`} ref={ref}>
        <h1 className={`${isVisible ? "animate" : ""}`} style={delay(0.2)}>
          Hello,
        </h1>
        <h1 className={`${isVisible ? "animate" : ""}`} style={delay(0.3)}>
          {lastName.toLowerCase()} Family!
        </h1>
      </div>
      <p className="text">
        We are so excited for you and your energy upgrade. This is your unique
        solar design that will help you better understand all the benefits and
        savings that are coming your way!
      </p>
      <div className={"img-wrapper"}>
        <img src={homeBG} alt="A home with Solar" />
      </div>
      <OptionalLicenseNumbers
        style={{
          position: "absolute",
          top: "16px",
          right: "16px",
          textAlign: "right",
          alignItems: "flex-end",
        }}
      />
    </Wrapper>
  );
}

export default Home;

const Wrapper = styled(FullPage)`
  position: relative;
  background: linear-gradient(
    /* #33669977, */ rgba(147, 232, 255, 0.18),
    rgba(227, 249, 255)
  );
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  svg,
  img.logo {
    padding-top: clamp(32px, calc(2rem + ((1vw - 3.44px) * 8.0163)), 70px);
  }

  img.logo {
    width: 272px;
  }

  .text {
    font-size: 18px;
    max-width: 62ch;
  }

  .img-wrapper {
    display: flex;

    align-items: flex-end;
    justify-content: center;
    width: 100vw;
    max-width: calc(100% + 64px);
    margin: 0 -32px;
    margin-top: auto;

    img {
      width: 100%;
    }
  }

  .h1-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1em;
    flex-wrap: wrap;
    color: #00ccff;
    margin-top: clamp(24px, calc(1.5rem + ((1vw - 3.44px) * 6.1141)), 69px);
    margin-bottom: 1.5rem;
    h1 {
      opacity: 0;
      transform: translate(20px);
      -webkit-transform: translate(20px);
      text-transform: capitalize;
    }
  }

  .animate {
    -webkit-animation: fade 0.4s ease-out forwards;
    -moz-animation: fade 0.4s ease-out forwards;
    animation: fade 0.4s ease-out forwards;
  }

  @-moz-keyframes fade {
    to {
      -webkit-transform: translate(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade {
    to {
      -webkit-transform: translate(0px);
      opacity: 1;
    }
  }
  @keyframes fade {
    to {
      transform: translate(0px);
      -webkit-transform: translate(0px);
      opacity: 1;
    }
  }

  @-moz-keyframes slide {
    to {
      background-position: 0% 100%;
    }
  }
  @-webkit-keyframes slide {
    to {
      background-position: 0% 100%;
    }
  }
  @keyframes slide {
    to {
      background-position: 0% 100%;
    }
  }
`;
