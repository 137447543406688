import styled from "@emotion/styled";

const GraphCard = styled.div`
  border-radius: 10px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: white;
    font-family: Gotham Medium;
    font-size: 14px;
    text-align: center;
  }

  p:nth-of-type(2) {
    font-size: 18px;
  }
`;

export default GraphCard;
