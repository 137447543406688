import React, { ReactFragment, ReactNode, useState } from "react";

import { ReactComponent as LeftArrow } from "@/assets/arrow-left-circle.svg";
import { ReactComponent as RightArrow } from "@/assets/arrow-right-circle.svg";
import styled from "@emotion/styled";
import Dots from "./Dots";
import { AnimatePresence } from "framer-motion";
import CarouselItem from "./CarouselItem";

type CardCarouselProps = {
  children?: ReactNode[] | undefined;
};

function CarouselContext({ children = [] }: CardCarouselProps) {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(1);

  const goToNext = (inc = 1) => {
    setDirection(inc);
    const next = index + inc;

    if (next >= children?.length) {
      return setIndex(0);
    }
    if (next < 0) {
      return setIndex(children?.length - 1);
    }
    setIndex(next);
  };

  return (
    <Wrapper>
      {children && children?.length > 1 && (
        <LeftArrow className="arrow" onClick={() => goToNext(-1)} key="left" />
      )}
      <div className="card-wrapper">
        <AnimatePresence>
          {children && children[index] && (
            <CarouselItem
              key={"carousel_" + index}
              direction={direction}
              goToNext={goToNext}
            >
              {children[index]}
            </CarouselItem>
          )}
        </AnimatePresence>
      </div>
      {children && children?.length > 1 && (
        <RightArrow className="arrow" onClick={() => goToNext(1)} key="right" />
      )}
      <div className="dots-wrapper">
        <Dots index={index} length={(children && children.length) || 0} />
      </div>
    </Wrapper>
  );
}

export default CarouselContext;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .card-wrapper {
    width: 100%;
    height: 100%;
    overflow: visible;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .arrow {
    position: absolute;
    padding: 8px;
  }

  .arrow:nth-of-type(1) {
    left: -25px; // (icon width - icon padding) / 2
  }

  .arrow:nth-of-type(2) {
    right: -25px; // (icon width - icon padding) / 2
  }

  .dots-wrapper {
    position: absolute;
    bottom: -16px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
