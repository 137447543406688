import { default as CardBase } from "@/Components_DEP/Card";
import CardHeader from "@/Components_DEP/Card/CardHeader";
import Context from "@/Context";
import { getSavingsBreakdown } from "@/utils/calculations";
import { money } from "@/utils/helpers";

import styled from "@emotion/styled";
import { useContext } from "react";
import InfoBar from "./InfoBar";
import InfoRow from "./InfoRow";
import PillDisplay from "./PillOverlay";
import { getLifetimePBIIncome } from "@/utils/newStateCalculations/RI";

function TotalSavings() {
  const { proposal_dep } = useContext(Context);
  const { total, secondVal, thirdVal, percent } =
    getSavingsBreakdown(proposal_dep);

  return (
    <Card>
      <CardHeader label="Total Savings" />
      <PillDisplay
        value={percent + "%"}
        label="Estimated Lifetime Savings (%)"
      />
      <div className="card-content">
        <InfoRow>
          <p>Pre-Solar Cost</p>
          <p>{money(total)}</p>
        </InfoRow>

        <InfoRow>
          <p>Post-Solar Cost</p>
          <p>{money(secondVal)}</p>
        </InfoRow>

        {proposal_dep?.State === "RI" && proposal_dep?.tsrf < 80 && (
          <InfoRow>
            <p>Lifetime Estimated PBI Income</p>
            <p>{money(getLifetimePBIIncome(proposal_dep))}</p>
          </InfoRow>
        )}

        <InfoBar>
          <InfoRow>
            <p>Total Savings</p>
            <p>{money(thirdVal + (getLifetimePBIIncome(proposal_dep) ?? 0))}</p>
          </InfoRow>
        </InfoBar>
      </div>
      <p className="footer">
        Tax credits, rebates and incentives may very depending on your
        circumstances. We do not provide tax advice and recommend you consult
        with your tax professional for guidance.
      </p>
    </Card>
  );
}

export default TotalSavings;

const Card = styled(CardBase)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    margin-top: 16px;
    height: 100%;
  }

  .footer {
    color: #a5a5a5;
    font-family: Gotham Medium;
    font-size: 8px;
    width: 50%;
    text-align: center;
    margin: auto;
  }
`;
