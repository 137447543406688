import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";

import mapStyle from "./mapStyle";

import GreyMarker from "@/assets/map/marker-grey.png";
import BlueMarker from "@/assets/map/marker-blue.png";
import { ReactComponent as XIcon } from "@/assets/x-icon.svg";

import { useNavigate, useParams } from "react-router-dom";
import stateLocations from "./stateLocations";
import MapMarker from "@/assets/map/marker.svg";
import styled from "@emotion/styled";
import GoogleMapReact from "google-map-react";
import Context from "@/Context";
import MapInfoModal from "./MapInfoModal";

const defaultCenter = { lat: 40.576975916042436, lng: -74.41165706380873 };

const clearMarkerHighlight = (clickedMarker, markersRef) => {
  for (let markerKey in markersRef.current) {
    clickedMarker !== markersRef.current[markerKey] &&
      markersRef.current[markerKey].setIcon(GreyMarker);
  }
};

function MapPage() {
  const {
    proposal: proposal_new,
    proposal_dep,
    setGuid,
    setOnPinsUpdated,
    pinsRef,
    userLocation,
  } = useContext(Context);
  const { guid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    guid && setGuid(guid);
  }, [guid]);

  const state = proposal_dep?.State || proposal_new?.property_state;
  const [selectedMarker, setSelectedMarker] = useState();
  const [pinUpdates, setPinUpdates] = useState(0);
  const mapRef = useRef();
  const markersRef = useRef({});
  const pins = pinsRef.current;

  const stateCenter = (() => stateLocations[state.toUpperCase()])();
  // const mapStartingPosition = userLocation;
  // Sometimes while the pins are being loaded in the styling on
  // highlighted markers will not reset themselves when another is
  // clicked due to race conditions. This functions memoizes the process
  const resetMarkerStyling = useCallback(
    () => clearMarkerHighlight(selectedMarker, markersRef),
    [selectedMarker, markersRef]
  );

  const handleRefreshMarkers = () => {
    resetMarkerStyling();
    updateMarkersOnMap();
  };

  const handleMarkerClick = (marker) => {
    resetMarkerStyling();
    mapRef.current.map.panTo(marker.position);
    setSelectedMarker((prevMarker) => {
      prevMarker && prevMarker.setIcon(GreyMarker);
      marker.setIcon(BlueMarker);

      return marker;
    });
  };

  const drawPin = ({ map, maps }, pinData) => {
    const position = {
      lat: pinData.latitude || pinData.lat,
      lng: pinData.longitude || pinData.lng,
    };
    const newMarker = new maps.Marker({
      position,
      icon: GreyMarker,
      map,
    });
    newMarker.data = pinData;
    newMarker.addListener("click", () => handleMarkerClick(newMarker));
    return newMarker;
  };
  const updateMarkersOnMap = () => {
    if (!!mapRef.current) {
      const nOfMarkers = Object.keys(markersRef.current).length;
      const newPins = pins.slice(pins.length - pins.length - nOfMarkers);
      let newMarkers = [];
      newPins.forEach((pinData) => {
        const key = `${pinData.latitude}_${pinData.longitude}`;

        if (
          !markersRef.current[key] &&
          pinData.latitude &&
          pinData.longitude &&
          state.toLowerCase() === pinData.state?.toLowerCase()
        ) {
          markersRef.current[key] = drawPin(mapRef.current, pinData, () =>
            handleMarkerClick(pinData)
          );
          newMarkers.push(markersRef.current[key]);
        }
      });
    }
  };

  // here we assign the psiun update counter to increment each time
  // the pins are updated on the top level of the app. The useEffect
  // below this one watches those updates.
  useEffect(() => {
    const updateFunction = () => {
      setPinUpdates((prev) => prev + 1);
    };
    setOnPinsUpdated(updateFunction);
  }, []);

  useEffect(() => {
    if (userLocation?.lat && userLocation?.lng && mapRef.current)
      new mapRef.current.maps.Marker({
        position: userLocation,
        icon: MapMarker,
        map: mapRef.current.map,
        zIndex: 8000,
      });
  }, [userLocation, mapRef.current]);

  // Any time the pins are updated or we change the page to this one
  // we update the pins on the map.
  // NOTES: The best practice would be to have the updateMakersOnMap function
  // directly called inside of the onPinsUpdated fn but I was unable to
  // have those updates appear on the map correctly. If you figure it out,
  // good on you.
  useEffect(() => {
    updateMarkersOnMap();
  }, [pinUpdates]);

  if (!state) return null;
  return (
    <Wrapper>
      <button
        className="exit"
        onClick={() => navigate(`/${guid}?page=Home+Value+Benefits`)}
      >
        <XIcon />
      </button>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyCR7yTvbSisKRJcpb20uNWQ_wZLWuCive8",
        }}
        defaultCenter={defaultCenter}
        defaultZoom={13}
        zoom={userLocation ? 13 : stateCenter?.zoom ?? 13}
        center={userLocation ?? stateCenter.position}
        style={{ height: 1 }}
        options={{
          fullscreenControl: false,
          draggable: true,
          zoomControl: true,
          scrollwheel: true,
          disableDoubleClickZoom: true,
          maxZoom: 14,
          styles: mapStyle,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(google) => {
          mapRef.current = google;

          google.map.addListener("drag", () =>
            setSelectedMarker((prev) => {
              prev && prev.setIcon(GreyMarker);
              return null;
            })
          );
          google.map.addListener("zoom_changed", () => {
            selectedMarker && google.map.panTo(selectedMarker.position);
            setSelectedMarker((prev) => {
              prev && prev.setIcon(GreyMarker);
              return null;
            });
          });

          if (userLocation?.lat && userLocation?.lng)
            new mapRef.current.maps.Marker({
              position: userLocation,
              icon: MapMarker,
              map: mapRef.current.map,
              zIndex: 8000,
            });
          updateMarkersOnMap();
        }}
      >
        <MapInfoModal
          marker={selectedMarker}
          closeModal={() =>
            setSelectedMarker((prevMarker) => {
              prevMarker && prevMarker.setIcon(GreyMarker);
              return;
            })
          }
        />
      </GoogleMapReact>
    </Wrapper>
  );
}

export default MapPage;

const Wrapper = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;

  button.exit {
    position: absolute;
    z-index: 1000;
    top: 0.5em;
    right: 0.5em;
    border: none;
    background: white;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666666;
  }

  button.exit:hover {
    opacity: 0.8;
  }
`;
